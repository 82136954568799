<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { onBeforeMount, ref, computed } from 'vue';
import type { LeaderboardInfo, LeaderboardMeta } from '@/types';
import LockedHeader from './headers/LockedHeader.vue';
import UnlockedHeader from './headers/UnlockedHeader.vue';
import ScoredHeader from './headers/ScoredHeader.vue';

const emits = defineEmits<{
	loading: [value: boolean];
}>();

const userStore = useUserStore();
const { leaderboard, username } = storeToRefs(useUserStore());

const info = ref<LeaderboardInfo>();
const nextStart = ref('');
const nextName = ref('');
const isActive = ref(false);

const showUnlockedHeader = computed(
	() => !username.value && leaderboard.value.has_enough_completes
);

const showScoredHeader = computed(
	() => username.value && leaderboard.value.unlocked
);

const fetchLeaderboardData = async () => {
	emits('loading', true);

	const data = await userStore.fetchLeaderboardData();
	const leaderboardType =
		(data.active && 'active') || (data.last && 'last') || undefined;

	isActive.value = Boolean(data?.active);

	if (leaderboardType) {
		info.value = {
			...data[leaderboardType],
		};
	}

	if (data.next) {
		nextStart.value = data.next.start;
		nextName.value = data.next.name;
	}

	updateUserLeaderboardRank(data, leaderboardType);

	emits('loading', false);
};

const updateUserLeaderboardRank = (
	meta: LeaderboardMeta,
	type: 'active' | 'last' | undefined
) => {
	if (!type) return;

	const rank =
		meta[type]?.user_standings
			?.find((user) => user.is_current_user)
			?.rank.toString() || '';

	if (rank) {
		userStore.setLeaderboardUserRank(rank);
	}
};

onBeforeMount(async () => {
	await fetchLeaderboardData();
});
</script>

<template>
	<header class="leaderboard-header">
		<!-- UNLOCKED user has 10 completed surveys BUT does not have username-->
		<UnlockedHeader v-if="showUnlockedHeader" :handler="fetchLeaderboardData" />

		<!-- SCORED user has 10 completed surveys AND has username-->
		<ScoredHeader
			v-else-if="showScoredHeader"
			:info="info!"
			:is-active="isActive"
			:next-start="nextStart"
			:next-name="nextName"
		/>

		<!-- LOCKED user does not have 10 completed surveys-->
		<LockedHeader
			v-else
			:info="info!"
			:is-active="isActive"
			:next-start="nextStart"
			:next-name="nextName"
		/>
	</header>
</template>
