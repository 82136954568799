<script setup lang="ts">
import { type Component, onBeforeMount, shallowRef } from 'vue';
import { getReferralStatistic } from '@/api';
import { ref } from 'vue';
import {
	REF_STATS_EARNED_COINS,
	REF_STATS_REGISTERED_FRIENDS,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import {
	PiCoinsDuotone,
	PiUsersDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type ReferralStatistic = {
	icon: Component;
	value: number | string;
	text: string;
};

const stats = ref<Record<string, ReferralStatistic>>({
	earned_coins: {
		icon: shallowRef(PiCoinsDuotone),
		value: 0,
		text: REF_STATS_EARNED_COINS,
	},
	registered_friends: {
		icon: shallowRef(PiUsersDuotone),
		value: 0,
		text: REF_STATS_REGISTERED_FRIENDS,
	},
});

onBeforeMount(async () => {
	const { earned_coins, registered_friends } = await getReferralStatistic();

	stats.value.earned_coins.value = localizePoints(earned_coins || 0);
	stats.value.registered_friends.value = registered_friends || 0;
});
</script>

<template>
	<section class="referrals-stats">
		<div v-for="stat in stats" :key="stat.text" class="referrals-stats--item">
			<component
				:is="stat.icon"
				color="base-primary"
				size="1.5rem"
				class="referrals-stats--icon"
			/>
			<div>
				<PrimeText tag="p" weight="600">
					{{ stat.value }}
				</PrimeText>
				<PrimeText tag="p" color="grey-700" size="xs" weight="500">
					{{ $t(stat.text) }}
				</PrimeText>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
.referrals-stats {
	display: flex;
	flex-wrap: wrap;
	gap: 0.75rem;
	padding: 0.75rem 0.875rem;
	border-radius: 0.75rem;
	border: 1px solid var(--grey-200);

	&--item {
		flex: none;
		min-width: calc(50% - 0.375rem);
		display: flex;
		align-items: center;
		gap: 0.5rem;
	}

	&--icon {
		flex: none;
	}
}
</style>
