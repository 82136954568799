import API from '@/api';
import type { BonusResponse, MessageResponse } from '@/types';

export const getWelcomeBonuses = async (): Promise<BonusResponse | null> => {
	const { data, status } = await API.get<BonusResponse>('/api/v2/bonus');
	return status === 200 ? data : null;
};

export const selectWelcomeBonus = async (
	bonusValue: number
): Promise<MessageResponse> => {
	const { data } = await API.post<MessageResponse>('/api/v2/bonus', {
		coin_value: bonusValue,
	});
	return data;
};
