import API from '@/api';

export const getReferralStatistic = async (): Promise<{
	earned_coins: number;
	registered_friends: number;
	cashed_out_friends: number;
}> => {
	const { data } = await API.get('/api/referral-statistics');
	const { earned_coins, registered_friends, cashed_out_friends } = data;

	return {
		earned_coins,
		registered_friends,
		cashed_out_friends,
	};
};
