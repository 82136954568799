<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { RewardsItem } from '@/types';
import { categories } from '@/views/surveys/components/user-dashboard/rewards/categories';
import RewardItem from '@/views/surveys/components/user-dashboard/rewards/RewardItem.vue';
import { useI18n } from 'vue-i18n';
import { VIEW_MORE_REWARDS } from '@/locales/constants';
import { RewardCategories } from '@/enums';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import RewardSubCategories from '@/views/surveys/components/user-dashboard/rewards/RewardSubCategories.vue';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';

const {
	category,
	subCategories = false,
	isFiltered = false,
} = defineProps<{
	category: RewardCategories;
	subCategories?: boolean;
	isFiltered?: boolean;
}>();

const categoryItems = defineModel<RewardsItem[]>('categoryItems', {
	required: true,
});

const selectedOption = defineModel<number>('selectedOption', {
	required: true,
});

const { isMobile } = useScreenSize();
const { t } = useI18n();
const isFullListVisible = ref(false);

const expandButtonText = computed(() => {
	const itemsLeftToShow = categoryItems.value.length - 2;
	return `${t(VIEW_MORE_REWARDS, {
		value: itemsLeftToShow,
	})} ${t(categories[category].name, itemsLeftToShow).toLowerCase()}`;
});

const categoryName = computed(() => {
	const transfer = category === RewardCategories.TRANSFERS ? 1 : 2;
	return `${t(categories[category].name, transfer)} (${
		categoryItems.value.length
	})`;
});

const visibleRewards = computed(() => {
	return isFullListVisible.value
		? categoryItems.value
		: categoryItems.value.slice(0, 2);
});

watch(
	categoryItems,
	(value) => {
		isFullListVisible.value = isFiltered || value.length <= 2;
	},
	{ immediate: true }
);

const rewardsStore = useRewardsStore();
const { availableSubCategoriesContent, selectedSubCategories } =
	storeToRefs(rewardsStore);

const selectedSubCategory = ref<string>(selectedSubCategories.value[category]);

watch(selectedSubCategory, (newValue) => {
	rewardsStore.setSelectedSubCategories(category, newValue);
});
</script>

<template>
	<div
		class="reward-category"
		:class="{ 'without-padding': isFullListVisible }"
	>
		<div class="category-header">
			<component :is="categories[category].icon" class="category-icon" />
			<PrimeText weight="500"> {{ categoryName }} </PrimeText>
		</div>
		<RewardSubCategories
			v-if="subCategories && availableSubCategoriesContent"
			v-model:selected-sub-category="selectedSubCategory"
			:sub-categories="availableSubCategoriesContent[category]"
		/>
		<div>
			<RewardItem
				v-for="reward in visibleRewards"
				:key="reward.reward_external_id"
				:item="reward"
				:selected-option-id="selectedOption"
				@select-option="selectedOption = $event"
			/>
		</div>
		<div v-if="!isFullListVisible" class="reward-show-more">
			<PrimeButton
				type="link"
				size="small"
				:label="expandButtonText"
				@click="isFullListVisible = true"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';
.reward-category {
	padding: 0.75rem 0;
	background: var(--grey-100);
	border-radius: 1rem;

	&.without-padding {
		padding-bottom: 0;
	}
}

.category-header {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
	padding: 0 0.75rem;

	& span {
		margin-left: 0.375rem;
	}

	@include breakpoint(mobile) {
		padding: 0 1rem;
	}
}

.category-icon {
	width: 1.75rem;
	height: 1.75rem;
}
.reward-show-more {
	padding: 0 0.75rem;
	margin-top: 0.5rem;

	@include breakpoint(mobile) {
		padding: 0 1rem;
	}
}
</style>
