<script setup lang="ts">
import { computed } from 'vue';
import { localizePoints } from '@/utils/helpers';
import {
	RATING_DESCRIPTION_WEEKLY,
	EARN_POINTS_TO_WIN_MORE,
} from '@/locales/constants';
import PrizePool from '@/views/leaderboard/components/headers/PrizePool.vue';
import UserRatingItem from '@/views/leaderboard/components/headers/UserRatingItem.vue';
import {
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiRocketLaunchDuotone } from '@primeinsightsgroupllc/prime-icons';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import type { LeaderboardInfo, UserStanding } from '@/types';

const props = defineProps<{
	info: LeaderboardInfo | undefined;
	nextStart: string;
	nextName: string;
	isActive: boolean;
}>();

const { isMobile } = useScreenSize();

const endedLeaderboardUserItem = computed<UserStanding[]>(() => [
	{
		rank: props.info?.current_user_rank || '',
		username: null,
		prize: props.info?.current_user_prize || 0,
		earned: props.info?.current_user_earnings || 0,
		is_current_user: true,
	},
]);
const items = computed<UserStanding[]>(() =>
	props.info?.user_standings?.length
		? props.info.user_standings
		: endedLeaderboardUserItem.value
);
const nextPrize = computed(() => props.info?.next_prize);
</script>

<template>
	<section class="leaderboard-header__default">
		<PrimeText
			tag="h2"
			weight="700"
			class="leaderboard-header--title"
			align="center"
			:size="isMobile ? 'lg' : '1xl'"
		>
			🏆 {{ info?.name }}
		</PrimeText>
		<PrimeText
			tag="p"
			color="grey-700"
			size="sm"
			weight="500"
			align="center"
			class="leaderboard-header--description"
		>
			{{ $t(RATING_DESCRIPTION_WEEKLY) }}
		</PrimeText>
		<PrimeDivider top-gap="1rem" bottom-gap="1rem" />
		<PrizePool
			:info="info"
			:is-active="isActive"
			:next-start="nextStart"
			:next-name="nextName"
		/>

		<div v-if="items?.length" class="user-rating">
			<UserRatingItem v-for="item in items" :key="item.rank" :data="item" />
		</div>
		<div v-if="nextPrize" class="user-earn-more">
			<PiRocketLaunchDuotone color="base-primary" />
			<PrimeText
				weight="500"
				tag="p"
				:size="isMobile ? 'sm' : 'base'"
				v-html="
					$t(EARN_POINTS_TO_WIN_MORE, {
						value: localizePoints(nextPrize.points_needed),
						win: localizePoints(nextPrize.prize_improvement),
					})
				"
			/>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.leaderboard-header__default {
	width: 100%;
	padding: 1.5rem 0.875rem;
	background: #fff;
	border: 1px solid var(--grey-200);
	border-radius: 0.75rem;

	@include breakpoint(mobile) {
		padding: 1.5rem;
	}

	.leaderboard-header--description {
		margin-top: 0.25rem;
	}

	.user-rating {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.375rem;
		margin-top: 0.875rem;
	}

	.user-earn-more {
		display: flex;
		justify-content: center;
		gap: 0.5rem;
		margin-top: 1rem;

		:deep(span) {
			font-weight: 700;
		}

		.p-text {
			color: var(--base-primary-900);
		}
	}
}
</style>
