<script setup lang="ts">
import { useRouter } from 'vue-router';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { OfferwallPartner } from '@/types';

const { item } = defineProps<{
	item: OfferwallPartner;
}>();

const router = useRouter();

const openPartner = () => {
	if (!item.id) return;
	router.push({
		name: 'offerwalls-partner',
		params: { partnerId: item.id },
	});
};
</script>

<template>
	<div
		class="offerwalls-partner-item"
		:class="{ unlocked: item.id }"
		@click="openPartner"
	>
		<img :src="item.image_url" :alt="item.name" />
		<PrimeText weight="500" size="sm">{{ item.name }}</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.offerwalls-partner-item {
	cursor: not-allowed;
	pointer-events: none;
	background: var(--white);
	border: 1px solid var(--white);
	border-radius: 0.75rem;
	box-shadow: var(--box-shadow);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.8125rem;
	padding: 0.75rem;

	& img {
		max-height: 2.188rem;
	}

	&.unlocked {
		pointer-events: auto;
		cursor: pointer;
		&:hover {
			border-color: var(--base-primary);
		}
	}
}
</style>
