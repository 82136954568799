<script lang="ts" setup>
import { ref } from 'vue';
import { useBonusStore } from '@/stores/bonus';
import {
	BONUS_INFORMATION_MESSAGE,
	CHOOSE_WELCOME_BONUS,
	EXCHANGE_RATE,
	GET_WELCOME_BONUS,
	WELCOME_BONUS_DESCRIPTION,
} from '@/locales/constants';
import type { BonusItem } from '@/types';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import BonusListItem from '@/views/surveys/components/welcome-bonus/BonusListItem.vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { TrackingEvents } from '@/enums';
import {
	PiCoinsDuotone,
	PiGiftDuotoneThin,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useAppStore } from '@/stores/app';

const selectedBonus = ref<BonusItem | null>(null);
const bonusStore = useBonusStore();
const appStore = useAppStore();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();
const isDisable = ref(false);

const setWelcomeBonus = async () => {
	if (selectedBonus.value) {
		isDisable.value = true;
		const { message } = await bonusStore.selectBonus(
			selectedBonus.value.coin_value
		);
		await appStore.trackEvent(TrackingEvents.WELCOME_BONUS_SELECTED, {
			selected_bonus: selectedBonus.value.coin_value.toString(),
		});
		notify({
			body: message,
		});
		emit('close-modal');
	}
};
</script>

<template>
	<div class="modal-content-container">
		<div class="gift-icon">
			<PiGiftDuotoneThin size="2.75rem" color="base-primary" />
		</div>

		<PrimeText tag="div" class="title-wrapper" size="lg" weight="600">
			<span v-html="`${$t(CHOOSE_WELCOME_BONUS)}`" />
		</PrimeText>

		<div class="bonus-list">
			<BonusListItem
				v-for="(bonus, index) in bonusStore.data"
				:key="bonus.coin_value"
				:index="index"
				:item="bonus"
				:selected-bonus="selectedBonus?.coin_value === bonus.coin_value"
				@select-bonus="selectedBonus = $event"
			/>
		</div>
		<template v-if="selectedBonus">
			<PrimeText tag="div" class="bonus-description" weight="500">
				{{ $t(WELCOME_BONUS_DESCRIPTION) }}
			</PrimeText>
			<div class="exchange-rate">
				<PiCoinsDuotone color="base-primary" size="2.25rem" />
				<div class="exchange-rate__details">
					<PrimeText color="primary" tag="p" size="sm">
						{{ $t(EXCHANGE_RATE) }}
					</PrimeText>
					<PrimeText color="primary" weight="700" tag="p" size="lg">
						{{
							$t(BONUS_INFORMATION_MESSAGE, {
								coins: localizePoints(selectedBonus?.coin_value),
								money: transformCurrency(
									+selectedBonus?.money_value,
									selectedBonus?.currency,
									2
								),
							})
						}}
					</PrimeText>
				</div>
			</div>
		</template>

		<PrimeButton
			type="gradient"
			full-width
			:label="$t(GET_WELCOME_BONUS)"
			:disabled="!selectedBonus || isDisable"
			@click="setWelcomeBonus"
		/>
		<PrimeLoader v-if="selectedBonus && bonusStore.loading" />
	</div>
</template>

<style lang="scss" scoped>
.modal-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.gift-icon {
	margin-bottom: 0.25rem;
	width: 2.75rem;
	height: 2.75rem;
}

.title-wrapper {
	margin-bottom: 1.375rem;
	text-align: center;

	:deep(span > span) {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		font-weight: 700;
		color: var(--base-primary);
	}
}

.bonus-list {
	width: 100%;
	margin-bottom: 1.375rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	align-items: end;
	gap: 0.625rem;
}

.bonus-description {
	text-align: center;
	margin-bottom: 0.5rem;
	max-width: 80%;
}

.exchange-rate {
	width: 100%;
	margin-bottom: 1.375rem;
	padding: 0.625rem;
	color: var(--base-primary);
	background-color: var(--base-primary-06);
	border-radius: 0.25rem;
	line-height: 1.4;
	text-align: center;
	display: flex;
	gap: 1rem;
	justify-content: center;
	align-items: center;

	&__details {
		text-align: left;
	}
}

.submit-button {
	width: 100%;
}
</style>
