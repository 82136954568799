<script setup lang="ts">
import {
	REFRESH_PAGE,
	SOMETHING_WENT_WRONG,
	TRY_AGAIN,
} from '@/locales/constants';
import { PiWarningTriangleDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const handleRefreshPage = () => document.location.reload();
</script>

<template>
	<div class="fallback-wrapper">
		<PiWarningTriangleDuotoneThin size="6.25rem" color="base-primary" />
		<PrimeText tag="h2" size="2xl" weight="600">
			{{ $t(SOMETHING_WENT_WRONG) }}
		</PrimeText>
		<PrimeText tag="p" size="sm" color="grey-600" class="fallback-message">
			{{ $t(TRY_AGAIN) }}
		</PrimeText>
		<PrimeButton
			type="gradient"
			full-width
			:label="$t(REFRESH_PAGE)"
			@click="handleRefreshPage"
		/>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.fallback-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
	width: 100%;
	padding: 0 1.125rem;
	max-width: 25rem;
}

.fallback-title {
	margin-top: 0.125rem;
	margin-bottom: 0.125rem;
}

.fallback-message {
	margin: 1rem 0 1.5rem;
	@include breakpoint(mobile) {
		margin: 1rem 0 2.75rem;
	}
}
</style>
