<script setup lang="ts">
import { onMounted } from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import ClaimedRewardItem from '@/views/history/views/rewards/components/ClaimedRewardItem.vue';
import { PrimeSkeleton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ClaimedRewardsFallback from '@/views/history/views/rewards/components/ClaimedRewardsFallback.vue';
import TotalClaims from '@/views/history/views/rewards/components/TotalClaims.vue';

const rewardsStore = useRewardsStore();

onMounted(async () => {
	await rewardsStore.fetchRewardsHistory();
});
</script>

<template>
	<div class="rewards">
		<TotalClaims />
		<template v-if="rewardsStore.loading">
			<PrimeSkeleton
				v-for="i in 3"
				:key="i"
				height="7.4375rem"
				class="rewards-skeleton"
			/>
		</template>
		<template v-else-if="rewardsStore.claimedRewards?.length">
			<ClaimedRewardItem
				v-for="item in rewardsStore.claimedRewards"
				:key="item.date"
				:item="item"
			/>
		</template>
		<ClaimedRewardsFallback v-else />
	</div>
</template>

<style lang="scss" scoped>
.rewards {
	padding-top: 1.5rem;
}
.rewards-skeleton {
	margin-bottom: 0.75rem;
}
</style>
