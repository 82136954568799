<script setup lang="ts">
import { computed } from 'vue';
import type { AchievementGroup } from '@/types';
import AchievementsLevel from '@/views/achievements/components/AchievementsLevel.vue';
import { UNLOCK_FIRST_ACHIEVEMENT } from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const props = defineProps<{
	achievement: AchievementGroup;
}>();

defineEmits<{
	(e: 'claim-achievement', id: string): void;
}>();

const { isMobile } = useScreenSize();

const isLockedGroup = computed<boolean>(() => {
	return !props.achievement.achievement_levels[0]?.is_claimed;
});
</script>

<template>
	<div class="achievements-group" :class="{ locked: isLockedGroup }">
		<PrimeText tag="h3" weight="600" class="achievements-title" color="dark">
			{{ achievement.name }}
		</PrimeText>
		<PrimeText
			v-if="isLockedGroup"
			tag="p"
			:size="isMobile ? 'xs' : 'sm'"
			class="achievements-description"
			color="grey-700"
		>
			{{ $t(UNLOCK_FIRST_ACHIEVEMENT) }}
		</PrimeText>
		<AchievementsLevel
			v-for="{
				id,
				level_key,
				progress,
				coin_value,
				description,
				goal,
				is_active,
				is_claim_available,
				is_claimed,
			} in achievement.achievement_levels"
			:key="id"
			:achievement-key="achievement.achievement_key"
			:progress="progress"
			:achievement-id="id"
			:coins="coin_value"
			:description="description"
			:goal="goal"
			:is-active="is_active"
			:is-claim-available="is_claim_available"
			:is-claimed="is_claimed"
			:level-key="level_key"
			@claim-achievement="$emit('claim-achievement', $event)"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.achievements-group {
	margin-bottom: 1.5rem;
	padding: 1.5rem;
	border-radius: 0.75rem;
	border: 1px solid var(--grey-200);
	background: var(--white);
	padding: 1.5rem 0.875rem;

	&:last-child {
		margin-bottom: 0;
	}

	&.locked {
		:deep(.achievement) {
			position: relative;

			&:not(:first-of-type) {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #ffffff38;
					display: flex;
					z-index: 5;
					backdrop-filter: blur(3px);
					-webkit-backdrop-filter: blur(3px);
					overflow: hidden;
					border-radius: 1.5rem;
				}
			}
		}
	}

	@include breakpoint(mobile) {
		& {
			padding: 1.5rem;
		}
	}
}

h3.achievements-title.p-text {
	margin-bottom: 0.25rem;
	word-break: break-all;
}
</style>
