<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import SurveysFallback from '@/views/surveys/components/SurveysFallback.vue';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useAuthStore } from '@/stores/auth';
import { useSurveysView } from '@/views/surveys/composables';
import { useScreenSize } from '@/utils/composables/useScreenSize';
const UserDashboard = defineAsyncComponent(
	() => import('@/views/surveys/components/user-dashboard/UserDashboard.vue')
);

const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);
const userStore = useUserStore();
const { integrationScriptLoading } = useSurveysView();

const { isTablet } = useScreenSize();

const showDashboard = computed(() => isTablet.value && isAuthorized.value);
</script>

<template>
	<main v-if="!userStore.error" class="surveys__container">
		<section v-if="showDashboard" class="rewards-section">
			<UserDashboard />
		</section>
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<div
			v-if="isAuthorized"
			id="prime-survey"
			:class="{ 'prime-survey-application': !integrationScriptLoading }"
		/>
	</main>
	<SurveysFallback v-else />
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.surveys__container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.prime-survey-application {
	flex: 1 1 auto;
	margin-top: 0.375rem;
	@include breakpoint(tablet) {
		margin-top: 1.125rem;
	}
}

.rewards-section {
	margin-top: 1.125rem;
}

#prime-survey {
	:deep(.integration-script-container) {
		.surveys-wrapper,
		.filter {
			background: none;
		}

		.container {
			padding-left: 0.25rem;
			padding-right: 0.25rem;
		}
	}
}
</style>
