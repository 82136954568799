<script setup lang="ts">
import OfferwallsPartnerItem from '@/views/offerwalls/views/partners/components/OfferwallsPartnerItem.vue';
import { onBeforeMount, ref } from 'vue';
import { getOfferWallsList } from '@/api';
import type { OfferwallPartner } from '@/types';
import { PARTNERS_NOT_FOUND } from '@/locales/constants';
import {
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import OfferwallsLocked from '@/views/offerwalls/views/partners/components/OfferwallsLocked.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { OfferwallsStatuses } from '@/enums';

const isLoading = ref(false);
const partners = ref<OfferwallPartner[]>([]);

const { offerwallsMeta } = storeToRefs(useUserStore());

onBeforeMount(async () => {
	isLoading.value = true;
	partners.value = await getOfferWallsList();
	isLoading.value = false;
});
</script>

<template>
	<div
		class="offerwalls-partners__container"
		:class="{ 'container-full': isLoading || !partners?.length }"
	>
		<OfferwallsLocked
			v-if="offerwallsMeta?.status === OfferwallsStatuses.LOCKED"
		/>

		<PrimeLoaderSpinner v-if="isLoading" />
		<template v-else>
			<div v-if="partners?.length" class="offerwalls-partners__list">
				<OfferwallsPartnerItem
					v-for="item in partners"
					:key="item.name"
					:item="item"
				/>
			</div>
			<div v-else>
				<PrimeText tag="p" weight="600" size="3xl">
					{{ $t(PARTNERS_NOT_FOUND) }}
				</PrimeText>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.offerwalls-partners__container {
	position: relative;
	overflow: hidden;
	border-radius: 0.75rem;
	min-height: 15rem;

	@include breakpoint(mobile) {
		& {
			min-height: 17.5rem;
		}
	}

	&.container-full {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
}

.offerwalls-partners__list {
	margin: 0 auto;
	display: grid;
	align-content: center;
	justify-content: center;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 0.75rem;

	@include breakpoint(tablet) {
		gap: 1.5rem;
	}
}
</style>
