<template>
	<div class="partners-list">
		<img alt="Amazon logo" src="@/assets/amazon.png" />
		<img alt="Apple logo" src="@/assets/apple.png" />
		<img alt="Starbucks logo" src="@/assets/starbucks.png" />
		<img alt="AMC logo" src="@/assets/amc.png" />
		<img alt="American eagle logo" src="@/assets/american-eagle.png" />
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.partners-list {
	display: flex;
	gap: 0.5rem;

	& img {
		border-radius: 0.25rem;
		display: inline-block;
		width: 2.8125rem;
		height: 1.875rem;
	}

	@include breakpoint(mobileonly) {
		gap: 0.75rem;

		& img {
			border-radius: 0.5rem;
			width: 3.125rem;
			height: 2rem;
		}
	}
}
</style>
