<script setup lang="ts">
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	BONUS_WARNING_MESSAGE,
	CONGRATS,
	UNABLE_CLAIM,
	WELCOME_BONUS,
	YOU_CAN_CLAIM,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { PiWarningTriangleDuotoneThin } from '@primeinsightsgroupllc/prime-icons';

defineProps<{
	isClaimAvailable: boolean;
}>();

const { minWelcomeBonusClaimBalance } = storeToRefs(useUserStore());
</script>

<template>
	<PiWarningTriangleDuotoneThin
		v-if="!isClaimAvailable"
		color="base-primary"
		class="icon"
	/>
	<PrimeText class="title" weight="600" size="2xl">
		{{ isClaimAvailable ? $t(CONGRATS) : $t(UNABLE_CLAIM) }}
	</PrimeText>
	<PrimeText
		v-if="isClaimAvailable"
		tag="p"
		weight="500"
		size="base"
		color="grey-600"
		class="text"
	>
		{{ $t(YOU_CAN_CLAIM) }}
	</PrimeText>
	<PrimeText v-else tag="p" class="text bonus-notifications">
		<PrimeText tag="span" weight="500" size="sm">
			{{
				$t(BONUS_WARNING_MESSAGE, {
					coins: minWelcomeBonusClaimBalance
						? localizePoints(minWelcomeBonusClaimBalance)
						: '',
					bonus: $t(WELCOME_BONUS),
				})
			}}
		</PrimeText>
	</PrimeText>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.title {
	margin-bottom: 0.125rem;
}

.text {
	text-align: center;
}

.icon {
	width: 8.125rem;
	height: auto;
	@include breakpoint(mobile) {
		width: 9.375rem;
	}
}
</style>
