import { computed } from 'vue';
import {
	SURVEYS,
	LEADERBOARD,
	ACCOUNT,
	MORE,
	NEW,
	HELP,
	REFER_LINK_TITLE,
	REDEEM_LINK_TITLE,
	INVITE_FRIEND,
} from '@/locales/constants';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	PiClipboardText,
	PiDotsThreeCircle,
	PiTrophy,
	PiUserCircle,
	PiCoins,
	PiGift,
	PiLifebuoyDuotone,
	PiUserCircleDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavItems = () => {
	const { isOfferwallsAvailable } = storeToRefs(useUserStore());
	const { t } = useI18n();

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiClipboardText,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiCoins,
			label: 'Offers',
			to: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			isActive: Boolean(isOfferwallsAvailable.value),
			pin: NEW,
			id: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiTrophy,
			label: t(LEADERBOARD),
			to: USER_ROUTE_NAME.LEADERBOARD,
			isActive: true,
			id: USER_ROUTE_NAME.LEADERBOARD,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiUserCircle,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop'],
		},
		{
			icon: PiDotsThreeCircle,
			label: t(MORE),
			isActive: true,
			id: 'more',
			placed: ['desktop', 'mobile'],
			children: [
				{
					icon: PiUserCircle,
					label: t(ACCOUNT),
					to: USER_ROUTE_NAME.PROFILE,
					isActive: true,
					id: USER_ROUTE_NAME.PROFILE,
					placed: ['mobile'],
				},
				{
					icon: PiLifebuoyDuotone,
					label: t(HELP),
					to: USER_ROUTE_NAME.SUPPORT,
					isActive: true,
					id: USER_ROUTE_NAME.SUPPORT,
					placed: ['desktop', 'mobile'],
				},
				{
					icon: PiGift,
					label: t(REFER_LINK_TITLE),
					subTitle: t(INVITE_FRIEND),
					to: USER_ROUTE_NAME.REFERRALS,
					isActive: true,
					id: USER_ROUTE_NAME.REFERRALS,
					placed: ['desktop', 'mobile'],
				},
				{
					icon: PiUserCircleDuotone,
					label: t(REDEEM_LINK_TITLE),
					isActive: true,
					id: 'redeem',
					placed: ['desktop', 'mobile'],
				},
			],
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items
			.filter((item) => item.placed.includes(type) && item.isActive)
			.map((item) => ({
				...item,
				children: item.children ? filterItems(item.children, type) : undefined,
			}));
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
