<script setup lang="ts">
import { computed } from 'vue';
import {
	LEADERBOARD_LOCKED_MESSAGE,
	LEADERBOARD_LOCKED_PROGRESS,
	LEADERBOARD_LOCKED_TITLE,
} from '@/locales/constants';
import {
	PrimeText,
	PrimeProgressbar,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import PrizePool from '@/views/leaderboard/components/headers/PrizePool.vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import type { LeaderboardInfo } from '@/types';

defineProps<{
	info: LeaderboardInfo | undefined;
}>();

const { leaderboard } = storeToRefs(useUserStore());
const { isTablet } = useScreenSize();

const progress = computed(() =>
	leaderboard.value?.successful_completes
		? leaderboard.value.successful_completes * 10
		: 0
);
</script>

<template>
	<section class="leaderboard-header__locked">
		<div class="leaderboard-header--cup">🏆</div>
		<PrimeText
			tag="div"
			weight="700"
			:size="isTablet ? 'lg' : 'xl'"
			class="leaderboard-header--title"
		>
			{{ $t(LEADERBOARD_LOCKED_TITLE) }}
		</PrimeText>
		<PrimeText
			tag="div"
			weight="500"
			size="sm"
			color="grey-700"
			class="leaderboard-header--description"
		>
			{{
				$t(LEADERBOARD_LOCKED_MESSAGE, {
					value:
						leaderboard.total_completes_required_to_unlock -
						leaderboard.successful_completes,
				})
			}}
		</PrimeText>
		<div class="header-progress">
			<PrimeProgressbar class="header-progress--bar" :value="progress" />
			<PrimeText
				tag="p"
				size="sm"
				color="grey-600"
				class="header-progress--text"
				align="center"
			>
				{{
					$t(LEADERBOARD_LOCKED_PROGRESS, {
						value: leaderboard.successful_completes,
						target: leaderboard.total_completes_required_to_unlock,
					})
				}}
			</PrimeText>
		</div>
		<PrizePool :info="info" />
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.leaderboard-header__locked {
	width: 100%;
	padding: 1.5rem 0.875rem;
	background: #fff;
	border: 1px solid var(--grey-200);
	border-radius: 0.75rem;

	@include breakpoint(mobile) {
		padding: 1.5rem;
	}

	.leaderboard-header--cup {
		text-align: center;
		font-size: 2rem;
		line-height: 1;
	}

	.leaderboard-header--title {
		margin-top: 0.5rem;
		text-align: center;
	}

	.leaderboard-header--description {
		margin-top: 0.5rem;
		text-align: center;
	}

	.header-progress {
		&--bar {
			margin-top: 1rem;

			@include breakpoint(mobile) {
				margin-top: 1.5rem;
			}
		}

		&--text {
			margin-top: 0.375rem;
		}
	}
}
.prize-pool {
	margin-top: 1rem;
}
</style>
