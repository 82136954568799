<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiHcChevron_right } from '@primeinsightsgroupllc/prime-icons';
import {
	OFFERWALLS_DESCRIPTION,
	OFFERWALLS_DISCLAIMER,
	OFFERWALLS_TITLE,
	PENDING_POINTS,
} from '@/locales/constants';
import { OFFERWALLS_PENDING_MODAL } from '@/constants/modals';
import ImportantNote from '@/components/ImportantNote.vue';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useUserStore } from '@/stores/user';

const modalStorage = useModalStorage();
const userStore = useUserStore();
const { offerwallsPendings, offerwallsPoints } = storeToRefs(userStore);

const onPendingBtnClick = async () => {
	await modalStorage.pushModal({
		name: OFFERWALLS_PENDING_MODAL,
		data: {
			offerwallsPendings: offerwallsPendings.value,
			offerwallsPoints: offerwallsPoints.value,
		},
	});
};

onBeforeMount(() => {
	userStore.fetchOfferwallPending();
});
</script>

<template>
	<div class="offerwalls-description__container">
		<div class="offerwalls-description__wrapper">
			<PrimeText tag="h2" size="xl" weight="700">
				{{ $t(OFFERWALLS_TITLE) }}
			</PrimeText>

			<PrimeText tag="p" color="grey-700" class="description" size="sm">
				{{ $t(OFFERWALLS_DESCRIPTION) }}
			</PrimeText>

			<ImportantNote :text="$t(OFFERWALLS_DISCLAIMER)" />

			<div
				v-if="offerwallsPendings.length"
				class="offerwalls-description__pending"
			>
				<PrimeText tag="p" size="sm" weight="500" color="grey-700">
					{{ $t(PENDING_POINTS) }}:
				</PrimeText>
				<button
					class="offerwalls-description__button"
					@click="onPendingBtnClick"
				>
					<PrimeText tag="p" size="sm" weight="600">
						{{ offerwallsPoints }}
					</PrimeText>
					<PiHcChevron_right size="1rem" />
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins';

.offerwalls-description__container {
	padding: 1.5rem 0.875rem;
	background-color: var(--white);
	border-radius: 0.75rem;
	max-width: 100%;
	box-shadow: var(--box-shadow);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include breakpoint(tablet) {
		padding: 1.5rem;
	}

	.offerwalls-description__wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		flex: 1 1 auto;
		max-width: 55.625rem;

		.description {
			margin-top: 0.25rem;
		}

		.note-content-wrapper {
			margin: 1rem 0 0;
		}
	}

	.offerwalls-description__pending {
		display: flex;
		align-items: center;
		margin-top: 1.25rem;
	}

	.offerwalls-description__button {
		display: flex;
		align-items: center;
		margin-left: 0.25rem;
		border: none;
		background: var(--grey-100);
		border-radius: 3.125rem;
		padding: 0.25rem 0.625rem;
		cursor: pointer;
	}
}
</style>
