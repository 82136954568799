<script lang="ts" setup>
import type { Component } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineEmits<{
	(e: 'click'): void;
}>();

const {
	handler,
	variant = 'dark',
	endIcon = null,
} = defineProps<{
	icon: Component;
	title: string;
	handler: () => void;
	variant?: 'dark' | 'secondary';
	endIcon?: Component | null;
}>();

const onClick = handler || (() => {});
</script>

<template>
	<div
		class="prime-navigation-outlined"
		:class="[variant]"
		@click.stop="onClick"
	>
		<PrimeText
			tag="p"
			size="sm"
			weight="700"
			class="prime-navigation-outlined__text"
		>
			<component :is="icon" size="1.375rem" :color="`base-${variant}`" />
			{{ title }}
		</PrimeText>
		<component :is="endIcon" v-if="endIcon" size="1.25rem" />
	</div>
</template>

<style lang="scss" scoped>
.prime-navigation-outlined {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
	padding: 0.625rem 0.875rem;
	background-color: transparent;
	border-radius: 3.125rem;
	border: 1px solid var(--grey-400);
	transition: 0.3s;

	&__text {
		display: flex;
		align-items: center;
		gap: 0.625rem;
	}

	&:hover {
		background-color: var(--grey-400);
	}
}
</style>
