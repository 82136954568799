<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	COINS,
	HOW_LEVELS_WORK,
	LVL,
	NEXT_LVL_REWARD,
	LEVEL,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { NEW_LEVELS_MODAL } from '@/constants/modals';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import OverlayPanel from 'primevue/overlaypanel';

import {
	PiQuestionCircle,
	PiCrownDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeText,
	PrimeButton,
	PrimeProgressbar,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const route = useRoute();
const {
	levelActual,
	levelNext,
	levelReward,
	coinsEarned,
	coinsTarget,
	currentTier,
} = storeToRefs(useUserStore());
const modalStorage = useModalStorage();
const { isLaptop, isMobile, isMobileOnly } = useScreenSize();

const userLevelInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);

const progress = computed(
	() => (Number(coinsEarned.value) / Number(coinsTarget.value)) * 100
);
const levelClass = computed(() => {
	const value =
		typeof levelActual.value === 'string'
			? parseInt(levelActual.value, 10)
			: levelActual.value;

	if (value > 30) return 'diamond';
	if (value > 20) return 'gold';
	if (value > 10) return 'silver';
	if (value > 5) return 'bronze';

	return 'default';
});
const buttonText = computed(() => {
	if (!isLaptop.value) return `${levelActual.value} ${LEVEL}`;
	return levelActual.value;
});

const toggleUserLevelInfo = (event: Event) => {
	userLevelInfo.value?.toggle(event);
};
const openNewLevelsModal = (event: any) => {
	userLevelInfo.value?.toggle(event);

	modalStorage.pushModal({
		name: NEW_LEVELS_MODAL,
	});
};

watch(route, async () => {
	if (userLevelInfo.value) {
		userLevelInfo.value?.hide();
	}
});
</script>

<template>
	<div class="user-level">
		<!-- Circle with user level -->

		<button
			v-if="currentTier"
			class="user-level-button"
			:class="levelClass"
			@click="toggleUserLevelInfo"
		>
			<component :is="PiCrownDuotone" v-if="!isMobileOnly" color="inherit" />
			<PrimeText weight="600" color="white" size="sm" transform="capitalize">
				{{ buttonText }}
			</PrimeText>
		</button>

		<!-- OverlayPanel with user progress info -->
		<OverlayPanel
			id="user-level--info"
			ref="userLevelInfo"
			append-to="body"
			style="width: 16.25rem"
		>
			<div class="level-info--progress">
				<div class="level-info--state-wrapper">
					<div class="level-info--progress-value">
						<PrimeText size="sm" weight="500">
							{{ localizePoints(coinsEarned) }}
						</PrimeText>
						/
						<PrimeText size="xs" color="grey-600">
							{{ localizePoints(coinsTarget) }}
						</PrimeText>
					</div>
					<PrimeText weight="600" size="xs">
						{{ levelNext }} {{ $t(LVL) }}
					</PrimeText>
				</div>
				<PrimeProgressbar class="level-info--progress-bar" :value="progress" />
			</div>
			<PrimeText tag="p" size="sm" color="grey-600">
				{{ $t(NEXT_LVL_REWARD) }}
			</PrimeText>
			<PrimeText tag="p" size="sm" weight="600" class="level-info--next-reward">
				{{ localizePoints(levelReward) }} {{ $t(COINS) }}
			</PrimeText>

			<PrimeButton
				type="outline"
				variant="grey"
				full-width
				:label="$t(HOW_LEVELS_WORK)"
				@click="openNewLevelsModal"
			>
				<template #iconLeft>
					<PiQuestionCircle />
				</template>
			</PrimeButton>
		</OverlayPanel>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.user-level-button {
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2.125rem;
	height: 2rem;
	border-radius: 3.125rem;
	color: #fff;
	border: 0;
	cursor: pointer;
	box-shadow: 0px 2.81px 8.42px 0px #00000057 inset;
	padding: 0 0.25rem;

	&.default {
		background-color: var(--base-primary);
	}
	&.bronze {
		background-color: var(--bronze-light);
	}
	&.silver {
		background-color: var(--silver-light);
	}
	&.gold {
		background-color: var(--gold-light);
	}
	&.diamond {
		background: var(--dimond-gradient);
	}

	svg {
		width: 1.25rem;
		height: 1.25rem;
		margin-right: 0.25rem;
	}

	.p-text {
		flex: none;
	}

	@include breakpoint(mobile) {
		svg {
			height: 1.5rem;
			width: 1.5rem;
		}

		& {
			width: unset;
			min-height: unset;
			height: 2.5rem;
			padding: 0 0.75rem;
		}
	}
	@include breakpoint(leptop) {
		& {
			min-width: 6.375rem;
		}
	}
}

.user-level {
	z-index: 1;
}

#user-level--info.p-overlaypanel {
	&:before,
	&:after {
		transform: none !important;
	}
}

#user-level--info .p-overlaypanel-content {
	display: flex;
	flex-direction: column;
	padding: 0.75rem;
	.level-info {
		&--current {
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--base-primary);
			background-color: var(--grey-100);
			border-radius: 0.25rem;
			text-align: center;
			padding: 0.375rem;
			& > span {
				font-size: 1rem;
				font-weight: 600;
				line-height: 1.25;
				padding: 0.125rem 0.5rem 0;
			}
		}
		&--state-wrapper {
			display: flex;
			justify-content: space-between;
			margin-top: 0.75rem;
			margin-bottom: 0.375rem;
		}
		&--progress-value {
			font-size: 0.75rem;
			line-height: 1.4;
			color: var(--grey-600);
		}

		&--progress-bar {
			margin-bottom: 0.75rem;
		}

		&--next-reward {
			margin-bottom: 0.75rem;
		}
	}
}
</style>
