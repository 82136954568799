import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import {
	ClaimStatuses,
	PostMessages,
	SignatureActions,
	TrackingEvents,
} from '@/enums';
import { useRewardsStore } from '@/stores/rewards';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { storeToRefs } from 'pinia';
import {
	CONFIRM_CLAIM_MODAL,
	ONE_CLICK_SURVEY_MODAL,
	REWARD_SENT_MODAL,
	WELCOME_BONUS_MODAL,
	PRESET_WELCOME_BONUS_MODAL,
} from '@/constants/modals';
import { useUserStore } from '@/stores/user';
import {
	oneClickSurveyRewardStorage,
	signatureLoginActionStorage,
} from '@/utils/local-storage';
import { usePostMessageHandler } from '@/utils/composables/usePostMessageHandler';
import { InAppReview } from '@capacitor-community/in-app-review';
import { randomizeChance } from '@/utils/helpers';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppStore } from '@/stores/app';
import { useBonusStore } from '@/stores/bonus';

export const useSurveysView = () => {
	const { integrationScriptLoading, renderPrimeSurveys, unmountPrimeSurveys } =
		usePrimeSurveysIntegration();

	const RELOAD_ATTEMPTS: number = 3;
	const CHECK_SCRIPT_INTERVAL_SECONDS: number = 15;

	const scriptLoadError = ref(false);
	const reloadCounter = ref(0);
	const interval = ref<any>(null);

	const checkScriptRender = (): void => {
		interval.value = setInterval(() => {
			if (reloadCounter.value > RELOAD_ATTEMPTS) {
				// Show SurveysFallback
				scriptLoadError.value = true;
				clearInterval(interval.value);
				return;
			}

			if (document.querySelector('.integration-script-container')) {
				clearInterval(interval.value);
			} else {
				reloadCounter.value++;
				unmountPrimeSurveys();
				renderPrimeSurveys('#prime-survey');
			}
		}, CHECK_SCRIPT_INTERVAL_SECONDS * 1000);
	};

	onMounted(() => {
		renderPrimeSurveys('#prime-survey');

		// Check and Reload script with some interval
		checkScriptRender();
	});

	onBeforeUnmount(() => {
		clearInterval(interval.value);
		unmountPrimeSurveys();
	});

	const appStore = useAppStore();
	const rewardsStore = useRewardsStore();
	const modalStorage = useModalStorage();
	const { isMobileApp } = useMobileApp();

	const {
		isWelcomeBonusSelected,
		userData,
		isPasswordSet,
		appReviewProbability,
	} = storeToRefs(useUserStore());
	const bonusStore = useBonusStore();

	onMounted(async () => {
		if (
			rewardsStore.claimStatus === ClaimStatuses.SUCCESS ||
			signatureLoginActionStorage.value ===
				SignatureActions.CLAIM_USER_CONFIRMATION
		) {
			await modalStorage.pushModal({
				name: REWARD_SENT_MODAL,
			});
			rewardsStore.setClaimStatus(null);
			signatureLoginActionStorage.value = null;
		}

		if (rewardsStore.claimStatus === ClaimStatuses.USER_CONFIRMATION) {
			await modalStorage.pushModal({ name: CONFIRM_CLAIM_MODAL });
			rewardsStore.setClaimStatus(null);
		}

		if (oneClickSurveyRewardStorage.value) {
			await modalStorage.pushModal({
				name: ONE_CLICK_SURVEY_MODAL,
				options: { reward: oneClickSurveyRewardStorage.value },
			});
		}
	});

	const isShowBonusModal = computed(() =>
		Boolean(
			userData.value && isPasswordSet.value && !isWelcomeBonusSelected.value
		)
	);

	watch(
		isShowBonusModal,
		async (value) => {
			if (value) {
				await bonusStore.fetchBonusList();
				const welcomeModalToShow = bonusStore.isPresetBonus
					? PRESET_WELCOME_BONUS_MODAL
					: WELCOME_BONUS_MODAL;

				await modalStorage.pushModal({
					name: welcomeModalToShow,
					options: { useDefaultClose: false },
				});
			}
		},
		{ immediate: true }
	);

	usePostMessageHandler(async (event: MessageEvent) => {
		if (event.data.message === PostMessages.QUALIFICATION_IN_PROGRESS) {
			appStore.setQualificationInProgress(event.data.qualification_in_progress);
		}

		if (event.data.message === PostMessages.SURVEY_STARTED) {
			await appStore.trackEvent(TrackingEvents.SURVEY_STARTED, {
				survey_id: event.data.survey_id,
			});
		}

		if (event.data.message === PostMessages.SURVEY_COMPLETED) {
			const money_value = (parseInt(event.data.survey_points) / 100).toString();

			// Based on appReviewProbability randomly ask for review
			if (isMobileApp) {
				const askForReviewChance: boolean = randomizeChance(
					appReviewProbability.value
				);

				if (askForReviewChance) {
					await InAppReview.requestReview();
				}
			}

			await appStore.trackEvent(TrackingEvents.SURVEY_COMPLETED, {
				survey_id: event.data.survey_id,
				survey_points: event.data.survey_points,
				survey_count: event.data.user_completes_count,
			});
		}
	});

	return {
		integrationScriptLoading,
	};
};
