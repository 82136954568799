<script setup lang="ts">
import {
	REF_HOW_IT_WORKS,
	REF_HIW_STEP_1_TITLE,
	REF_HIW_STEP_2_TITLE,
	REF_HIW_STEP_3_TITLE,
	REF_HIW_STEP_2,
	REF_HIW_STEP_1,
	REF_HIW_STEP_3,
} from '@/locales/constants';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const steps = [
	{
		title: REF_HIW_STEP_1_TITLE,
		description: REF_HIW_STEP_1,
	},
	{
		title: REF_HIW_STEP_2_TITLE,
		description: REF_HIW_STEP_2,
	},
	{
		title: REF_HIW_STEP_3_TITLE,
		description: REF_HIW_STEP_3,
	},
];
</script>

<template>
	<section class="referrals-hiw">
		<PrimeText tag="h4" weight="600" size="1xl" class="referrals-hiw--title">
			{{ $t(REF_HOW_IT_WORKS) }}
		</PrimeText>
		<div class="referrals-hiw--steps">
			<div
				v-for="(step, index) in steps"
				:key="index"
				class="referrals-hiw--step--wrapper"
			>
				<div class="referrals-hiw--step">
					<PrimeText size="sm" weight="600" color="white">
						{{ index + 1 }}
					</PrimeText>
				</div>
				<div class="referrals-hiw--step--content">
					<PrimeText
						tag="p"
						weight="700"
						size="sm"
						class="referrals-hiw--step--title"
					>
						{{ $t(step.title) }}
					</PrimeText>
					<PrimeText tag="p" size="xs" weight="500" color="grey-700">
						{{ $t(step.description) }}
					</PrimeText>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals-hiw {
	padding: 0.75rem;
	border-radius: 0.75rem;
	background: var(--base-primary-09);

	@include breakpoint(mobile) {
		& {
			padding: 1.5rem;
		}
	}

	&--title.p-text {
		color: var(--base-primary-900);
	}

	&--steps {
		display: flex;
		flex-direction: column;
		margin-top: 1.125rem;
	}

	&--step {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 1.75rem;
		height: 1.75rem;
		margin-right: 0.875rem;
		background: var(--diamond);
		border-radius: 50%;
		flex: none;

		&:last-child {
			margin-bottom: 0;
		}

		&--wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 1.313rem;
		}

		&--wrapper:last-child {
			margin-bottom: 0;
		}

		&--content {
			flex: 1;
		}
		&--title.p-text {
			margin-bottom: 0.375rem;
			color: var(--base-primary-900);
		}
	}
}
</style>
