<script setup lang="ts">
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { COINS } from '@/locales/constants';
import RewardImage from '@/components/common/RewardImage.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { userSelectedReward } = storeToRefs(useUserStore());
</script>

<template>
	<div class="reward">
		<div class="logo">
			<RewardImage
				:src="userSelectedReward?.image_url || ''"
				:alt="userSelectedReward?.name || 'logo'"
			/>
		</div>
		<PrimeText weight="500" size="sm" color="grey-700" class="coins-value">
			{{
				`${userSelectedReward?.name} - ${localizePoints(userSelectedReward?.coin_value || 0)} ${$t(COINS)}`
			}}
		</PrimeText>
		<PrimeText weight="700" class="money-value">
			{{
				transformCurrency(
					userSelectedReward?.money_value,
					userSelectedReward?.currency,
					2
				)
			}}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';
.reward {
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo {
		position: relative;
		width: 8.125rem;
		height: 4.375rem;
		border-radius: 0.5rem;
		overflow: hidden;
		@include breakpoint(mobile) {
			width: 9.25rem;
			height: 5rem;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.money-value {
		font-size: 2.125rem;

		@include breakpoint(mobile) {
			font-size: 2.75rem;
		}
	}

	.coins-value {
		margin: 0.75rem 0 0.25rem;
	}
}
</style>
