<script lang="ts" setup>
import type { RouteRecordName } from 'vue-router';
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { PiCaretLeft } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const authStore = useAuthStore();
const router = useRouter();

const {
	backRoute,
	title = '',
	backButtonVisible = true,
} = defineProps<{
	title?: string;
	backButtonVisible?: boolean;
	backRoute?: RouteRecordName;
}>();

const goBack = async () => {
	if (backRoute) {
		await router.push({ name: backRoute });
	} else if (window.history.state.back) {
		router.go(-1);
	} else {
		await router.push({ name: 'surveys' });
	}
};

onBeforeMount(() => {
	authStore.setTopNavigationVisibility(false);
});
onBeforeUnmount(() => {
	authStore.setTopNavigationVisibility(true);
});
</script>
<template>
	<div class="subpage">
		<header v-if="title || backButtonVisible" class="subpage__header">
			<PrimeButton
				v-if="backButtonVisible"
				rounded="rounded"
				variant="primary"
				type="outline"
				class="subpage__header--back"
				@click="goBack"
			>
				<template #icon><PiCaretLeft size="1.5rem" /></template>
			</PrimeButton>
			<PrimeText weight="600" align="center" transform="capitalize">
				{{ title }}
			</PrimeText>
		</header>
		<div class="subpage__content">
			<slot />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.subpage {
	display: flex;
	flex-direction: column;
	width: 100%;
	@include breakpoint(mobile) {
		margin-top: 2.25rem;
	}

	&__header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 2.5rem;
		padding: 0.625rem 4rem;
	}
}

.p-btn.subpage__header--back {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	padding: 0;
	width: 2.5rem;
	height: 2.5rem;
	font-size: 1.5rem;
}

.subpage__content {
	flex: 1;
	margin: 1.5rem auto;
	max-width: 43.75rem;
	width: inherit;
}
</style>
