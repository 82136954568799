<script lang="ts" setup>
import { PiLockDuotone } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	UNLOCK_OFFERWALLS_TARGET,
	UNLOCK_OFFERWALLS_PROGRESS,
	UNLOCK,
} from '@/locales/constants';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';

const { offerwallsMeta } = storeToRefs(useUserStore());

const currentProgress = computed(() => {
	return offerwallsMeta.value
		? (offerwallsMeta.value.progress / offerwallsMeta.value.target) * 100
		: 0;
});

const completesLeft = computed(() => {
	const left = offerwallsMeta.value
		? offerwallsMeta.value.target - offerwallsMeta.value.progress
		: 0;
	return left > 0 ? left : 0;
});
</script>

<template>
	<div class="offerwalls-locked__container">
		<div class="offerwalls-locked__content">
			<PiLockDuotone color="base-primary" size="2.75rem" />
			<PrimeText size="1xl" weight="700" class="offerwalls-locked__title">
				{{ `${$t(UNLOCK)} offerwalls!` }}
			</PrimeText>
			<PrimeText size="sm" color="grey-700" weight="500" align="center">
				{{ $t(UNLOCK_OFFERWALLS_TARGET, { target: completesLeft }) }}
			</PrimeText>
			<PrimeProgressbar
				:value="currentProgress"
				progress-bar-rounded
				class="offerwalls-locked__progress"
			/>
			<PrimeText size="sm" weight="500" color="grey-600">
				{{
					$t(UNLOCK_OFFERWALLS_PROGRESS, {
						progress: `${offerwallsMeta?.progress}/${offerwallsMeta?.target}`,
					})
				}}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.offerwalls-locked__container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(235, 244, 254, 0.9);
	backdrop-filter: blur(1px);
	z-index: 2;
}

.offerwalls-locked__content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 2.5rem 0.875rem;
}

.offerwalls-locked__title {
	margin: 0.5rem 0;
}

.offerwalls-locked__progress {
	margin: 1.5rem 0 0.375rem;
}

@include breakpoint(mobile) {
	.offerwalls-locked__content {
		padding: 3.75rem 1.5rem;
	}
}
</style>
