import { useRoute, useRouter } from 'vue-router';
import { onBeforeMount, ref } from 'vue';

export const useHistoryView = () => {
	const route = useRoute();
	const router = useRouter();
	const activeTabIndex = ref<number>(0);
	const tabs: Array<string> = ['rewards', 'transactions'];
	const paramsTabIndex = tabs.findIndex((tab) => route.params.type === tab);

	onBeforeMount(() => {
		if (paramsTabIndex !== activeTabIndex.value) {
			activeTabIndex.value = paramsTabIndex;
		}
	});

	const onTabChange = async ({ index }: { index: number }) => {
		await router.replace({
			name: String(route.name),
			params: { type: tabs[index] },
		});
	};

	return {
		onTabChange,
		activeTabIndex,
	};
};
