import SurveysView from '@/views/surveys/SurveysView.vue';
import ProfileView from '@/views/profile/ProfileView.vue';
import SettingsView from '@/views/settings/SettingsView.vue';
import HistoryView from '@/views/history/HistoryView.vue';
import LeaderboardView from '@/views/leaderboard/LeaderboardView.vue';
import ConfirmClaimView from '@/views/confirm-claim/ConfirmClaimView.vue';
import PrizeDrawView from '@/views/prize-draw/PrizeDrawView.vue';
import ReferralsView from '@/views/referrals/ReferralsView.vue';
import IntegrationSinglePageView from '@/views/IntegrationSinglePageView.vue';
import EmailSubscriptionsView from '@/views/email-subscriptions/EmailSubscriptionsView.vue';
import AchievementsView from '@/views/achievements/AchievementsView.vue';
import BonusDayView from '@/views/bonus-day/BonusDayView.vue';
import { FeatureFlags } from '@/enums';
import OfferwallsPartnersListView from '@/views/offerwalls/views/partners/OfferwallsPartnersListView.vue';
import OfferwallsPartnerView from '@/views/offerwalls/views/partner/OfferwallsPartnerView.vue';
import { USER_ROUTE_NAME } from '@/constants/routes';
import {
	checkContextIdHook,
	checkHistoryTypeHook,
	checkInitializedClaim,
} from '@/router/hooks';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: SurveysView,
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: ProfileView,
	},
	{
		path: '/settings',
		name: USER_ROUTE_NAME.SETTINGS,
		component: SettingsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/reward-history/:type?',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: HistoryView,
		beforeEnter: checkHistoryTypeHook,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/leaderboard',
		name: USER_ROUTE_NAME.LEADERBOARD,
		component: LeaderboardView,
	},
	{
		path: '/confirm-claim',
		name: USER_ROUTE_NAME.CONFIRM_CLAIM,
		component: ConfirmClaimView,
		beforeEnter: checkInitializedClaim,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/prize-draw',
		name: USER_ROUTE_NAME.PRIZE_DRAW,
		component: PrizeDrawView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: ReferralsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/recent-activity',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY,
		component: IntegrationSinglePageView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: IntegrationSinglePageView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		component: EmailSubscriptionsView,
		meta: {
			ignoreAuth: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/achievements',
		name: USER_ROUTE_NAME.ACHIEVEMENTS,
		component: AchievementsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/bonus-day',
		name: USER_ROUTE_NAME.BONUS_DAY,
		component: BonusDayView,
		meta: {
			hideMobileNav: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/offerwalls',
		name: USER_ROUTE_NAME.OFFERWALLS,
		meta: {
			feature: FeatureFlags.OFFER_WALLS,
		},
		children: [
			{
				path: 'partners',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
				component: OfferwallsPartnersListView,
			},
			{
				path: 'partner/:partnerId',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNER,
				component: OfferwallsPartnerView,
				meta: {
					hideMobileBalance: true,
				},
			},
		],
	},
];
