<script lang="ts" setup>
import API from '@/api';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { CONTINUE_WITH_SOCIAL } from '@/locales/constants';
import {
	PrimeButton,
	PrimeSocialNetworkIcon,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { SocialProviders } from '@/enums';

const {
	provider,
	nonPrefix = false,
	rounded = false,
} = defineProps<{
	provider: SocialProviders;
	nonPrefix?: boolean;
	rounded?: boolean;
}>();

const { t } = useI18n();
const { isMobileApp } = useMobileApp();

const emits = defineEmits<{
	(e: 'loading', value: boolean): void;
	(e: 'auth', value: string): void;
}>();

const getProviderRedirect = async () => {
	if (isMobileApp) {
		emits('auth', provider);
	} else {
		try {
			emits('loading', true);
			const response = await API.get<{ url: string }>(
				`auth/social/redirect?redirect_to_app=1&provider=${provider}`,
				{ authRequired: false }
			);
			window.location.href = response?.data.url;
		} finally {
			emits('loading', false);
		}
	}
};

const buttonLabel = computed((): string => {
	return !nonPrefix
		? `${t(CONTINUE_WITH_SOCIAL)} <span>${provider}</span>`
		: '<template/>';
});
const btnRounded = computed(() => (rounded ? 'rounded' : 'lg'));
</script>

<template>
	<PrimeButton
		variant="grey"
		type="outline"
		:label="buttonLabel"
		:class="{ 'social__btn-inline': nonPrefix }"
		:rounded="btnRounded"
		@click="getProviderRedirect"
	>
		<template #iconLeft>
			<PrimeSocialNetworkIcon :name="provider" />
		</template>
	</PrimeButton>
</template>

<style lang="scss">
button.p-btn {
	&.social__btn-inline {
		min-width: 0;
		flex: 1;

		span.p-btn-icon--left {
			margin-right: 0;
		}
	}
}
</style>
