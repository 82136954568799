<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import {
	PiCalendarBlank,
	PiClock,
	PiGearSix,
	PiLock,
	PiRocketLaunchDuotone,
	PiSignout,
} from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeText,
	PrimeDivider,
	PrimeButton,
	PrimeCountryFlag,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	ACCOUNT_SETTINGS,
	BONUS_DAY,
	CURRENT_TIME,
	JOINED,
	LOGOUT,
} from '@/locales/constants';
import { useUserStore } from '@/stores/user';
import AppMeta from '@/components/AppMeta.vue';
import PrimeNavigationOutlined from '@/views/profile/components/PrimeNavigationOutlined.vue';
import { getLocalTime, getMonthTerm } from '@/utils/date';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useDev } from '@/utils/composables/useDev';
import { BONUS_DAY_MODAL } from '@/constants/modals';
import SectionWrapper from '@/components/common/SectionWrapper.vue';

const {
	username,
	email,
	countryCode,
	countryName,
	timezone,
	createdAt,
	isBonusDayUnlocked,
	bonusDayBonus,
} = storeToRefs(useUserStore());

const { isDev } = useDev();
const router = useRouter();

const userCreatedAtMonth = computed((): string => {
	const date = new Date(createdAt.value);

	return getMonthTerm(date.getMonth());
});

const userCreatedAtYear = computed((): number => {
	return new Date(createdAt.value).getFullYear();
});

const localTime = computed<string>((): string => {
	if (timezone.value) {
		const { hours, minutes } = getLocalTime(timezone.value);
		return `${hours}:${minutes}`;
	}
	return '';
});

const { pushModal } = useModalStorage();

const handleBonusDayClick = () => {
	if (isBonusDayUnlocked.value) {
		router.push({ name: 'bonus-day' });
	} else {
		pushModal({ name: BONUS_DAY_MODAL });
	}
};
</script>

<template>
	<SectionWrapper>
		<div>
			<section class="profile--user-info">
				<div class="profile--user-item">
					<PrimeText v-if="username" weight="600" size="lg">
						{{ username }}
					</PrimeText>
					<PrimeText color="grey-700" size="xs" weight="500">
						{{ email }}
					</PrimeText>
				</div>

				<!-- Country code/name -->
				<div class="profile--user-item">
					<div v-if="countryCode && countryName" class="profile--user-country">
						<PrimeCountryFlag :country-code="countryCode" />
						<PrimeText weight="600" size="sm"> {{ countryName }}</PrimeText>
					</div>
				</div>

				<!-- Register date and local time -->
				<div class="profile--user-item">
					<section class="profile--date-time">
						<div
							v-if="userCreatedAtMonth && userCreatedAtYear"
							class="profile--user-date"
						>
							<PiCalendarBlank color="grey-700" size="1.25rem" />
							<PrimeText weight="500" size="xs" color="grey-700">
								{{ $t(JOINED) }} {{ $t(userCreatedAtMonth) }}
								{{ userCreatedAtYear }}
							</PrimeText>
						</div>
						<div v-if="localTime" class="profile--user-time">
							<PiClock size="1.25rem" />
							<PrimeText weight="500" size="xs" color="grey-700">
								{{ $t(CURRENT_TIME) }}
								<span class="time-value">{{ localTime }}</span>
							</PrimeText>
						</div>
					</section>
				</div>
			</section>
			<!-- Account Settings -->
			<AppMeta v-if="isDev" />

			<PrimeDivider color="grey-100" top-gap="1rem" bottom-gap="1rem" />
			<div class="navigations-wrapper">
				<div class="navigations-wrapper-left">
					<PrimeNavigationOutlined
						:icon="PiGearSix"
						:title="$t(ACCOUNT_SETTINGS)"
						:handler="() => router.push({ name: 'settings' })"
					/>

					<PrimeNavigationOutlined
						:icon="PiRocketLaunchDuotone"
						:title="$t(BONUS_DAY, { dynamicValue: bonusDayBonus })"
						:handler="handleBonusDayClick"
						:end-icon="!isBonusDayUnlocked ? PiLock : null"
						variant="secondary"
					/>
				</div>

				<PrimeButton
					:label="$t(LOGOUT)"
					type="text"
					icon-left
					variant="grey"
					@click="() => router.replace('logout')"
				>
					<template #iconLeft> <PiSignout /> </template>
				</PrimeButton>
			</div>
		</div>
	</SectionWrapper>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';
.profile--user-info {
	display: grid;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 1rem;

	.profile--user-item:nth-child(1) {
		display: flex;
		flex-direction: column;
		gap: 0.375rem;
	}
	.profile--user-item:nth-child(2) {
		flex: none;
	}
	.profile--user-item:last-child {
		width: 100%;
	}

	@include breakpoint(mobile) {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 0.375rem;

		.profile--user-item:nth-child(1) {
			grid-area: 1 / 1 / 3 / 3;
		}
		.profile--user-item:nth-child(2) {
			display: flex;
			justify-content: flex-end;
			grid-area: 1 / 3 / 2 / 7;
		}
		.profile--user-item:last-child {
			display: flex;
			justify-content: flex-end;
			grid-area: 2 / 3 / 3 / 7;
		}
	}
}

.profile--user-country {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	background-color: var(--grey-100);
	padding: 0.25rem 0.625rem;
	border-radius: 3.125rem;
}

.profile--date-time {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 0.75rem;

	.profile--user-date,
	.profile--user-time {
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
		& > svg {
			margin-inline-end: 0.25rem;
		}
	}
	.profile--user-time {
		.time-value {
			color: var(--text-default);
		}
	}
}

.navigations-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.75rem;

	.navigations-wrapper-left {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 0.75rem;
	}

	.prime-navigation-outlined {
		width: 100%;
		justify-content: center;
	}

	@include breakpoint(mobile) {
		flex-direction: row;
		justify-content: space-between;

		.prime-navigation-outlined {
			width: auto;
			justify-content: space-between;
		}

		.navigations-wrapper-left {
			width: unset;
			flex-direction: row;
		}
	}
}
</style>
