<script lang="ts" setup>
import {
	EMAIL_PREFERENCES,
	EMAIL_SUBS_TITLE,
	EMAIL_SUBS_SAVE,
	EMAIL_SUBS_SHOW_ALL,
} from '@/locales/constants';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import { useEmailSubscriptionsView } from '@/views/email-subscriptions/composables';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import SubscriptionItem from '@/views/email-subscriptions/components/SubscriptionItem.vue';

const {
	subsToShow,
	isAllSubsShow,
	isAuthorized,
	emailSubsTranslations,
	isDisable,
	handleSave,
} = useEmailSubscriptionsView();
</script>

<template>
	<main class="email-subs">
		<LayoutSubpage
			:title="$t(EMAIL_PREFERENCES)"
			:back-button-visible="isAuthorized"
		>
			<div class="email-subs--inner">
				<PrimeText tag="h2" weight="600" size="sm">
					{{ $t(EMAIL_SUBS_TITLE) }}
				</PrimeText>
				<div v-if="subsToShow" class="email-subs__items">
					<SubscriptionItem
						v-for="(subscription, index) in subsToShow"
						:key="index"
						:type="subscription.type"
						:subscribed="subscription.subscribed"
						:title="$t(emailSubsTranslations[subscription.type]?.title)"
						:description="
							$t(emailSubsTranslations[subscription.type]?.description)
						"
						@change="subscription.subscribed = $event"
					/>
				</div>
				<template v-if="!isAuthorized">
					<button
						v-if="!isAllSubsShow"
						class="email-subs__show-all-btn"
						@click="isAllSubsShow = true"
					>
						{{ $t(EMAIL_SUBS_SHOW_ALL) }}
					</button>
				</template>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(EMAIL_SUBS_SAVE)"
					:disabled="!subsToShow || isDisable"
					@click="handleSave"
				/>
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.email-subs {
	width: 100%;
	&--inner {
		padding: 1.5rem;
		border-radius: 0.75rem;
		border: 1px solid var(--grey-200);
		background: var(--white);
		padding: 1.5rem 0.875rem;
	}

	@include breakpoint(mobile) {
		&--inner {
			padding: 1.5rem;
		}
	}

	&__show-all-btn {
		margin-bottom: 1.5rem;
		cursor: pointer;
		border: 0;
		color: var(--base-primary);
		background: transparent;
		font-size: 0.75rem;
		text-decoration: underline;

		&:hover {
			color: var(--base-primary-hover);
		}
	}
}
</style>
