<script setup lang="ts">
import { ref } from 'vue';
import LeaderboardHeader from './components/LeaderboardHeader.vue';
import LeaderboardTable from './components/LeaderboardTable.vue';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const loading = ref(false);
</script>

<template>
	<PrimeLoaderSpinner v-if="loading" />
	<main v-show="!loading" class="leaderboard-main">
		<div class="leaderboard-inner">
			<LeaderboardHeader @loading="loading = $event" />
			<LeaderboardTable />
		</div>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard-main {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.leaderboard-inner {
	width: 100%;
	margin-top: 1.125rem;

	@include breakpoint(mobile) {
		margin-top: 2.25rem;
	}
}

.leaderboard {
	padding: 1rem;
	background-color: var(--white);
	font-weight: 500;
	width: 100%;

	@include breakpoint(mobile) {
		margin: 2rem 0 0;
		padding: 1.5rem;
		box-shadow: var(--card-shadow);
		border-radius: 0.75rem 0.75rem 0 0;
		max-width: 40.625rem;
	}

	&__divider {
		&.p-divider {
			margin-left: -1rem;
			margin-right: -1rem;
			width: calc(100% + 2rem);

			@include breakpoint(mobile) {
				margin-left: -1.5rem;
				margin-right: -1.5rem;
				width: calc(100% + 3rem);
			}
		}
	}
}

.leaderboard-users {
	margin-top: 1.5rem;
}
</style>
