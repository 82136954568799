<script setup lang="ts">
import { ref } from 'vue';
import { localizePoints } from '@/utils/helpers';
import { computed } from 'vue';
import AchievementIcon from '@/views/achievements/components/AchievementIcon.vue';
import {
	VALUE_OF_TOTAL,
	POINTS,
	CLAIM_REWARD,
	CLAIMED,
} from '@/locales/constants';
import {
	PrimeText,
	PrimeButton,
	PrimeProgressbar,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const props = defineProps<{
	achievementKey: string;
	achievementId: number;
	progress: number;
	goal: number | string;
	isClaimed: boolean;
	isActive: boolean;
	levelKey: string;
	description: string;
	isClaimAvailable: boolean;
	coins: number | string;
}>();

defineEmits<{
	(e: 'claim-achievement', id: string): void;
}>();

const { isMobile } = useScreenSize();

const loading = ref(false);

const achievementProgress = computed(() => {
	const goal =
		typeof props.goal === 'string' ? parseInt(props.goal) : props.goal;
	const widthProgress = (props.progress / goal) * 100;
	return widthProgress > 100 ? 100 : widthProgress;
});

const inProgress = computed<boolean>(() => {
	return props.isActive && !props.isClaimed;
});
</script>

<template>
	<div class="achievement">
		<AchievementIcon
			:achievement-key="achievementKey"
			:level-key="levelKey"
			:alt="description"
			:active="isActive"
		/>
		<div class="achievement-content">
			<PrimeText
				tag="h4"
				weight="500"
				class="achievement-title"
				:size="isMobile ? 'sm' : 'base'"
			>
				{{ description }}
			</PrimeText>
			<div v-if="inProgress" class="achievement-progress">
				<PrimeProgressbar :value="achievementProgress" />
				<PrimeText
					weight="500"
					class="progress-progress"
					:size="isMobile ? 'xs' : 'sm'"
				>
					{{
						$t(VALUE_OF_TOTAL, {
							value: progress,
							total: goal,
						})
					}}
				</PrimeText>
			</div>

			<PrimeText
				v-if="isClaimed"
				tag="div"
				class="achievement-bonus is_claimed"
				:size="isMobile ? 'xs' : 'sm'"
			>
				{{ $t(CLAIMED) }}
			</PrimeText>
			<div v-else class="achievement-claim-info">
				<PrimeButton
					v-if="isClaimAvailable"
					type="gradient"
					size="small"
					class="achievement-button"
					:class="{ disabled: loading }"
					:label="$t(CLAIM_REWARD)"
					@click="$emit('claim-achievement', achievementId.toString())"
				/>
				<PrimeText
					weight="500"
					class="achievement-bonus"
					size="xs"
					:class="{ badge: !isClaimAvailable }"
				>
					+{{ typeof coins === 'number' ? localizePoints(coins) : coins }}
					{{ $t(POINTS) }}
				</PrimeText>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.achievement {
	display: flex;
	align-items: center;
	gap: 0.875rem;
	border-bottom: 1px solid var(--grey-200);
	padding: 1rem 0;

	&:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}

	:deep(.achievement-icon) {
		flex: none;
		width: 4.375rem;

		@include breakpoint(mobile) {
			width: 6.25rem;
		}
	}
}

.achievement-content {
	width: 100%;
}

.achievement-title.p-text {
	word-break: break-all;
}

.achievement-progress {
	display: flex;
	align-items: center;
	gap: 0.688rem;
	margin: 0.5rem 0;
	text-align: center;

	.progress-progress.p-text {
		flex: none;
		white-space: nowrap;
	}
}

.achievement-claim-info {
	display: flex;
	flex-wrap: wrap;
	margin-top: 0.5rem;
}

.achievement-button {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-radius: 0.5rem;
}

.achievement-bonus.p-text {
	display: inline-flex;
	align-items: center;
	padding: 0.375rem 0.625rem;
	border-radius: 3.125rem;

	&.badge {
		color: var(--states-success);
		background-color: var(--rgba-states-success-15);
	}

	&.is_claimed {
		color: var(--text-default);
		background-color: var(--rgba-text-default-15);
		margin-top: 0.5rem;
	}
}
</style>
