import API from '@/api';
import type { BonusDay, MessageResponse, StatusResponse } from '@/types';

export const fetchBonusDays = async (): Promise<BonusDay[]> => {
	const { data } = await API.get<{ days: BonusDay[] }>(
		'/api/user-bonus-days/days'
	);
	return data.days;
};

export const selectBonusDay = async (
	day: number
): Promise<MessageResponse & StatusResponse> => {
	const { data, status } = await API.post<MessageResponse>(
		'/api/user-bonus-days',
		{ day_of_week: day }
	);

	return {
		message: data.message,
		status,
	};
};
