<script lang="ts" setup>
import { computed } from 'vue';
import {
	LOGIN_PAGE_TITLE,
	LOGIN_OR_SIGN_UP,
	EMAIL,
	CONTINUE,
	OR_CONTINUE_WITH,
	MILLION,
	EARNED_BY_MEMBERS,
} from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import SocialButton from '@/components/auth/SocialButton.vue';
import FeathersLeft from '@/assets/icons/feathers-left.svg';
import FeathersRight from '@/assets/icons/feathers-right.svg';
import { useAppLoginView } from '@/views/app-login/composables';
import CardUsd from '@/assets/cards/card-usd.svg';
import CardEur from '@/assets/cards/card-eur.svg';
import CardGpb from '@/assets/cards/card-gpb.svg';
import {
	PrimeText,
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	form,
	handleSubmitForm,
	getValidationFieldStatus,
	getValidationFieldText,
	loading,
	socialAuthButtons,
	handleSocialAuth,
	handleLoading,
	currencySymbol,
	amount,
} = useAppLoginView();

const card = computed(() => {
	switch (currencySymbol.value) {
		case '€':
			return CardEur;
		case '£':
			return CardGpb;
		default:
			return CardUsd;
	}
});
</script>

<template>
	<div class="login-page">
		<div class="login-top">
			<PrimeText
				tag="h1"
				class="login-title"
				weight="700"
				size="3xl"
				color="white"
				align="center"
			>
				{{ $t(LOGIN_PAGE_TITLE) }}
			</PrimeText>
			<div class="login-cards">
				<img :src="card" alt="card image" />
			</div>
		</div>
		<div class="form-wrapper">
			<div class="form-top">
				<img :src="FeathersLeft" alt="Feathers left" />
				<div class="form-top-title">
					<PrimeText tag="span" weight="600" color="grey-100" size="xl">
						{{
							$t(MILLION, {
								currency: currencySymbol,
								count: amount,
							})
						}}</PrimeText
					>
					<PrimeText tag="span" weight="500" color="grey-100" size="sm">
						{{ $t(EARNED_BY_MEMBERS) }}
					</PrimeText>
				</div>
				<img :src="FeathersRight" alt="Feathers Right" />
			</div>
			<div class="login-form">
				<form @submit.prevent="handleSubmitForm">
					<PrimeText tag="h3" weight="600" class="form-title">{{
						$t(LOGIN_OR_SIGN_UP)
					}}</PrimeText>
					<PrimeInput
						v-model="form.email"
						:placeholder="$t(EMAIL)"
						:invalid="!!getValidationFieldStatus('email')"
						:error-text="getValidationFieldText('email')?.toString()"
						autocomplete="username"
						type="email"
					/>

					<PrimeButton
						full-width
						role="submit"
						class="submit-button"
						:disabled="loading"
						:label="$t(CONTINUE)"
						rounded="rounded"
					/>
				</form>

				<div class="social-login__title">
					<div class="title-divider" />
					<PrimeText tag="p" weight="500" class="title-text">
						{{ $t(OR_CONTINUE_WITH) }}
					</PrimeText>
					<div class="title-divider" />
				</div>
				<div class="providers-container">
					<SocialButton
						v-for="provider in socialAuthButtons"
						:key="provider"
						:provider="provider"
						non-prefix
						rounded
						@loading="handleLoading($event)"
						@auth="handleSocialAuth($event)"
					/>
				</div>
			</div>
		</div>
		<PrimeLoader v-if="loading" full-page :rounded="false" />
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.login-page {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto;
	width: 35.625rem;
	max-width: 100%;
	padding: 0 0.625rem 1rem;
	@include breakpoint(mobile) {
		padding: 0 0.625rem 1.875rem;
	}
}

.login-top {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.login-title {
	max-width: 23.125rem;
	@include breakpoint(mobile) {
		max-width: unset;
	}
}

.login-cards {
	flex: 1;
	display: flex;
	justify-content: center;
	margin: 1rem 0;

	img {
		display: block;
		width: auto;
		height: 100%;
		max-height: 13.438rem;
	}
}

.form-wrapper {
	flex: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: var(--deep-navy);
	border-radius: 1.5rem;
	overflow: hidden;
}

.form-top {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.563rem 1.5rem;
}

.form-top-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 1.75rem;
}

.login-form {
	background: var(--white);
	padding: 1rem;

	.providers-container {
		margin-bottom: 0;
	}
}
.form-title {
	text-align: center;
	margin-bottom: 0.75rem;
}

.submit-button {
	margin-top: 0.75rem;
}

.password-wrapper {
	position: relative;
}

.social-login {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.social-login__title {
	display: flex;
	align-items: center;
	margin-top: 1rem;

	& .title-divider {
		height: 1px;
		flex: 1 1 auto;
		background-color: var(--grey-200);
	}

	& .title-text {
		margin: 0 0.675rem;
	}
}

.providers-container {
	display: flex;
	gap: 0.75rem;
	margin-top: 0.75rem;

	:deep(.provider-button) {
		flex: 1 0 30%;
	}
	:deep(.p-btn) {
		height: 2.75rem;
	}
}
</style>
