<script setup lang="ts">
import { PrimeText, PrimeToggle } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { ref, onMounted } from 'vue';

const { type, subscribed, title, description } = defineProps<{
	type: string;
	subscribed: boolean;
	title: string;
	description: string;
}>();

const emits = defineEmits(['change']);

const isSubscribed = ref(false);

const updateItem = (value: boolean): void => {
	emits('change', value);
};

onMounted(() => {
	isSubscribed.value = subscribed;
});
</script>

<template>
	<div class="subscription-item">
		<template v-if="type">
			<PrimeToggle
				v-model="isSubscribed"
				:checked="subscribed"
				:label="title"
				@input="updateItem(isSubscribed)"
			/>
			<PrimeText tag="div" size="xs" weight="500" color="grey-600">
				{{ description }}
			</PrimeText>
		</template>
	</div>
</template>

<style scoped lang="scss">
.subscription-item {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border-bottom: 1px solid var(--p-grey-200);

	&:last-child {
		border-bottom: 0;
	}

	:deep(.p-toggle-text) {
		font-weight: 600;
	}
}
</style>
