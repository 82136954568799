<script setup lang="ts">
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import {
	BONUS_DAY,
	BONUS_DAY_SELECT_DESCRIPTION,
	SAVE,
	SELECT_DAY,
	SELECT_DAY_INTERVAL,
} from '@/locales/constants';
import {
	PrimeButton,
	PrimeRadio,
	PrimeText,
	PrimeSkeleton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import TierCircle from '@/components/tiers/TierCircle.vue';
import { PiRocketLaunchDuotone } from '@primeinsightsgroupllc/prime-icons';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { DAYS_LABELS } from '@/views/bonus-day/constants';
import { useBonusDayView } from '@/views/bonus-day/composables';

const { bonusDayBonus } = storeToRefs(useUserStore());
const {
	isLoading,
	bonusDays,
	selectedDay,
	handleClick,
	isDayChanged,
	intervalDate,
	isSaveLoading,
	handleSelectedBonusDay,
} = useBonusDayView();
</script>

<template>
	<main class="bonus-day-page">
		<LayoutSubpage :title="$t(BONUS_DAY, { value: bonusDayBonus })">
			<div class="content">
				<div class="content-top">
					<TierCircle size="3.25rem" tier-key="silver">
						<PiRocketLaunchDuotone size="1.75rem" color="white" />
					</TierCircle>
					<div class="content-top__right">
						<PrimeText tag="p" weight="700">
							{{ $t(BONUS_DAY, { value: bonusDayBonus }) }}
						</PrimeText>
						<PrimeText tag="p" weight="500" size="sm">
							{{ $t(BONUS_DAY_SELECT_DESCRIPTION, { value: bonusDayBonus }) }}
						</PrimeText>
					</div>
				</div>
				<div class="content-bottom">
					<PrimeText tag="div" weight="600" class="title">
						{{ $t(SELECT_DAY, { value: bonusDayBonus }) }}
					</PrimeText>

					<template v-if="isLoading">
						<PrimeSkeleton
							v-for="i in 6"
							:key="i"
							class="bonus-day-skeleton"
							height="1.375rem"
						/>
					</template>
					<template v-else>
						<PrimeRadio
							v-for="day in bonusDays"
							:key="day.id"
							v-model="selectedDay"
							:input-id="day.id"
							:val="day.id"
							:label="$t(DAYS_LABELS[day.id])"
							name="bonus-day"
							@click="handleClick"
						/>

						<PrimeText
							v-if="isDayChanged && intervalDate"
							tag="p"
							weight="500"
							size="sm"
							color="critical"
						>
							{{ $t(SELECT_DAY_INTERVAL) }}

							<PrimeText weight="600" size="sm" color="critical">
								{{ intervalDate }}
							</PrimeText>
						</PrimeText>
					</template>
				</div>

				<div class="bonus-day-action">
					<PrimeButton
						:label="$t(SAVE)"
						:loading="isSaveLoading"
						:disabled="!isDayChanged"
						type="gradient"
						full-width
						@click="handleSelectedBonusDay"
					/>
				</div>
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.bonus-day-page {
	width: 100%;

	& .page-card,
	& .content {
		display: flex;
		flex-direction: column;
	}

	& .page-card {
		padding-bottom: 0.875rem;
	}

	& .content {
		flex: 1 0 auto;
		padding: 1.5rem;
		border-radius: 0.75rem;
		border: 1px solid var(--grey-200);
		background: var(--white);
		padding: 1.5rem 0.875rem;
	}

	@include breakpoint(mobile) {
		& .content {
			padding: 1.5rem;
		}
	}
}

.content-top {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 2rem;
}

.content-bottom {
	border: 1px solid var(--p-grey-200);
	border-radius: 0.5rem;
	padding: 0.75rem 1rem;

	& .title {
		margin-bottom: 1rem;
	}

	.p-radio-wrapper:not(:last-child) {
		margin-bottom: 0.75rem;
	}
}

.bonus-day-action {
	margin-top: 2rem;
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	@include breakpoint(mobile) {
		justify-content: flex-start;
	}
}

.bonus-day-skeleton {
	&:not(:last-child) {
		margin-bottom: 1rem;
	}
}
</style>
