<script lang="ts" setup>
import { PrimeNavigation } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useNavigation } from './composables/index';
import { Nav } from '@/enums';

const { isOpen, navigationClick, items } = useNavigation(Nav.DESKTOP);
</script>

<template>
	<div class="desktop-nav">
		<PrimeNavigation
			v-for="item in items"
			:key="item.id"
			color="primary"
			:data="{ ...item, isOpen }"
			@navigation-click="navigationClick($event)"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.desktop-nav {
	display: flex;
	flex-direction: column;
	gap: 0.625rem;
}
</style>
