<script lang="ts" setup>
import type { ClaimedReward } from '@/types';
import { AMOUNT, COINS_USED, DATE } from '@/locales/constants';
import RewardImage from '@/components/common/RewardImage.vue';
import ClaimRewardDetails from '@/views/history/views/rewards/components/ClaimRewardDetails.vue';
import { localizePoints, toDate, transformCurrency } from '@/utils/helpers';
import ClaimStatus from '@/views/history/views/rewards/components/ClaimStatus.vue';
import type { ClaimStatuses } from '@/enums';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import {
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineProps<{
	item: ClaimedReward;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';
</script>

<template>
	<div class="claim-item-container">
		<div class="claim-item-content">
			<div class="claim-item-top">
				<div class="partner-info">
					<RewardImage :src="item.reward_image_url" :alt="item.reward_name" />
					<PrimeText weight="600" tag="p" size="sm">
						{{ item.reward_name }}
					</PrimeText>
				</div>
				<ClaimStatus :status="item.status as ClaimStatuses" />
			</div>
			<PrimeDivider />
			<div class="claim-item-bottom">
				<ClaimRewardDetails
					:title="$t(AMOUNT)"
					:data="transformCurrency(item.money_value, item.currency)"
				/>
				<ClaimRewardDetails
					:title="$t(COINS_USED)"
					:data="localizePoints(item.coin_value)"
				/>
				<ClaimRewardDetails
					:title="$t(DATE)"
					:data="toDate(item.date, localization)"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.claim-item-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 0.875rem;
	background-color: var(--white);
	border-radius: 0.875rem;
	box-shadow: var(--box-shadow);
	@include breakpoint(mobile) {
		margin-bottom: 1.125rem;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.claim-item-content {
	.claim-item-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.75rem 0.875rem;
	}

	.claim-item-bottom {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 0.875rem;
		padding: 0.75rem 0.875rem;

		&:last-child {
			align-items: flex-end;
		}
	}
}

.partner-info {
	display: flex;
	align-items: center;

	& p {
		margin-left: 0.5rem;
	}
}
</style>
