import {
	FRIDAY_FULL,
	MONDAY_FULL,
	SATURDAY_FULL,
	THURSDAY_FULL,
	TUESDAY_FULL,
	WEDNESDAY_FULL,
} from '@/locales/constants';

export const DAYS_LABELS: Record<number, string> = {
	1: MONDAY_FULL,
	2: TUESDAY_FULL,
	3: WEDNESDAY_FULL,
	4: THURSDAY_FULL,
	5: FRIDAY_FULL,
	6: SATURDAY_FULL,
};
