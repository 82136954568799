<script setup lang="ts">
import { onMounted } from 'vue';
import { usePrimeChatIntegration } from '@/utils/composables/useIntegrationChatRender';

const CHAT_CONTAINER_ID = 'prime-chat';
const { renderPrimeChat } = usePrimeChatIntegration();

onMounted(() => {
	renderPrimeChat(`#${CHAT_CONTAINER_ID}`);

	// TODO: remove all Intercom chat code when PrimeChat will be released
	const intercomChatEl = document.querySelector(
		'.intercom-lightweight-app'
	) as HTMLDivElement | null;

	if (intercomChatEl) {
		intercomChatEl.style.display = 'none';
	}
});
</script>

<template>
	<div :id="CHAT_CONTAINER_ID" />
</template>
