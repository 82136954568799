<script setup lang="ts">
import { getLeaderboardUsers } from '@/api';
import TableSkeleton from '@/components/common/SkeletonTable.vue';
import {
	LEADERBOARD_TABLE_TITLE,
	LEADERBOARD_TABLE_DESCRIPTION,
	USER,
	EARNED,
	PRIZE,
	LOADING_MORE,
	NO_MORE_ENTRIES,
} from '@/locales/constants';
import type { LeaderboardUser } from '@/types';
import LeaderboardUserRow from '@/views/leaderboard/components/LeaderboardUserRow.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	PrimeText,
	PrimeInfiniteList,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { leaderboard, username } = storeToRefs(useUserStore());
</script>

<template>
	<section class="leaderboard-users">
		<div class="leaderboard-users-top">
			<PrimeText tag="h1" size="xl" weight="700">
				{{ $t(LEADERBOARD_TABLE_TITLE) }}
			</PrimeText>
			<PrimeText tag="p" size="sm" color="grey-700">
				{{ $t(LEADERBOARD_TABLE_DESCRIPTION) }}
			</PrimeText>
		</div>
		<div
			v-if="username && leaderboard.unlocked"
			class="leaderboard-users--table"
		>
			<div class="leaderboard-table__header-row">
				<PrimeText size="xs" weight="500" color="grey-700">
					{{ $t(USER) }} / {{ $t(EARNED) }}
				</PrimeText>
				<PrimeText size="xs" weight="500" color="grey-700">
					{{ $t(PRIZE) }}
				</PrimeText>
			</div>
			<PrimeInfiniteList
				:page-offset="50"
				:no-more-data-text="$t(NO_MORE_ENTRIES)"
				:loading-text="$t(LOADING_MORE)"
				:fetch="getLeaderboardUsers"
				data-test-id="transactions-list"
			>
				<template #skeleton-component>
					<TableSkeleton />
				</template>
				<template #list-item="{ item: user }: { item: LeaderboardUser }">
					<LeaderboardUserRow :user="user" />
				</template>
			</PrimeInfiniteList>
		</div>
		<TableSkeleton v-else />
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard-users {
	padding: 1.5rem;
	background: #fff;
	border: 1px solid var(--grey-200);
	border-radius: 0.75rem;
}

.leaderboard-users-top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 1.125rem;
	gap: 0.25rem;
}

.leaderboard-users--table {
	width: 100%;
}

.leaderboard-table__header-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 0.5rem;
	padding-bottom: 0.5rem;
}
</style>
