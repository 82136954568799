<script lang="ts" setup>
import { ClaimStatuses } from '@/enums';
import {
	CONFIRM_VIA_EMAIL,
	DONE,
	ERROR,
	PROCESSING,
	REFUNDED,
} from '@/locales/constants';
import type { Component } from 'vue';
import { computed } from 'vue';
import type { IconColors } from '@/types';
import {
	PiCheckCircleDuotone,
	PiClockCountdownDuotone,
	PiQuestionCircleDuotone,
	PiWarningCircleDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type StatusContent = {
	text: string;
	icon: Component;
	color: IconColors;
	bg: string;
};

type StatusesContent = Record<ClaimStatuses, StatusContent>;

const props = defineProps<{
	status: ClaimStatuses;
}>();

const CLAIM_STATUS_CONTENT: StatusesContent = {
	[ClaimStatuses.SUCCESS]: {
		text: DONE,
		icon: PiCheckCircleDuotone,
		color: 'states-success',
		bg: 'rgba-states-success-10',
	},
	[ClaimStatuses.REFUND]: {
		text: REFUNDED,
		icon: PiWarningCircleDuotone,
		color: 'base-primary',
		bg: 'base-primary-50',
	},
	[ClaimStatuses.FAILED]: {
		text: ERROR,
		icon: PiWarningCircleDuotone,
		color: 'states-critical',
		bg: 'rgba-states-critical-10',
	},
	[ClaimStatuses.PROCESSING]: {
		text: PROCESSING,
		icon: PiQuestionCircleDuotone,
		color: 'states-warning',
		bg: 'rgba-states-warning-10',
	},
	[ClaimStatuses.USER_CONFIRMATION]: {
		text: CONFIRM_VIA_EMAIL,
		icon: PiClockCountdownDuotone,
		color: 'base-primary',
		bg: 'base-primary-50',
	},
};

const statusContent = computed(() => CLAIM_STATUS_CONTENT[props.status]);
</script>

<template>
	<div
		class="claim-status"
		:style="{ background: `var(--${statusContent.bg})` }"
	>
		<PrimeText weight="500" size="xs" class="status-text">
			{{ $t(statusContent.text) }}
		</PrimeText>
		<component
			:is="statusContent.icon"
			:color="statusContent.color"
			size="1.25rem"
		/>
	</div>
</template>

<style lang="scss" scoped>
.claim-status {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.5rem;
	border-radius: 3.125rem;

	& .status-text {
		margin-inline-end: 0.25rem;
	}
}
</style>
