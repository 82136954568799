import API from '@/api';
import type { AchievementGroup } from '@/types';

export const getAchievements = async (): Promise<AchievementGroup[]> => {
	const { data } = await API.get<{ achievements: AchievementGroup[] }>(
		'/api/achievements'
	);
	return data ? [...data.achievements] : [];
};

export const postAchievementsLevels = async (
	achievementId: string
): Promise<{ status: number; data: string }> => {
	const { status, data } = await API.post<{ message: string }>(
		`/api/achievement-levels/${achievementId}/claim`
	);
	return {
		status,
		data: data.message,
	};
};
