import type { Component, ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';
import { useClaimBaseInfo } from '@/views/confirm-claim/composables/useClaimBaseInfo';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { InternalRewards } from '@/enums';
import ConfirmClaimDefault from '@/views/confirm-claim/components/ConfirmClaimDefault.vue';
import ConfirmClaimDataPayPal from '@/views/confirm-claim/components/ConfirmClaimDataPayPal.vue';
import ConfirmClaimDataRevolut from '@/views/confirm-claim/components/ConfirmClaimDataRevolut.vue';
import ConfirmClaimDataAch from '@/views/confirm-claim/components/ConfirmClaimDataAch.vue';

const confirmClaimComponents: Record<string, Component> = {
	[InternalRewards.PAYPAL]: ConfirmClaimDataPayPal,
	[InternalRewards.REVOLUT]: ConfirmClaimDataRevolut,
	[InternalRewards.ACH]: ConfirmClaimDataAch,
	default: ConfirmClaimDefault,
};

export const useConfirmClaimView = (): {
	restOfTheBalanceText: ComputedRef<string>;
	isClaimAvailable: ComputedRef<boolean>;
	component: ComputedRef<Component>;
	confirmClaimData: Ref<{ handleConfirmClaim: () => void } | null>;
	isButtonDisabled: Ref<boolean>;
	hasEnoughPoints: ComputedRef<boolean>;
	isButtonDisable: ComputedRef<boolean>;
	hasSomeInput: ComputedRef<boolean>;
	confirmClaimProcess: () => void;
} => {
	const { isClaimAvailable, restOfTheBalanceText } = useClaimBaseInfo();

	const { userSelectedReward, collectedCoins } = storeToRefs(useUserStore());

	const confirmClaimData = ref<{ handleConfirmClaim: () => void } | null>(null);
	const isButtonDisabled = ref(true);
	const hasSomeInput = computed(() => {
		if (
			userSelectedReward.value?.reward_external_id === 'ACH_INTERNAL' ||
			userSelectedReward.value?.reward_external_id === 'PAYPAL_INTERNAL' ||
			userSelectedReward.value?.reward_external_id === 'REVOLUT_INTERNAL'
		)
			return true;
		return false;
	});
	const isButtonDisable = computed(
		() =>
			(isButtonDisabled.value && hasSomeInput.value) || !hasEnoughPoints.value
	);
	const component = computed(() => {
		if (userSelectedReward.value) {
			return (
				confirmClaimComponents[userSelectedReward.value.reward_external_id] ||
				confirmClaimComponents['default']
			);
		} else {
			return confirmClaimComponents['default'];
		}
	});
	const hasEnoughPoints = computed(() =>
		userSelectedReward.value
			? collectedCoins.value >= userSelectedReward.value.coin_value
			: false
	);

	const confirmClaimProcess = () => {
		confirmClaimData.value?.handleConfirmClaim();
	};

	return {
		restOfTheBalanceText,
		isClaimAvailable,
		component,
		hasSomeInput,
		isButtonDisabled,
		isButtonDisable,
		confirmClaimData,
		confirmClaimProcess,
		hasEnoughPoints,
	};
};
