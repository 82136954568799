<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { PiCaretRight } from '@primeinsightsgroupllc/prime-icons';
import type { Component } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();

const emits = defineEmits<{
	(e: 'click'): void;
}>();

const props = withDefaults(
	defineProps<{
		icon: Component;
		title: string;
		details?: string;
		subtitle?: string;
		route?: object | null;
	}>(),
	{
		details: '',
		route: null,
	}
);

const topText = computed(() =>
	props.subtitle ? props.title + ' ' + props.details : props.title
);
const bottomText = computed(() =>
	props.subtitle ? props.subtitle : props.details
);
const goTo = () => {
	if (props.route) {
		router.push(props.route);
	}

	emits('click');
};
</script>

<template>
	<div
		class="prime-navigation"
		:class="{ 'has-route': route }"
		@click.stop="goTo"
	>
		<div class="prime-navigation-content">
			<div class="prime-navigation-icon">
				<component :is="icon" v-if="icon" color="base-primary" />
			</div>
			<div class="prime-navigation-text">
				<PrimeText tag="h5" weight="700" size="lg">
					{{ topText }}
				</PrimeText>
				<PrimeText
					v-if="bottomText"
					tag="p"
					weight="500"
					size="sm"
					color="grey-700"
				>
					{{ bottomText }}
				</PrimeText>
			</div>
		</div>
		<div v-if="route" class="prime-navigation-link" :to="route">
			<PiCaretRight size="1.125rem" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prime-navigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.75rem;
	padding: 1rem 2.063rem 1rem 1rem;
	background-color: var(--white);
	border: 1px solid var(--grey-200);
	border-radius: 3.125rem;
	align-items: center;
	transition: ease 0.3s;

	&.has-route {
		cursor: pointer;
		background-color: var(--base-primary-50);

		&:hover {
			background-color: var(--base-primary-100);
			border-color: var(--base-primary-400);
		}
	}
}

.prime-navigation-content {
	display: flex;
	align-items: center;
	gap: 0.75rem;
}

.prime-navigation-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 3.25rem;
	height: 3.25rem;
	min-width: 3.25rem;
	min-height: 3.25rem;
	border: 1px solid var(--grey-300);
	border-radius: 50%;
	background: var(--white);

	.has-route & {
		border-color: var(--base-primary-200);
	}
}

.prime-navigation-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

.prime-navigation-link {
	flex: none;
}
</style>
