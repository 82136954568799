<script setup lang="ts">
import {
	RESEND,
	RESET_PASSWORD,
	RESET_PASSWORD_SENT_DETAILS,
	RESET_PASSWORD_SENT_MESSAGE,
	RESET_PASSWORD_SENT_SPAM,
} from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { useForgotPasswordView } from '@/views/forgot-password/composables';
import {
	PrimeDivider,
	PrimeText,
	PrimeCooldownButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const COOL_DOWN_SECONDS = 15;

const { email, resendResetPassword, isLoading, cooldownButtonEl } =
	useForgotPasswordView();
</script>

<template>
	<AuthPageContent :title="$t(RESET_PASSWORD)" back-route="signin">
		<template #default>
			<div class="password-sent-icon">
				<PiEnvelopeDuotoneThin color="base-primary" size="100%" />
			</div>

			<PrimeText tag="div" weight="500" class="password-sent-email">
				<span v-html="$t(RESET_PASSWORD_SENT_MESSAGE, { email })" />
			</PrimeText>
			<PrimeText
				tag="div"
				weight="500"
				size="sm"
				color="grey-700"
				class="password-sent"
			>
				{{ $t(RESET_PASSWORD_SENT_DETAILS) }}
			</PrimeText>
			<PrimeDivider top-gap="0.75rem" />
			<PrimeText
				tag="div"
				size="xs"
				color="grey-700"
				class="password-sent spam-info"
			>
				{{ $t(RESET_PASSWORD_SENT_SPAM) }}
			</PrimeText>
			<PrimeCooldownButton
				ref="cooldownButtonEl"
				data-test-id="resend-button"
				:text="$t(RESEND)"
				:timer="COOL_DOWN_SECONDS"
				@click="resendResetPassword"
			/>
			<PrimeLoader v-if="isLoading" />
		</template>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.forgot-password-container {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	width: 100%;
	max-width: 26.875rem;
	margin: 0 auto;
	text-align: center;
}

.password-sent-email {
	margin-bottom: 0.25rem;

	:deep(.user-email) {
		word-break: break-word;
	}
}

.password-sent {
	margin-top: 0.75rem;

	&.spam-info {
		margin-bottom: 1.5rem;
	}
}

.password-sent-icon {
	margin: 0 auto;
	width: 3.375rem;
	height: 3.375rem;

	@include breakpoint(mobile) {
		width: 5.875rem;
		height: 5.875rem;
	}
}
</style>
