export const SING_IN = 'sign_in';
export const GOTCHA = 'gotcha';
export const SAVE = 'save';
export const PRIVACY_POLICY = 'privacy_policy';
export const LEGAL_INFORMATION = 'legal_information';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const RESET_PASSWORD = 'reset_password';
export const CONFIRM_EMAIL = 'confirm_email';
export const CONFIRM_EMAIL_MESSAGE = 'confirm_email_message';
export const RESEND = 'resend';
export const EMAIL_REQUIRED = 'email_required';
export const EMAIL_NOT_VALID = 'email_not_valid';
export const PASSWORD_REQUIRED = 'password_required';
export const PASSWORD_MIN_LENGTH = 'password_min_length';
export const MIN_COINS_VALUE = 'min_coins_value';
export const COINS = 'coins';
export const POINTS = 'points';
export const SELECT = 'select';
export const COINS_REACHED = 'coins_reached';
export const SUCCESS = 'success';
export const SCREENOUT = 'screenout';
export const BONUS = 'bonus';
export const CLAIM = 'claim';
export const BALANCE = 'balance';
export const CONGRATS = 'congrats';
export const WELL_DONE = 'well_done';
export const CHECK_IT_OUT = 'check_it_out';
export const YOU_CAN_CLAIM = 'you_can_claim';
export const BALANCE_REST = 'balance_rest';
export const REWARD_SENT = 'reward_sent';
export const REWARD_RECEIVE_TIME = 'reward_receive_time';
export const OKAY = 'okay';
export const SEND = 'send';
export const ENTER_NEW_PASSWORD = 'enter_new_password';
export const CONFIRM_PASSWORD = 'confirm_password';
export const CONFIRM = 'confirm';
export const CONFIRM_PASSWORD_REQUIRED = 'confirm_password_required';
export const UPDATED_BALANCE = 'updated_balance';
export const SOMETHING_WENT_WRONG = 'something_went_wrong';
export const TRY_AGAIN = 'try_again';
export const REFRESH_PAGE = 'refresh_page';
export const TRANSACTIONS = 'transactions';
export const TRANSACTIONS_EMPTY_TITLE = 'transactions_empty_title';
export const TRANSACTIONS_EMPTY_DESCRIPTION = 'transactions_empty_description';
export const SELECT_REWARD = 'select_reward';
export const SELECT_REWARDS = 'select_rewards';
export const SEARCH_REWARDS = 'search_rewards';
export const CHANGE_REWARD = 'change_reward';
export const NO_REWARDS_MESSAGE = 'no_rewards_message';
export const CLAIM_REWARD = 'claim_reward';
export const TRANSFER = 'transfer';
export const PREPAID_CARDS = 'prepaid_cards';
export const GIFT_CARDS = 'gift_cards';
export const CHARITY = 'charity';
export const VIEW_MORE_REWARDS = 'view_more_rewards';
export const NO_REWARDS_WITH_NAME = 'no_rewards_with_name';
export const SURVEYS = 'surveys';
export const ACCOUNT_SETTINGS = 'account_settings';
export const REWARD_HISTORY = 'reward_history';
export const REWARDS = 'rewards';
export const REWARD = 'reward';
export const DELETE = 'delete';
export const DELETE_ACCOUNT = 'delete_account';
export const DELETE_ACCOUNT_QUESTION = 'delete_account_question';
export const DELETE_ACCOUNT_INFORMATION = 'delete_account_information';
export const NO_REWARDS_HISTORY_TITLE = 'no_rewards_history_title';
export const NO_REWARDS_HISTORY_MESSAGE = 'no_rewards_history_message';
export const COLLECT_POINTS = 'collect_points';
export const DONE = 'done';
export const CANCELLED = 'cancelled';
export const AMOUNT = 'amount';
export const COINS_USED = 'coins_used';
export const DATE = 'date';
export const SET_USERNAME = 'set_username';
export const CHANGE_USERNAME = 'change_username';
export const ENTER_USERNAME = 'enter_username';
export const USERNAME_MIN_LENGTH = 'username_min_length';
export const USERNAME_MAX_LENGTH = 'username_max_length';
export const USERNAME_ALLOWED_SYMBOLS = 'username_allowed_symbols';
export const USERNAME_REQUIRED = 'username_required';
export const CHANGE_TIMEZONE = 'change_timezone';
export const CHANGE_EMAIL = 'change_email';
export const ENTER_NEW_EMAIL = 'enter_new_email';
export const NEW_EMAIL = 'new_email';
export const CHANGE_PASSWORD = 'change_password';
export const OLD_PASSWORD = 'old_password';
export const CHOOSE_WELCOME_BONUS = 'choose_welcome_bonus';
export const WELCOME_BONUS = 'welcome_bonus';
export const BONUS_WARNING_MESSAGE = 'bonus_warning_message';
export const UNABLE_CLAIM = 'unable_claim';
export const REDEEM_FROM = 'redeem_from';
export const BONUS_INFORMATION_MESSAGE = 'bonus_information';
export const BONUS_INFORMATION_MESSAGE_2 = 'bonus_information_2';
export const CURRENT_STREAK = 'current_streak';
export const STREAK_RULES = 'streak_rules';
export const STREAK_WEEKLY_REWARD = 'streak_weekly_reward';
export const PRIZE_DRAW = 'prize_draw';
export const DRAW_PRIZE = 'draw_prize';
export const UP_TO_COINS = 'up_to_coins';
export const PRIZE_CLAIMED = 'prize_claimed';
export const CHECK_TOMORROW = 'check_tomorrow';
export const STREAK_GONGRATS_TITLE = 'streak_gongrats_title';
export const STREAK_GONGRATS_DESCRIPTION = 'streak_gongrats_description';
export const CONGRATULATIONS = 'congratulations';
export const YOU_WON_COINS = 'you_won_coins';
export const OPEN_BOX = 'open_box';
export const SELECT_PRIZE_BOX = 'select_prize_box';
export const LVL = 'lvl';
export const LEVEL = 'level';
export const LEVEL_UP = 'level_up';
export const USER = 'user';
export const EARNED = 'earned';
export const PRIZE = 'prize';
export const COINS_FOR_NEXT_LVL = 'coins_for_next_level';
export const NEXT_LVL_REWARD = 'next_lvl_reward';
export const YOU_REACHED_LEVEL = 'you_reached_level';
export const WE_CREDITED_YOU_COINS = 'we_credited_you_coins';
export const EARN_COINS_TO_REACH_LEVEL = 'earn_coins_to_reach_level';
export const FAST_PAYOUT = 'fast_payout';
export const MOST_POPULAR = 'most_popular';
export const BIGGEST_BONUS = 'biggest_bonus';
export const SUNDAY = 'sunday';
export const MONDAY = 'monday';
export const TUESDAY = 'tuesday';
export const WEDNESDAY = 'wednesday';
export const THURSDAY = 'thursday';
export const FRIDAY = 'friday';
export const SATURDAY = 'saturday';
export const JANUARY = 'january';
export const FEBRUARY = 'february';
export const MARCH = 'march';
export const APRIL = 'april';
export const MAY = 'may';
export const JUNE = 'june';
export const JULY = 'july';
export const AUGUST = 'august';
export const SEPTEMBER = 'september';
export const OCTOBER = 'october';
export const NOVEMBER = 'november';
export const DECEMBER = 'december';
export const WE_CREDITED_COINS_WELCOME_BONUS =
	'we_credited_coins_welcome_bonus';
export const EARN_COINS_TO_WITHDRAW = 'earn_coins_to_withdraw';
export const WITHDRAW_COINS = 'withdraw_coins';
export const LOGOUT = 'logout';
export const GET_WELCOME_BONUS = 'get_welcome_bonus';
export const STATISTICS = 'statistics';
export const REFERRAL = 'referral';
export const REFERRALS = 'referrals';
export const REF_PAGE_TITLE = 'ref_page_title';
export const REF_PAGE_DESCRIPTION = 'ref_page_description';
export const REF_HOW_IT_WORKS = 'ref_how_it_works';
export const REF_HIW_STEP_1_TITLE = 'ref_hiw_step-1_title_code';
export const REF_HIW_STEP_2_TITLE = 'ref_hiw_step-2_title_code';
export const REF_HIW_STEP_3_TITLE = 'ref_hiw_step-3_title_code';
export const REF_SHARE_BUTTON = 'ref_share_button';
export const REF_SHARE_ATTENTION = 'ref_share_attention';
export const REF_STATS_EARNED_COINS = 'ref_stats_earned_coins_2';
export const REF_STATS_REGISTERED_FRIENDS = 'ref_stats_registered_friends';
export const PROFILE_POINTS_EARNED = 'profile_points_earned2';
export const PROFILE_POINTS_RATING = 'profile_points_rating';
export const PROFILE_SURVEYS_COMPLETED = 'profile_surveys_completed';
export const PROFILE_REWARDS_REDEEMED = 'profile_rewards_redeemed';
export const PROFILE_LONGEST_STREAK = 'profile_longest_streak';
export const PROFILE_LONGEST_STREAK_DAYS = 'profile_longest_streak_days';
export const PROFILE_LEADERBOARD_RECORD = 'profile_leaderboard_record';
export const PROFILE_LEADERBOARD_PLACE = 'profile_leaderboard_place';
export const COMING_SOON = 'coming_soon';
export const JOINED = 'joined';
export const CURRENT_TIME = 'current_time';
export const SELECTED_REWARD = 'selected_reward';
export const REDEEM_REWARD_WELCOME_BONUS = 'redeem_reward_welcome_bonus';
export const ACTIVITIES = 'activities';
export const HELP = 'help';
export const KEEP_IT_UP = 'keep_it_up';
export const RATING_CURRENT = 'rating_current';
export const RATING_DESCRIPTION = 'rating_description';
export const LEADERBOARD = 'leaderboard';
export const LEADERBOARD_MONTHLY = 'leaderboard_monthly';
export const LEADERBOARD_CHECK = 'leaderboard_check';
export const LEADERBOARD_LOCKED_TITLE = 'leaderboard_locked_title';
export const LEADERBOARD_LOCKED_MESSAGE = 'leaderboard_locked_message';
export const LEADERBOARD_LOCKED_PROGRESS = 'leaderboard_locked_progress';
export const LEADERBOARD_UNLOCKED_TITLE = 'leaderboard_unlocked_title';
export const LEADERBOARD_SCORED_TITLE = 'leaderboard_scored_title';
export const LEADERBOARD_WON_TITLE = 'leaderboard_won_title';
export const LEADERBOARD_UNLOCKED_MESSAGE = 'leaderboard_unlocked_message';
export const LEADERBOARD_SCORED_MESSAGE = 'leaderboard_scored_message';
export const LEADERBOARD_WON_MESSAGE = 'leaderboard_won_message';
export const LEADERBOARD_TABLE_TITLE = 'leaderboard_table_title';
export const LEADERBOARD_TABLE_DESCRIPTION = 'leaderboard_table_description';
export const LEADERBOARD_ENDED = 'leaderboard_ended';
export const AUTHORIZATION_FAILED = 'authorization_failed';
export const NO_MORE_ENTRIES = 'no_more_entries';
export const LOADING_MORE = 'loading_more';
export const OTHER_TRANSACTION = 'other_transaction';
export const EMAIL_PREFERENCES = 'email_preferences';
export const EMAIL_SUBS_TITLE = 'email_subs_title';
export const EMAIL_SUBS_SAVE = 'email_subs_save';
export const EMAIL_SUBS_SHOW_ALL = 'email_subs_show_all';
export const EMAIL_SUB_OFFERS = 'email_sub_offers';
export const EMAIL_SUB_REMINDERS = 'email_sub_reminders';
export const EMAIL_SUB_WEEKLY_REPORT = 'email_sub_weekly_report';
export const EMAIL_SUB_ACCOUNT_UPDATES = 'email_sub_account_updates';
export const EMAIL_SUB_SURVEY_INVITATIONS = 'email_sub_survey_invitations';
export const EMAIL_SUB_OFFERS_DESCRIPTION = 'email_sub_offers_description';
export const EMAIL_SUB_REMINDERS_DESCRIPTION =
	'email_sub_reminders_description';
export const EMAIL_SUB_WEEKLY_REPORT_DESCRIPTION =
	'email_sub_weekly_report_description';
export const EMAIL_SUB_ACCOUNT_UPDATES_DESCRIPTION =
	'email_sub_account_updates_description';
export const EMAIL_SUB_SURVEY_INVITATIONS_DESCRIPTION =
	'email_sub_survey_invitations_description';
export const EMAIL_SUBS_UPDATED = 'email_subs_updated';
export const EMAIL_SUBS_NOT_UPDATED = 'email_subs_not_updated';
export const PROFILE = 'profile';
export const LANGUAGE = 'language';
export const CHANGE_LANGUAGE_TITLE = 'change_language_title';
export const CHANGE_LANGUAGE_SUBTITLE = 'change_language_subtitle';
export const CHANGE_LANGUAGE_MESSAGE = 'change_language_message';
export const ACCOUNT = 'account';
export const MORE = 'more';
export const INVITE_FRIEND = 'invite_friend_updated';
export const PRIZE_DRAW_DESCRIPTION = 'prize_draw_description';
export const WIN_UP_POINTS = 'win_up_points';
export const GUARANTEED_PRIZE = 'guaranteed_prize';
export const NEED_NEW_PASSWORD = 'need_new_password';
export const UNLINK_SOCIALS = 'unlink_socials';
export const RESET_PASSWORD_SENT_MESSAGE = 'reset_password_sent_message';
export const RESET_PASSWORD_SENT_DETAILS =
	'reset_password_sent_message-details';
export const RESET_PASSWORD_SENT_SPAM = 'reset_password_sent_message-spam';
export const ACHIEVEMENTS = 'achievements';
export const ACHIEVEMENTS_EARNED = 'achievements_earned';
export const UNLOCK_FIRST_ACHIEVEMENT = 'unlock_first_achievement';
export const VALUE_OF_TOTAL = 'value_of_total';
export const CLAIMED = 'claimed';
export const ACHIEVEMENT_UNLOCKED = 'achievement_unlocked';
export const GO_TO_ACHIEVEMENTS_AND_REDEEM_BONUS =
	'go_to_achievements_and_redeem_bonus';
export const GO_TO_ACHIEVEMENTS = 'go_to_achievements';
export const YOUR_REWARD = 'your_reward';
export const THANK_YOU_FOR_OPINION = 'thank_you_for_opinion';
export const TAKE_SURVEYS_EARN_POINTS = 'take_surveys_earn_points';
export const TAKE_SURVEYS = 'take_surveys';
export const SOUNDS_GREAT = 'sounds_great';
export const RATING_DESCRIPTION_WEEKLY = 'rating_description_weekly';
export const LEADERBOARD_WON_TITLE_WEEKLY = 'leaderboard_won_title_weekly';
export const LEADERBOARD_SCORED_MESSAGE_WEEKLY =
	'leaderboard_scored_message_weekly';
export const SELECT_COUNTRY_LANGUAGE_HEADER = 'select_country_language_header';
export const SEARCH_COUNTRY = 'search_country';
export const LOCALIZATION_EMPTY_SEARCH = 'localization_modal_no_items';
export const OTHER_OPTIONS = 'other_options';
export const ENTER_EMAIL_PASSWORD = 'enter_email_password';
export const CONTINUE = 'continue';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgot_password';
export const SIGN_UP = 'sign_up';
export const ACCEPT_POLICY_TERMS = 'accept_policy_terms';
export const SIGN_UP_INFO = 'sign_up_info';
export const EMAIL_VERIFY_WARNING = 'email_verification_warning';
export const SENT = 'sent';
export const CONTINUE_WITH_SOCIAL = 'continue_with_provider';
export const OR = 'or';
export const SOCIAL_ACCOUNT_MESSAGE = 'social_account_message';
export const NOT_YOUR = 'not_your';
export const USE_ANOTHER_ACCOUNT = 'use_another_account';
export const SIGN_UP_SOCIAL: Record<string, string> = {
	google: 'sign_up_google',
	facebook: 'sign_up_facebook',
	apple: 'sign_up_apple',
};
export const ACCOUNT_EXISTS = 'account_exists';
export const LINK_ACCOUNTS_MESSAGE = 'link_account_message';
export const SET_PASSWORD = 'set_password';
export const SET_PASSWORD_MESSAGE = 'set_password_message';
export const EXCHANGE_RATE = 'exchange_rate';
export const WELCOME_BONUS_DESCRIPTION = 'welcome_bonus_description';
export const BLUE = 'blue';
export const BRONZE = 'bronze';
export const SILVER = 'silver';
export const GOLD = 'gold';
export const DIAMOND = 'diamond';
export const LEVELS_DESCRIPTION_TITLE = 'levels_description_title';
export const LEVELS_CURRENT = 'levels_current';
export const LIVE_CHAT = 'live_chat';
export const EARLY_ACCESS = 'early_access';
export const UPCOMING_BENEFITS = 'upcoming_benefits';
export const NEW_LEVELS = 'new_levels';
export const BENEFIT_LIVE_CHAT = 'benefit_live_chat';
export const BENEFIT_EARLY_ACCESS = 'benefit_early_access';
export const TIER_BONUS = 'tier_bonus';
export const HOW_LEVELS_WORK = 'how_levels_work';
export const CLAIM_EMAIL = 'claim_email';
export const PAYPAL_DELAY_MESSAGE = 'paypal_delay_message';
export const PROCESSING = 'processing';
export const NO_CONNECTION_TITLE = 'no_connection_title';
export const NO_CONNECTION_MESSAGE = 'no_connection_message';
export const RELOAD = 'reload';
export const CODE_REQUIRED = 'code_required';
export const CODE_SENT = 'code_sent';
export const CODE_SENT_MESSAGE = 'code_sent_message';
export const CODE_SENT_QUESTION = 'code_sent_question';
export const RESEND_CODE = 'resend_code';
export const NOT_VERIFIED_CODE = 'not_verified_code';
export const OR_CONTINUE_WITH = 'or_continue_with';
export const LOGIN_OR_SIGN_UP = 'login_or_sign_up';
export const REWARD_IS_ON_WAY = 'reward_is_on_way';
export const CONFIRM_PAYPAL_EMAIL = 'confirm_paypal_email';
export const PAYPAL_EMAIL = 'paypal_email';
export const CONFIRM_PAYPAL_MESSAGE = 'confirm_paypal_message';
export const PROCEED = 'proceed';
export const PASSWORD_NOT_EQUAL = 'password_not_equal';
export const REFUNDED = 'refunded';
export const ERROR = 'error';
export const REFUND = 'refund';
export const REDEEM_CODE = 'redeem_code';
export const BONUS_CODE = 'bonus_code';
export const ENTER_CODE = 'enter_code';
export const SUBMIT = 'submit';
export const BONUS_CODE_MESSAGE = 'bonus_code_message';
export const SELECT_LANGUAGE = 'select_language';
export const STREAK_SAVED = 'streak_saved';
export const UPDATE_DIALOG_TITLE = 'update_dialog_title';
export const UPDATE_DIALOG_MESSAGE = 'update_dialog_message';
export const APP_REVIEW = 'app_review';
export const APP_REVIEW_TEXT = 'app_review_text';
export const EMAIL_TO_PREVIOUS = 'email_to_previous';
export const CONFIRM_CLAIM_MODAL_TITLE = 'confirm_claim_modal_title';
export const CONFIRM_CLAIM_MODAL_MESSAGE = 'confirm_claim_modal_message';
export const CONFIRM_VIA_EMAIL = 'confirm_via_email';
export const TRANSLATE_MODAL_HEADER = 'translate_modal_header';
export const TRANSLATE_MODAL_TITLE = 'translate_modal_title';
export const TRANSLATE_MODAL_INFO = 'translate_modal_info';
export const TRANSLATE_MODAL_MESSAGE = 'translate_modal_message';
export const OPEN_ACCOUNT_SETTINGS = 'open_account_settings';
export const NONE = 'none';
export const LEADERBOARD_LOCKED = 'leaderboard_locked';
export const HIGHEST_RANK = 'highest_rank';
export const LEADERBOARD_UNLOCK_TARGET = 'leaderboard_unlock_target';
export const BONUS_DAY_TARGET = 'bonus_day_target';
export const BONUS_DAY_SELECT_DESCRIPTION = 'bonus_day_select_description';
export const SELECT_DAY = 'select_day';
export const SELECT_DAY_INTERVAL = 'bonus_day_select_interval';
export const MONDAY_FULL = 'monday_full';
export const TUESDAY_FULL = 'tuesday_full';
export const WEDNESDAY_FULL = 'wednesday_full';
export const THURSDAY_FULL = 'thursday_full';
export const FRIDAY_FULL = 'friday_full';
export const SATURDAY_FULL = 'saturday_full';
export const BONUS_DAY_UNLOCK_DESCRIPTION = 'bonus_day_unlock_description';
export const REWARD_DISCOUNT = 'reward_discount';
export const REWARD_DISCOUNT_BENEFIT_TITLE = 'reward_discount_benefit_title';
export const REWARD_DISCOUNT_BENEFIT_DESCRIPTION =
	'reward_discount_benefit_description';
export const BONUS_DAY_DESCRIPTION_BENEFIT =
	'bonus_day_benefit_description_fixed';
export const BONUS_DAY = 'bonus_day_dynamic';
export const SAVE_DISCOUNT = 'save_discount';
export const YOU_WON_BONUS = 'you_won_bonus';
export const PRIZE_BONUS_MODAL_TITLE = 'prize_bonus_modal_title';
export const PRIZE_BONUS_MODAL_DESCRIPTION = 'prize_bonus_modal_description';
export const REVOLUT_REWARD_SIGNUP_DESCRIPTION =
	'revolut_reward_signup_description';
export const CLICK_TO_SIGNUP = 'click_to_signup';
export const FULL_NAME = 'full_name';
export const TAG = 'tag';
export const FULL_NAME_REQUIRED = 'full_name_required';
export const TAG_REQUIRED = 'tag_required';
export const FULL_NAME_MIN_LENGTH = 'full_name_min_length';
export const FULL_NAME_MAX_LENGTH = 'full_name_max_length';
export const TAG_MIN_LENGTH = 'tag_min_length';
export const TAG_MAX_LENGTH = 'tag_max_length';
export const REVOLUT_CLAIM_DATA = 'revolut_claim_data';
export const REVOLUT_ANNOUNCEMENT_JOIN = 'revolut_announcement_join';
export const ACH_FULL_NAME_CONFIRM = 'confirm_ach_full_name';
export const ACH_FULL_NAME_CONFIRM_PLACEHOLDER =
	'confirm_ach_full_name_placeholder';
export const CONFIRM_ACH_FULL_NAME_MESSAGE = 'confirm_ach_full_name_message';
export const CONFIRM_FULL_NAME = 'confirm_full_name';
export const CHANGE_NAME = 'change_name';
export const ASK_PERMISSIONS_MODAL_HEADER = 'ask_permissions_modal_header';
export const ASK_PERMISSIONS_MODAL_TITLE = 'ask_permissions_modal_title';
export const ASK_PERMISSIONS_MODAL_MESSAGE = 'ask_permissions_modal_message';
export const ASK_PERMISSIONS_MODAL_IMPORTANT =
	'ask_permissions_modal_important';
export const SELECT_CHOICE = 'select_choice';
export const OFFERWALLS_TITLE = 'offerwalls_title';
export const OFFERWALLS_DESCRIPTION = 'offerwalls_description';
export const OFFERWALLS_DISCLAIMER = 'offerwalls_disclaimer';
export const OFFERWALLS_NEW_EARNING = 'offerwalls_new_earning_fixed';
export const OFFERWALLS_ENABLE_EARNING = 'offerwalls_enable_earning';
export const OFFERWALLS_CHECK_BUTTON = 'offerwalls_check_button';
export const OFFERWALLS_REWARD = 'offerwalls_reward';
export const COMPLETE = 'complete';
export const OFFERWALLS_ARE_HERE = 'offerwalls_are_here';
export const OFFERWALLS_BUTTON_DESCRIPTION = 'offerwalls_button_description';
export const OFFERWALLS_OPEN = 'offerwalls_open';
export const REF_CARD_TITLE = 'ref_card_title';
export const REF_CARD_TEASER = 'ref_card_teaser';
export const REF_CARD_BUTTON = 'ref_card_button';
export const REF_HIW_STEP_1 = 'ref_hiw_step_1_code';
export const REF_HIW_STEP_2 = 'ref_hiw_step_2_code';
export const REF_HIW_STEP_3 = 'ref_hiw_step_3_code';
export const REFERRAL_LINK = 'referral_link';
export const TOTAL_CLAIMS = 'total_claims';
export const SORRY_MODAL_HEADER = 'sorry_modal_header';
export const SORRY_MODAL_TITLE = 'sorry_modal_title';
export const SORRY_MODAL_DESCRIPTION = 'sorry_modal_description';
export const SORRY_MODAL_ACTION = 'sorry_modal_action_2';
export const ALL = 'all';
export const POPULAR = 'popular';
export const FOOD = 'food';
export const GAMING = 'gaming';
export const SHOPPING = 'shopping';
export const YOUR_REFERRAL_LINK = 'your_referral_link';
export const COPY = 'copy';
export const COPY_CODE = 'copy_code';
export const USE_REFERRAL_INSTEAD = 'use_referral_instead';
export const COPIED_INTO_CLIPBOARD = 'copied_into_clipboard';
export const UNLOCK = 'unlock';
export const UNLOCK_OFFERWALLS_TARGET = 'unlock_offerwalls_target';
export const UNLOCK_OFFERWALLS_PROGRESS = 'unlock_offerwalls_progress';
export const RECEIVED_POINTS = 'received_points';
export const CASHED_OUT_POINTS = 'cashed_out_points';
export const PRESET_WELCOME_BONUS_DESCRIPTION =
	'preset_welcome_bonus_description';
export const NEW_VERSION_AVAILABLE_HEADER = 'new_version_available_header';
export const NEW_VERSION_AVAILABLE_TITLE = 'new_version_available_title';
export const NEW_VERSION_AVAILABLE_MSG = 'new_version_available_msg_store';
export const NEW_VERSION_AVAILABLE_BTN = 'new_version_available_btn';
export const NEW_VERSION_AVAILABLE_SUCCESS = 'new_version_available_success';
export const NEW_VERSION_AVAILABLE_TITLE_DOWNLOADING =
	'new_version_available_title_downloading';
export const NEW_VERSION_AVAILABLE_MSG_DOWNLOADING =
	'new_version_available_msg_downloading';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLING =
	'new_version_available_title_installing';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLING =
	'new_version_available_msg_installing';
export const NEW_VERSION_AVAILABLE_TITLE_INSTALLED =
	'new_version_available_title_installed';
export const NEW_VERSION_AVAILABLE_MSG_INSTALLED =
	'new_version_available_msg_installed';
export const NEW_VERSION_AVAILABLE_BTN_INSTALLED =
	'new_version_available_btn_installed';
export const NEW_VERSION_AVAILABLE_TITLE_FAILED =
	'new_version_available_title_failed';
export const NEW_VERSION_AVAILABLE_MSG_FAILED =
	'new_version_available_msg_failed';
export const NEW_VERSION_AVAILABLE_BTN_FAILED =
	'new_version_available_btn_failed';
export const LOGIN_PAGE_TITLE = 'login_page_title';
export const MILLION = 'million';
export const EARNED_BY_MEMBERS = 'earned_by_members';
export const NEW = 'new';
export const REFER_LINK_TITLE = 'refer_link_title';
export const REFER_LINK_TITLE_SUBTITLE = 'refer_link_title_subtitle';
export const REDEEM_LINK_TITLE = 'redeem_link_title';
export const COMPLETE_SURVEY_INFO_TEXT = 'complete_survay_info_text';
export const PARTNERS_NOT_FOUND = 'partners_not_found';
export const WELL_DONE_V2 = 'well_done_v2';
export const OFFERWALLS_WELL_DONE_MODAL_TITLE =
	'offerwalls_well_done_modal_title';
export const OFFERWALLS_WELL_DONE_MODAL_TEASER =
	'offerwalls_well_done_modal_teaser';
export const PENDING_POINTS = 'pending_points';
export const PENDING_UNTIL = 'pending_until';
export const TOTAL_PENDING_POINTS = 'total_pending_points';
export const PRESET_WELCOME_BONUS_TITLE = 'preset_welcome_bonus_title';
export const CASHBACK_ANNOUNCEMENT_HEADER = 'cashback_announcement_header';
export const CASHBACK_ANNOUNCEMENT_TITLE = 'cashback_announcement_title';
export const CASHBACK_ANNOUNCEMENT_DESCRIPTION =
	'cashback_announcement_description';
export const CASHBACK_ANNOUNCEMENT_END_DATE = 'cashback_announcement_end_date';
export const CASHBACK_NOTIFICATION_HEADER = 'cashback_notification_header';
export const CASHBACK_NOTIFICATION_TITLE = 'cashback_notification_title';
export const CASHBACK_NOTIFICATION_DESCRIPTION =
	'cashback_notification_description';
export const REWARDS_CASHBACK_SUBTITLE = 'rewards_cashback_subtitle';
export const CASHBACK_TRANSACTION = 'cashback_transaction';
export const CASHBACK_DEDUCTED_TRANSACTION = 'cashback_deducted_transaction';
export const ANNOUCEMENT_V2_HEAD = 'annoucement_v2_head';
export const ANNOUCEMENT_V2_TITLE = 'annoucement_v2_title';
export const ANNOUCEMENT_V2_SUBTITLE = 'annoucement_v2_subtitle';
export const ANNOUCEMENT_V2_BTN = 'annoucement_v2_btn';
export const ANNOUCEMENT_V2_LIST_1_TITLE = 'annoucement_v2_list_1_title';
export const ANNOUCEMENT_V2_LIST_1_TEASER = 'annoucement_v2_list_1_teaser';
export const ANNOUCEMENT_V2_LIST_2_TITLE = 'annoucement_v2_list_2_title';
export const ANNOUCEMENT_V2_LIST_2_TEASER = 'annoucement_v2_list_2_teaser';
export const ANNOUCEMENT_V2_LIST_3_TITLE = 'annoucement_v2_list_3_title';
export const ANNOUCEMENT_V2_LIST_3_TEASER = 'annoucement_v2_list_3_teaser';
export const ANNOUCEMENT_V2_LIST_4_TITLE = 'annoucement_v2_list_4_title';
export const ANNOUCEMENT_V2_LIST_4_TEASER = 'annoucement_v2_list_4_teaser';
export const USERNAME_UPDATE_SUCCESS_MESSAGE =
	'username_update_success_message';
export const TIMEZONE_UPDATE_SUCCESS_MESSAGE =
	'timezone_update_success_message';
export const NO_RESULTS = 'no_results';
export const HISTORY_PAGE_TITLE = 'history_page_title';
export const LEADERBOARD_ENDS_IN = 'leaderboard_ends_in';
export const LEADERBOARD_START_IN = 'leaderboard_start_in_3';
export const YOUR_RANK = 'your_rank';
export const EARN_POINTS_TO_WIN_MORE = 'earn_points_to_win_more';
export const SELECT_THEME = 'select_theme';
export const THEME_NOTE = 'theme_note';
export const LIGHT = 'light';
export const DARK = 'dark';
export const SYSTEM = 'system';
