<script setup lang="ts">
import { computed, ref, onMounted } from 'vue';
import {
	BONUS_INFORMATION_MESSAGE_2,
	LEADERBOARD_ENDED,
	LEADERBOARD_START_IN,
	LEADERBOARD_ENDS_IN,
} from '@/locales/constants';
import {
	localizePoints,
	transformCurrency,
	getCountdown,
} from '@/utils/helpers';
import { getCurrentLocale } from '@/i18n';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import type { LeaderboardInfo } from '@/types';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
	info: LeaderboardInfo | undefined;
	nextStart?: string;
	nextName?: string;
	isActive?: boolean;
}>();

const { currency, leaderboard, username } = storeToRefs(useUserStore());
const { isLaptop, isMobileOnly } = useScreenSize();
const { t } = useI18n();

const dateLocale = ref<any>();

const showEndsIn = computed(
	() => props.isActive && getCountdown(props.info?.end || '', dateLocale.value)
);
const showStartIn = computed(() =>
	Boolean(
		!props.isActive &&
			props.nextStart &&
			props.nextName &&
			getCountdown(props.nextStart, dateLocale.value)
	)
);
const title = computed(() => {
	if (props.info?.prize_pool_header) {
		return props.info.prize_pool_header;
	} else if (showStartIn.value) {
		return t(LEADERBOARD_START_IN);
	}
	return '';
});
const date = computed(() => {
	if (showEndsIn.value) {
		return getCountdown(props.info?.end || '', dateLocale.value);
	} else if (showStartIn.value) {
		return getCountdown(props.nextStart, dateLocale.value);
	}
	return '';
});
const showEndedLeaderboardTitle = computed(
	() => username.value && leaderboard.value.unlocked && !props.isActive
);
const showLeftSide = computed(
	() => title.value || showEndsIn.value || date.value
);

onMounted(() => {
	import('date-fns/locale').then((module) => {
		dateLocale.value = module[getCurrentLocale()];
	});
});
</script>

<template>
	<div class="prize-pool">
		<div v-if="showLeftSide" class="prize-pool-left">
			<PrimeText v-if="title" tag="p" size="base" weight="600">
				{{ title }}:
			</PrimeText>
			<div>
				<PrimeText
					v-if="showEndsIn"
					weight="500"
					color="grey-700"
					:size="isMobileOnly ? 'xs' : 'sm'"
				>
					{{ $t(LEADERBOARD_ENDS_IN) }}:
				</PrimeText>
				<PrimeText v-if="date" weight="700" :size="isMobileOnly ? 'xs' : 'sm'">
					{{ date }}
				</PrimeText>
			</div>
		</div>
		<div class="prize-pool-right">
			<PrimeText
				v-if="showEndedLeaderboardTitle"
				tag="div"
				color="secondary"
				weight="600"
				class="leaderboard--ended"
				:size="isMobileOnly ? 'sm' : 'lg'"
				:align="isLaptop ? 'center' : 'left'"
			>
				{{ $t(LEADERBOARD_ENDED) }}
			</PrimeText>
			<PrimeText v-else tag="div" size="base" weight="600" color="primary">
				{{
					$t(BONUS_INFORMATION_MESSAGE_2, {
						coins: localizePoints(info?.prize_pool_coins || 0),
						money: transformCurrency(
							info?.prize_pool_in_user_currency || 0,
							currency || '',
							2
						),
					})
				}}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prize-pool {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 0.5rem;

	@include breakpoint(mobile) {
		flex-direction: row;
		justify-content: space-between;
		text-align: left;
	}
}

.prize-pool-right {
	flex: none;
}
</style>
