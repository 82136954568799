<script setup lang="ts">
import {
	CODE_SENT,
	CODE_SENT_MESSAGE,
	CODE_SENT_QUESTION,
	CONFIRM_EMAIL,
	CONFIRM_EMAIL_MESSAGE,
	LOGIN,
	RESEND,
	RESEND_CODE,
} from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import { useSignupCompleteView } from '@/views/signup-complete/composables';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import {
	PrimeButton,
	PrimeText,
	PrimeCooldownButton,
	PrimePinInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const COOL_DOWN_SECONDS = 15;

const {
	isMobileApp,
	isLoading,
	resendVerificationEmail,
	verifyCode,
	code,
	codeError,
	handleUpdateCode,
	cooldownButtonEl,
} = useSignupCompleteView();
</script>

<template>
	<AuthPageContent :title="$t(CONFIRM_EMAIL)" back-route="signup">
		<template v-if="!isMobileApp" #default>
			<PiEnvelopeDuotoneThin
				class="confirm-email-icon"
				color="base-primary"
				size="100%"
			/>
			<PrimeText tag="p" weight="500" class="confirm-email-text">
				{{ $t(CONFIRM_EMAIL_MESSAGE) }}
			</PrimeText>
			<PrimeCooldownButton
				ref="cooldownButtonEl"
				data-test-id="resend-button"
				:text="$t(RESEND)"
				:timer="COOL_DOWN_SECONDS"
				@click="resendVerificationEmail"
			/>
			<PrimeLoader v-if="isLoading" />
		</template>

		<template v-else #default>
			<div class="confirm-code-wrapper">
				<PrimeText tag="p" size="sm" weight="600" class="confirm-code-title">
					{{ $t(CODE_SENT) }}
				</PrimeText>
				<PrimeText tag="p" size="sm" class="confirm-code-message">
					{{ $t(CODE_SENT_MESSAGE) }}
				</PrimeText>

				<div class="confirm-code-resend">
					<PrimeText size="sm" weight="500" class="resend-code-message">
						{{ $t(CODE_SENT_QUESTION) }}
					</PrimeText>
					<PrimeCooldownButton
						ref="cooldownButtonEl"
						:text="$t(RESEND_CODE)"
						:timer="COOL_DOWN_SECONDS"
						@click="resendVerificationEmail"
					/>
				</div>
			</div>
			<form class="verify-code-form" @submit.prevent="verifyCode">
				<PrimePinInput
					:model-value="code"
					:error="codeError"
					class="pin-input"
					@update:model-value="handleUpdateCode"
				/>

				<PrimeButton
					role="submit"
					full-width
					class="submit-register-button"
					:disabled="!!codeError || code.length < 4"
					:label="$t(LOGIN)"
				/>
			</form>
		</template>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.confirm-email-container {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	width: 100%;
	max-width: 26.875rem;
	margin: 0 auto;
	text-align: center;
}

.confirm-email-text {
	margin: 1.5rem 0;
	:deep(.user-email) {
		word-break: break-word;
	}
}

.confirm-email-icon {
	margin: 0 auto;
	width: 3.375rem;
	height: 3.375rem;

	@include breakpoint(mobile) {
		width: 5.875rem;
		height: 5.875rem;
	}
}

.confirm-code-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	& .confirm-code-title {
		margin-top: 0.875rem;
	}

	& .confirm-code-message {
		margin-bottom: 1.5rem;
		text-align: start;
	}
}

.confirm-code-resend {
	& .resend-code-message {
		display: inline-block;
		margin-right: 0.375rem;
	}
}

.verify-code-form {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
}

.pin-input {
	flex: 1 0 auto;
	margin-top: 0.875rem;
}
</style>
