<script setup lang="ts">
import TransactionsView from '@/views/history/views/transactions/TransactionsView.vue';
import RewardsView from '@/views/history/views/rewards/RewardsView.vue';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { REWARDS, TRANSACTIONS, HISTORY_PAGE_TITLE } from '@/locales/constants';
import { useHistoryView } from '@/views/history/composables';
import LayoutSubpage from '@/components/LayoutSubpage.vue';

const { activeTabIndex, onTabChange } = useHistoryView();
</script>

<template>
	<LayoutSubpage back-button-visible :title="$t(HISTORY_PAGE_TITLE)">
		<main class="history">
			<TabView
				v-model:activeIndex="activeTabIndex"
				lazy
				@tab-change="onTabChange"
			>
				<TabPanel :header="$t(REWARDS)">
					<RewardsView />
				</TabPanel>
				<TabPanel :header="$t(TRANSACTIONS)">
					<TransactionsView />
				</TabPanel>
			</TabView>
		</main>
	</LayoutSubpage>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.history {
	width: 100%;
	// max-width: 43.75rem;
	// margin: 0.375rem auto 0;

	// @include breakpoint(mobile) {
	// 	margin: 2.25rem auto 0;
	// }
}
</style>
