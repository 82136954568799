import type { IconColors } from '@/types';
import {
	BONUS,
	LEADERBOARD,
	LEVEL_UP,
	OTHER_TRANSACTION,
	PRIZE_DRAW,
	REFERRAL,
	REFUND,
	REWARD,
	SCREENOUT,
	STREAK_SAVED,
	COMPLETE,
	OFFERWALLS_TITLE,
	CASHBACK_TRANSACTION,
	CASHBACK_DEDUCTED_TRANSACTION,
} from '@/locales/constants';
import type { Component } from 'vue';
import {
	PiArrowRepeatDuotone,
	PiCheckCircleDuotone,
	PiCoinsDuotone,
	PiCrownDuotone,
	PiCurrencyCircleDollarDuotone,
	PiFireDuotone,
	PiGiftDuotone,
	PiHandCoinsDuotone,
	PiRepeatDuotone,
	PiRocketLaunchDuotone,
	PiTrophyDuotone,
	PiUsersDuotone,
	PiWarningWaveDuotone,
} from '@primeinsightsgroupllc/prime-icons';

type TransactionContent = {
	term: string;
	icon: Component;
	color: IconColors;
};

enum Transactions {
	COMPLETE,
	SCREENOUT,
	CLAIM,
	BONUS,
	PRIZE_DRAW,
	LEVEL_UP,
	REFERRAL,
	LEADERBOARD,
	REFUND,
	OTHER_TRANSACTION,
	STREAK_SAVED,
	OFFERWALLS,
	CASHBACK,
	DEDUCTED_CASHBACK,
}

const transactionsContent: Record<number, TransactionContent> = {
	[Transactions.COMPLETE]: {
		term: COMPLETE,
		icon: PiCheckCircleDuotone,
		color: 'states-success',
	},
	[Transactions.SCREENOUT]: {
		term: SCREENOUT,
		icon: PiRepeatDuotone,
		color: 'text-default',
	},
	[Transactions.CLAIM]: {
		term: REWARD,
		icon: PiCoinsDuotone,
		color: 'text-default',
	},
	[Transactions.BONUS]: {
		term: BONUS,
		icon: PiRocketLaunchDuotone,
		color: 'text-default',
	},
	[Transactions.PRIZE_DRAW]: {
		term: PRIZE_DRAW,
		icon: PiGiftDuotone,
		color: 'text-default',
	},
	[Transactions.LEVEL_UP]: {
		term: LEVEL_UP,
		icon: PiCrownDuotone,
		color: 'text-default',
	},
	[Transactions.REFERRAL]: {
		term: REFERRAL,
		icon: PiUsersDuotone,
		color: 'text-default',
	},
	[Transactions.LEADERBOARD]: {
		term: LEADERBOARD,
		icon: PiTrophyDuotone,
		color: 'text-default',
	},
	[Transactions.REFUND]: {
		term: REFUND,
		icon: PiHandCoinsDuotone,
		color: 'text-default',
	},
	[Transactions.OTHER_TRANSACTION]: {
		term: OTHER_TRANSACTION,
		icon: PiCurrencyCircleDollarDuotone,
		color: 'text-default',
	},
	[Transactions.STREAK_SAVED]: {
		term: STREAK_SAVED,
		icon: PiFireDuotone,
		color: 'text-default',
	},
	[Transactions.OFFERWALLS]: {
		term: OFFERWALLS_TITLE,
		icon: PiCoinsDuotone,
		color: 'text-default',
	},
	[Transactions.CASHBACK]: {
		term: CASHBACK_TRANSACTION,
		icon: PiArrowRepeatDuotone,
		color: 'text-default',
	},
	[Transactions.DEDUCTED_CASHBACK]: {
		term: CASHBACK_DEDUCTED_TRANSACTION,
		icon: PiWarningWaveDuotone,
		color: 'text-default',
	},
};

export const transactionContent: Record<number, TransactionContent> = {
	1: transactionsContent[Transactions.COMPLETE], // Survey complete
	2: transactionsContent[Transactions.SCREENOUT], // Survey screen out
	6: transactionsContent[Transactions.BONUS], // Plugin Install bonus
	100: transactionsContent[Transactions.CLAIM], // Claim reward
	105: transactionsContent[Transactions.OTHER_TRANSACTION], // Claim invert
	110: transactionsContent[Transactions.REFUND], // Claim refund auto
	115: transactionsContent[Transactions.REFUND], // Claim refund manual
	200: transactionsContent[Transactions.BONUS], // Bonus
	210: transactionsContent[Transactions.PRIZE_DRAW], // Bonus prize draw
	220: transactionsContent[Transactions.LEVEL_UP], // Bonus level up
	225: transactionsContent[Transactions.BONUS], // Bonus refund
	230: transactionsContent[Transactions.REFERRAL], // Bonus referral
	235: transactionsContent[Transactions.OTHER_TRANSACTION], //Referral invert
	240: transactionsContent[Transactions.LEADERBOARD], // Bonus leaderboard
	245: transactionsContent[Transactions.BONUS], // Bonus one click survey
	250: transactionsContent[Transactions.BONUS], //Bonus achievement
	260: transactionsContent[Transactions.BONUS], // Bonus onetime
	265: transactionsContent[Transactions.REFERRAL], // Update referrals bonus (percents)
	270: transactionsContent[Transactions.CASHBACK], // User receives cashback
	275: transactionsContent[Transactions.DEDUCTED_CASHBACK], // Cashback deducted from user balance
	300: transactionsContent[Transactions.OTHER_TRANSACTION], // Manual transaction
	400: transactionsContent[Transactions.STREAK_SAVED], // Streak saved
	600: transactionsContent[Transactions.OFFERWALLS], // Bonus offerwall completed
};

export const getTransactionContent = (type: number): TransactionContent => {
	return (
		transactionContent[type] ||
		transactionsContent[Transactions.OTHER_TRANSACTION]
	);
};
