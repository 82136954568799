import { useAchievementsStore } from '@/stores/achievements';
import { type ComputedRef, onBeforeMount, type Ref, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { AchievementGroup } from '@/types';

export const useAchievementsView = (): {
	achievements: ComputedRef<AchievementGroup[]>;
	initLoading: Ref<boolean>;
	loading: Ref<boolean>;
	claimPrize: (id: string) => Promise<void>;
} => {
	const achievementsStore = useAchievementsStore();
	const initLoading = ref<boolean>(false);
	const loading = ref<boolean>(false);

	const { achievements, achievementsMessage } = storeToRefs(achievementsStore);

	const claimPrize = async (achievementId: string): Promise<void> => {
		loading.value = true;
		await achievementsStore.claimAchievementPrize(achievementId);
		notify({ body: achievementsMessage.value });
		loading.value = false;
	};

	onBeforeMount(async () => {
		initLoading.value = true;
		await achievementsStore.fetchAchievements();
		initLoading.value = false;
	});

	return {
		achievements,
		initLoading,
		loading,
		claimPrize,
	};
};
