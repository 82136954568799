<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useBonusStore } from '@/stores/bonus';
import {
	BONUS_INFORMATION_MESSAGE,
	EXCHANGE_RATE,
	GET_WELCOME_BONUS,
	RECEIVED_POINTS,
	CASHED_OUT_POINTS,
	PRESET_WELCOME_BONUS_DESCRIPTION,
	PRESET_WELCOME_BONUS_TITLE,
} from '@/locales/constants';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { useAppStore } from '@/stores/app';
import { TrackingEvents } from '@/enums';
import { PiCoinsDuotone } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import rocket from '@/assets/icons/rocket.svg';

const bonusStore = useBonusStore();
const { presetBonus } = storeToRefs(bonusStore);
const appStore = useAppStore();

const isDisable = ref(false);

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const setWelcomeBonus = async () => {
	if (presetBonus.value) {
		isDisable.value = true;
		const { message } = await bonusStore.selectBonus(
			presetBonus.value.coin_value
		);

		await appStore.trackEvent(TrackingEvents.WELCOME_BONUS_SELECTED, {
			selected_bonus: presetBonus.value.coin_value.toString(),
		});

		notify({
			body: message,
		});
		emit('close-modal');
	}
};
onBeforeMount(async () => {
	if (!presetBonus.value) emit('close-modal');
});
</script>

<template>
	<div class="modal-content-container">
		<div class="modal-header">
			<PrimeText tag="h2" weight="600" align="center">
				{{ $t(PRESET_WELCOME_BONUS_TITLE) }}
			</PrimeText>
		</div>
		<div class="icon">
			<img :src="rocket" alt="rocket" />
		</div>
		<PrimeText tag="h3" weight="700" size="lg" color="primary" align="center">
			{{ $t(RECEIVED_POINTS, { points: 100 }) }}
		</PrimeText>
		<PrimeText
			tag="p"
			weight="500"
			size="sm"
			color="grey-700"
			align="center"
			class="sub-title"
		>
			{{ $t(CASHED_OUT_POINTS, { points: 500 }) }}
		</PrimeText>
		<div class="modal-info">
			<div class="exchange-rate">
				<PiCoinsDuotone color="base-primary" size="2.25rem" />
				<div>
					<PrimeText color="primary" tag="p" size="sm" weight="500">
						{{ $t(EXCHANGE_RATE) }}
					</PrimeText>
					<PrimeText v-if="presetBonus" color="primary" weight="700" tag="p">
						{{
							$t(BONUS_INFORMATION_MESSAGE, {
								coins: localizePoints(presetBonus.coin_value),
								money: transformCurrency(
									+presetBonus.money_value,
									presetBonus.currency,
									2
								),
							})
						}}
					</PrimeText>
				</div>
			</div>
		</div>
		<PrimeText color="grey-700" weight="500" tag="p" size="sm" align="center">
			{{ $t(PRESET_WELCOME_BONUS_DESCRIPTION, { giftCount: 100 }) }}
		</PrimeText>
		<PrimeButton
			type="gradient"
			full-width
			:label="$t(GET_WELCOME_BONUS)"
			:disabled="isDisable"
			class="submit-button"
			rounded="rounded"
			@click="setWelcomeBonus"
		/>
	</div>
</template>

<style lang="scss" scoped>
.modal-content-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.modal-header {
	width: calc(100% + 3rem);
	padding: 0 1rem 1rem;
	border-bottom: 1px solid var(--grey-200);
}
.icon {
	margin: 1.375rem 0 0.25rem;
}
.sub-title {
	margin-top: 0.25rem;
}
.modal-info {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.625rem;
	border-radius: 0.74rem;
	background: var(--base-primary-09);
	margin: 1.375rem 0;
}
.exchange-rate {
	display: flex;
	align-items: center;
	gap: 1.5rem;
}
.submit-button {
	margin-top: 1.375rem;
}
</style>
