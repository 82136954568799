<script lang="ts" setup>
import { useRewardsStore } from '@/stores/rewards';

const isLoading = defineModel<boolean>('isLoading');
const rewardsStore = useRewardsStore();

const handleConfirmClaim = async () => {
	try {
		isLoading.value = true;
		await rewardsStore.claim();
	} finally {
		isLoading.value = false;
	}
};
defineExpose({
	handleConfirmClaim,
});
</script>

<template>
	<div></div>
</template>
