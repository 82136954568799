<script setup lang="ts">
import { CONTINUE, EMAIL, SIGN_UP, SIGN_UP_SOCIAL } from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import AcceptPolicy from '@/components/auth/AcceptPolicy.vue';
import { useSignupSocialView } from '@/views/signup-social/composables';
import {
	PrimeButton,
	PrimeInput,
	PrimeText,
	PrimeSocialNetworkIcon,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleSubmitForm,
	userSocialAuthData,
	form,
	isLoading,
	getValidationFieldStatus,
	getValidationFieldText,
} = useSignupSocialView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_UP)">
		<form class="social-register-form" @submit.prevent="handleSubmitForm">
			<div v-if="userSocialAuthData" class="form-title">
				<PrimeSocialNetworkIcon
					:name="userSocialAuthData?.social_auth_provider"
				/>
				<PrimeText size="sm" weight="600">
					{{ $t(SIGN_UP_SOCIAL[userSocialAuthData?.social_auth_provider]) }}
				</PrimeText>
			</div>
			<PrimeInput
				v-model="form.email"
				:disabled="!!userSocialAuthData?.email"
				:placeholder="$t(EMAIL)"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
				autocomplete="username"
				type="email"
			/>

			<AcceptPolicy ref="acceptPolicyEl" />

			<PrimeButton
				role="submit"
				class="submit-register-button"
				full-width
				:label="$t(CONTINUE)"
			/>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.social-register-form {
	display: flex;
	flex-direction: column;

	& .form-title {
		display: flex;
		gap: 0.375rem;
		align-items: center;
		margin-bottom: 0.75rem;
	}

	& .submit-register-button {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
</style>
