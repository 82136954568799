import { computed, onMounted, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import type { BonusDay } from '@/types';
import { getCurrentLocale } from '@/i18n';
import { fetchBonusDays, selectBonusDay } from '@/api';
import { format } from 'date-fns';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';

export const useBonusDayView = (): {
	isLoading: Ref<boolean>;
	bonusDays: Ref<BonusDay[]>;
	selectedDay: Ref<string | number>;
	isDayChanged: Ref<boolean>;
	isSaveLoading: Ref<boolean>;
	intervalDate: ComputedRef<string | null>;
	handleClick: () => void;
	handleSelectedBonusDay: (day: number) => Promise<void>;
} => {
	const bonusDays = ref<BonusDay[]>([]);
	const selectedDay = ref<number | string>('');
	const isDayChanged = ref(false);
	const dateLocale = ref<any>();
	const isLoading = ref(true);
	const isSaveLoading = ref(false);

	const intervalDate = computed(() => {
		if (selectedDay.value) {
			const bonusDay = bonusDays.value.find(
				(day) => day.id === selectedDay.value
			);

			return bonusDay && bonusDay.next_bonus_on
				? format(new Date(bonusDay.next_bonus_on), 'EEEE, do MMMM', {
						locale: dateLocale.value,
					})
				: null;
		} else {
			return null;
		}
	});

	watch(bonusDays, (value) => {
		const bonusDay = value.find((day) => day.is_selected);
		if (bonusDay) {
			selectedDay.value = bonusDay.id;
		}
		isDayChanged.value = false;
	});

	onMounted(() => {
		import('date-fns/locale').then((module) => {
			dateLocale.value = module[getCurrentLocale()];
		});
	});

	onMounted(async () => {
		bonusDays.value = await fetchBonusDays();
		isLoading.value = false;
	});

	const handleSelectedBonusDay = async () => {
		try {
			isSaveLoading.value = true;
			const { status, message } = await selectBonusDay(+selectedDay.value);

			if (status === 200) {
				notify({
					body: message,
				});
				bonusDays.value = await fetchBonusDays();
			}
		} finally {
			isDayChanged.value = false;
			isSaveLoading.value = false;
		}
	};

	const handleClick = () => {
		if (!isDayChanged.value) isDayChanged.value = true;
	};

	return {
		isLoading,
		bonusDays,
		selectedDay,
		handleClick,
		isDayChanged,
		intervalDate,
		isSaveLoading,
		handleSelectedBonusDay,
	};
};
