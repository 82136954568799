<script setup lang="ts">
import { ref } from 'vue';
import { CLAIM_REWARD } from '@/locales/constants';
import { useConfirmClaimView } from '@/views/confirm-claim/composables';
import {
	PrimeText,
	PrimeDivider,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ConfirmClaimTop from '@/views/confirm-claim/components/ConfirmClaimTop.vue';
import RewardDetails from '@/views/confirm-claim/components/RewardDetails.vue';
import LayoutSubpage from '@/components/LayoutSubpage.vue';

const isLoading = ref(false);
const {
	isClaimAvailable,
	restOfTheBalanceText,
	component,
	hasSomeInput,
	isButtonDisabled,
	isButtonDisable,
	confirmClaimData,
	hasEnoughPoints,
	confirmClaimProcess,
} = useConfirmClaimView();
</script>

<template>
	<LayoutSubpage :title="$t(CLAIM_REWARD)">
		<section class="container">
			<div class="wrapper">
				<ConfirmClaimTop :is-claim-available="isClaimAvailable" />

				<div class="white-box">
					<RewardDetails />
					<PrimeText
						v-if="isClaimAvailable && hasEnoughPoints"
						weight="500"
						tag="p"
						size="xs"
						class="text"
						color="grey-600"
					>
						{{ restOfTheBalanceText }}
					</PrimeText>
					<PrimeDivider v-if="hasSomeInput" class="divider" />
					<div class="claim-data-wrapper">
						<component
							:is="component"
							ref="confirmClaimData"
							v-model:confirm-disabled="isButtonDisabled"
							v-model:is-loading="isLoading"
						/>
					</div>
				</div>
				<PrimeButton
					full-width
					type="gradient"
					rounded="rounded"
					:disabled="isButtonDisable || isLoading"
					:label="$t(CLAIM_REWARD)"
					@click="confirmClaimProcess"
				/>
			</div>
		</section>
	</LayoutSubpage>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.container {
	width: 31.25rem;
	max-width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.wrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
}

.text {
	text-align: center;
	margin: 1.563rem auto 0;
}

.divider.p-divider {
	margin: 0.75rem 0;
}
.white-box {
	padding: 1.5rem;
	background: var(--white);
	margin: 1.125rem 0;
	border-radius: 1.5rem;
	width: 100%;
	box-shadow: var(--box-shadow);
}

.claim-data-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
}
</style>
