<script lang="ts" setup>
import {
	SELECT_PRIZE_BOX,
	YOU_WON_BONUS,
	YOU_WON_COINS,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { PiTrophy } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import type { StreakPrizes as PrizeType } from '@/enums';
import { StreakPrizes } from '@/enums';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { type, prize } = defineProps<{
	showWinMessage: boolean;
	prize: number;
	type: PrizeType;
}>();

const { isMobile } = useScreenSize();
const { t } = useI18n();

const headerText = computed(() => {
	const text = type === StreakPrizes.COINS ? YOU_WON_COINS : YOU_WON_BONUS;
	const amount = type === StreakPrizes.COINS ? localizePoints(prize) : prize;

	return t(text, { amount });
});
</script>

<template>
	<div v-if="showWinMessage" class="prize-draw--win-message">
		<PiTrophy size="1.75rem" color="base-secondary" />
		<PrimeText
			color="secondary"
			weight="600"
			:size="isMobile ? 'sm' : 'lg'"
			v-html="headerText"
		/>
	</div>
	<template v-else>
		<PrimeText tag="p" color="grey-600" size="sm" align="center">
			{{ $t(SELECT_PRIZE_BOX) }}
		</PrimeText>
	</template>
</template>

<style lang="scss" scoped>
.prize-draw--win-message {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	padding: 0.25rem 0;
	border-radius: 3.125rem;
	background-color: var(--base-secondary-bg);
}
</style>
