import { useComponentName } from '@/utils/composables/useComponentName';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useI18n } from 'vue-i18n';
import type { ComputedRef, Ref } from 'vue';
import { onBeforeUnmount, ref, watch } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { sendErrorInfo } from '@/utils/errorCatching';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { CODE_REQUIRED } from '@/locales/constants';

type LoginForm = {
	password: string;
};

export const useSigninView = (): {
	form: Ref<LoginForm>;
	authUserEmail: ComputedRef<string>;
	isLoading: Ref<boolean>;
	isHidePassword: Ref<boolean>;
	isLinkExistingToSocial: Ref<boolean>;
	isEmailNotVerified: ComputedRef<boolean>;
	isMobileApp: boolean;
	handleSubmitForm: () => Promise<void>;
	handleForgotPassword: () => Promise<void>;
	getValidationFieldText: (field: string) => string;
	getValidationFieldStatus: (field: string) => string;
	sendOneTimeLink: () => Promise<void>;
	validationSubMessage: Ref<string>;
	code: Ref<string>;
	codeError: Ref<string>;
	handleUpdateCode: (updatedCode: string) => void;
} => {
	const componentName = useComponentName();
	const authStore = useAuthStore();
	const {
		authUserEmail,
		authErrors,
		isResetPasswordSent,
		isEmailNotVerified,
		isLinkExistingToSocial,
		codeErrorContent,
	} = storeToRefs(authStore);
	const router = useRouter();
	const { isMobileApp } = useMobileApp();
	const { t } = useI18n();

	const code = ref('');
	const codeError = ref('');
	const form = ref<LoginForm>({
		password: '',
	});
	const isLoading = ref(false);
	const isHidePassword = ref(true);
	const serverValidationResults = ref();
	const validationSubMessage = ref('');

	const { password } = useValidationRules();

	const validation = useVuelidate({ password }, form, {
		$autoDirty: false,
		$externalResults: serverValidationResults,
	});

	const {
		getValidationFieldText,
		getValidationFieldStatus,
		clear,
		validate,
		isValidationError,
		reset,
	} = useValidationUtils<LoginForm>(validation);

	const submitLogin = async () => {
		clear();
		await validate();
		if (isValidationError.value) return;
		try {
			isLoading.value = true;
			if (isLinkExistingToSocial.value) {
				await authStore.loginToExisting(form.value.password);
			} else {
				await authStore.login({
					email: authUserEmail.value,
					password: form.value.password,
				});
			}
		} catch (error) {
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	const handleSubmitForm = async (): Promise<void> => {
		if (code.value.length === 4) {
			await authStore.verifyCode(code.value);
		} else {
			await submitLogin();
		}
	};

	const handleForgotPassword = async (): Promise<void> => {
		try {
			isLoading.value = true;
			await authStore.forgotPassword(authUserEmail.value);
			if (isResetPasswordSent.value) {
				await router.push({
					name: 'forgot-password',
				});
			}
		} catch (error) {
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	const sendOneTimeLink = async () => {
		const response = await authStore.requestOneTimeLink();
		if (response?.status === 200) {
			notify({
				body: response.data.message,
			});
		}
	};

	watch(authErrors, (newErrors) => {
		serverValidationResults.value = { password: newErrors?.message };
		validationSubMessage.value = newErrors?.sub_message || '';
	});

	watch(codeErrorContent, (newError) => {
		codeError.value = newError?.message || '';
	});

	onBeforeUnmount(() => {
		form.value.password = '';
		reset();
	});

	const handleUpdateCode = (updatedCode: string) => {
		code.value = updatedCode;
	};

	watch(code, (newValue, oldValue) => {
		codeError.value =
			oldValue.length && !newValue.length ? t(CODE_REQUIRED) : '';
	});

	return {
		form,
		authUserEmail,
		isLoading,
		isHidePassword,
		isLinkExistingToSocial,
		isEmailNotVerified,
		isMobileApp,
		handleSubmitForm,
		handleForgotPassword,
		getValidationFieldText,
		getValidationFieldStatus,
		sendOneTimeLink,
		validationSubMessage,
		code,
		codeError,
		handleUpdateCode,
	};
};
