<script lang="ts" setup>
import { ACCEPT_POLICY_TERMS } from '@/locales/constants';
import { getRootDomain } from '@/utils/helpers';
import { ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { PrimeCheckbox } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const homePageURL = getRootDomain(true);

const legalRequirements = ref<{ policyAccepted: boolean }>({
	policyAccepted: false,
});

const { policyAccepted } = useValidationRules();

const legalRequirementsValidation = useVuelidate(
	{ policyAccepted },
	legalRequirements
);

const {
	reset: policyValidationReset,
	validate: policyValidate,
	isValidationError: policyValidationError,
} = useValidationUtils<{ policyAccepted: boolean }>(
	legalRequirementsValidation
);

defineExpose({
	policyValidationReset,
	policyValidate,
	policyValidationError,
});
</script>

<template>
	<div class="accept-policy">
		<PrimeCheckbox
			v-model="legalRequirements.policyAccepted"
			:invalid="policyValidationError"
		>
			<span
				v-html="
					$t(ACCEPT_POLICY_TERMS, {
						privacyLink: `${homePageURL}/policy`,
						termsLink: `${homePageURL}/terms`,
					})
				"
			/>
		</PrimeCheckbox>
	</div>
</template>

<style lang="scss" scoped>
.accept-policy {
	display: flex;
	text-align: left;
	margin-top: 1.125rem;

	:deep(a.legal-link) {
		color: var(--base-primary);
		text-decoration: none;

		&:visited,
		&:active,
		&:link,
		&:hover {
			color: var(--base-primary-hover);
		}

		&:hover {
			text-decoration: underline;
		}
	}
}
</style>
