import API from '@/api';
import type { Prize } from '@/types';

export const getDrawPrizes = async (
	position: number
): Promise<Prize[] | any> => {
	const response = await API.post<{ prizes: Prize[] }>('/api/draw-prize', {
		box_position: position,
	});

	return response.data;
};
