<script setup lang="ts">
import { CONTINUE, PASSWORD, SIGN_UP, SIGN_UP_INFO } from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import AcceptPolicy from '@/components/auth/AcceptPolicy.vue';
import { useSignupView } from '@/views/signup/composables';
import {
	PrimeText,
	PrimeButton,
	PrimeInput,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleSubmitForm,
	form,
	getValidationFieldText,
	getValidationFieldStatus,
	isLoading,
} = useSignupView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_UP)">
		<form class="register-form" @submit.prevent="handleSubmitForm">
			<PrimeInput
				v-model="form.email"
				disabled
				readonly
				autocomplete="username"
				type="email"
			/>
			<PrimeInput
				v-model="form.password"
				autofocus
				autocomplete="password"
				type="password"
				:placeholder="$t(PASSWORD)"
				:invalid="!!getValidationFieldStatus('password')"
				:error-text="getValidationFieldText('password')?.toString()"
			/>

			<AcceptPolicy ref="acceptPolicyEl" />

			<PrimeText size="xs" weight="500" class="info-message">
				{{ $t(SIGN_UP_INFO) }}
			</PrimeText>

			<PrimeButton
				role="submit"
				class="submit-register-button"
				:label="$t(CONTINUE)"
			/>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.register-form {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	& .submit-register-button {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	& .info-message {
		padding: 0.75rem;
		text-align: center;
		background-color: var(--base-primary-03);
		border-radius: 1rem;
		margin-top: 1.5rem;
	}
}
</style>
