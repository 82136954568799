<script setup lang="ts">
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	REF_SHARE_ATTENTION,
	COPIED_INTO_CLIPBOARD,
	USE_REFERRAL_INSTEAD,
	COPY_CODE,
} from '@/locales/constants';
import {
	PiWarningCircle,
	PiCopy,
	PiLink,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { COPY_REFERRAL_LINK_MODAL } from '@/constants/modals';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useI18n } from 'vue-i18n';

const userStore = useUserStore();
const { referralBonusCode } = storeToRefs(userStore);
const { t } = useI18n();

const modalStorage = useModalStorage();
const copyToClipboard = async () => {
	await navigator.clipboard.writeText(referralBonusCode.value);
	notify({
		body: `${t(COPIED_INTO_CLIPBOARD)}: ${referralBonusCode.value}`,
	});
};

const openLinkModal = async () => {
	await modalStorage.pushModal({
		name: COPY_REFERRAL_LINK_MODAL,
	});
};
</script>

<template>
	<section class="referrals-share">
		<div class="referrals-share--attention">
			<PiWarningCircle size="1.25rem" color="base-secondary" />
			<PrimeText size="xs" weight="500" color="secondary">
				{{ $t(REF_SHARE_ATTENTION) }}
			</PrimeText>
		</div>
		<div class="referrals-share--buttons">
			<div class="referrals-share--button">
				<PrimeButton
					type="gradient"
					class="referrals-share--copy-button"
					transform="capitalize"
					:label="`${$t(COPY_CODE)}: ${referralBonusCode}`"
					rounded="rounded"
					@click="copyToClipboard"
				>
					<template #iconLeft>
						<PiCopy size="1.375rem" color="white" />
					</template>
				</PrimeButton>
			</div>
			<div class="referrals-share--button">
				<PrimeButton
					type="outline"
					variant="grey"
					class="referrals-share--copy-button"
					:label="$t(USE_REFERRAL_INSTEAD)"
					rounded="rounded"
					@click="openLinkModal"
				>
					<template #iconLeft><PiLink size="1.375rem" /></template>
				</PrimeButton>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals-share {
	&--attention {
		display: flex;
		align-items: center;
		& > svg {
			flex: none;
			flex-shrink: 0;
			margin-inline-end: 0.625rem;
		}
	}

	&--buttons {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 0.875rem;
		margin-top: 0.75rem;
	}

	&--button {
		flex: 1;

		.p-btn {
			width: 100%;
		}
	}

	@include breakpoint(mobile) {
		&--buttons {
			flex-direction: row;
		}
	}
}
</style>
