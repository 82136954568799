import {
	JANUARY,
	FEBRUARY,
	MARCH,
	APRIL,
	MAY,
	JUNE,
	JULY,
	AUGUST,
	SEPTEMBER,
	OCTOBER,
	NOVEMBER,
	DECEMBER,
} from '@/locales/constants';

const months = [
	JANUARY,
	FEBRUARY,
	MARCH,
	APRIL,
	MAY,
	JUNE,
	JULY,
	AUGUST,
	SEPTEMBER,
	OCTOBER,
	NOVEMBER,
	DECEMBER,
];

export const getMonthTerm = (index?: number): string => {
	const monthIndex = index || new Date().getMonth();
	return months[monthIndex];
};

export const getLocalTime = (
	timeZone: string = 'UTC'
): Record<string, string> => {
	const now = new Date();
	const date = now.toLocaleTimeString('en-US', {
		hour12: false,
		timeZone,
	});
	const time = date.split(':');
	const hours = time[0];
	const minutes = time[1];
	const seconds = time[2];

	return { hours, minutes, seconds };
};
