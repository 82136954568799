import { useComponentName } from '@/utils/composables/useComponentName';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import type { ComputedRef, Ref } from 'vue';
import { ref } from 'vue';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';

export const useForgotPasswordView = (): {
	email: ComputedRef<string>;
	isLoading: Ref<boolean>;
	resendResetPassword: () => Promise<void>;
	cooldownButtonEl: Ref<{ restartTimer: () => void } | null>;
} => {
	const componentName = useComponentName();
	const authStore = useAuthStore();
	const { authUserEmail, isResetPasswordSent } = storeToRefs(authStore);

	const isLoading = ref(false);
	const cooldownButtonEl = ref<{ restartTimer: () => void } | null>(null);

	const resendResetPassword = async () => {
		try {
			isLoading.value = true;
			const response = await authStore.forgotPassword(authUserEmail.value!);

			if (isResetPasswordSent.value) {
				cooldownButtonEl.value?.restartTimer();
				notify({ body: response?.data.message });
			}
		} catch (error) {
			notify({ body: authStore.error?.message || '' });
			sendErrorInfo(error, componentName);
		} finally {
			isLoading.value = false;
		}
	};

	return {
		email: authUserEmail,
		isLoading,
		resendResetPassword,
		cooldownButtonEl,
	};
};
