<script setup lang="ts">
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import AchievementsGroup from '@/views/achievements/components/AchievementsGroup.vue';
import { ACHIEVEMENTS } from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useAchievementsView } from '@/views/achievements/composables';

const { achievements, loading, claimPrize, initLoading } =
	useAchievementsView();
</script>

<template>
	<PrimeLoaderSpinner v-if="initLoading" />
	<main v-else class="achievements">
		<LayoutSubpage :title="$t(ACHIEVEMENTS)">
			<AchievementsGroup
				v-for="achievement in achievements"
				:key="achievement.achievement_id"
				:achievement="achievement"
				@claim-achievement="claimPrize($event)"
			/>
		</LayoutSubpage>
		<PrimeLoader v-if="loading" />
	</main>
</template>

<style lang="scss" scoped>
.achievements {
	width: 100%;
}
</style>
