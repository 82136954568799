<script setup lang="ts">
import { PrimeToggle } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { ref } from 'vue';

const useReleaseScriptUrl = ref(
	localStorage.getItem('use-release') === 'true' || false
);

const updateItem = (value: boolean) => {
	localStorage.setItem('use-release', value.toString());
	document.location.reload();
};
</script>

<template>
	<div>
		<PrimeToggle
			v-model="useReleaseScriptUrl"
			:checked="useReleaseScriptUrl"
			label="Use release script URL"
			@input="updateItem(useReleaseScriptUrl)"
		/>
	</div>
</template>
