<script setup lang="ts">
import {
	TRANSACTIONS_EMPTY_TITLE,
	TRANSACTIONS_EMPTY_DESCRIPTION,
	COLLECT_POINTS,
} from '@/locales/constants';
import { useRouter } from 'vue-router';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();

const handleClick = () => router.push({ name: 'surveys' });
</script>

<template>
	<div class="transactions-empty">
		<PrimeText
			tag="h2"
			weight="600"
			size="lg"
			class="transactions-empty--title"
		>
			{{ $t(TRANSACTIONS_EMPTY_TITLE) }}
		</PrimeText>
		<PrimeText
			tag="p"
			weight="500"
			color="grey-700"
			class="transactions-empty--description"
		>
			{{ $t(TRANSACTIONS_EMPTY_DESCRIPTION) }}
		</PrimeText>
		<PrimeButton
			type="gradient"
			full-width
			:label="$t(COLLECT_POINTS)"
			@click="handleClick"
		/>
	</div>
</template>

<style lang="scss" scoped>
.transactions-empty {
	display: flex;
	flex-direction: column;
	align-items: center;

	&--title {
		margin-bottom: 0.25rem;
	}

	&--description {
		margin-bottom: 1.375rem;
	}
}
</style>
