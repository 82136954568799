<script setup lang="ts">
import { OPEN_BOX, PRIZE_DRAW } from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrizeDrawHeader from '@/views/prize-draw/components/PrizeDrawHeader.vue';
import PrizeDrawBox from '@/views/prize-draw/components/PrizeDrawBox.vue';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import { usePrizeDrawView } from '@/views/prize-draw/composables';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { StreakPrizes } from '@/enums';

const {
	showWinMessage,
	boxes,
	hiddenBoxes,
	selectedBoxPosition,
	disabledBoxes,
	openBox,
	isLoading,
	prize,
	handleSelectBox,
	boxOpened,
	isButtonDisable,
} = usePrizeDrawView();

const { prizes } = storeToRefs(useUserStore());
</script>

<template>
	<LayoutSubpage back-button-visible :title="$t(PRIZE_DRAW)">
		<main class="prize-draw">
			<PrizeDrawHeader
				:show-win-message="showWinMessage"
				:type="prize.is_code"
				:prize="
					prize.is_code === StreakPrizes.BONUS
						? `${prize.percentage}%`
						: prize.coin_value
				"
			/>
			<section class="prize-draw--fields-wrapper">
				<div class="prize-draw--fields">
					<PrizeDrawBox
						v-for="({ id, coin_value, is_code, percentage }, index) in boxes"
						:key="id"
						:hidden="hiddenBoxes && selectedBoxPosition !== index"
						:disabled="
							(disabledBoxes && selectedBoxPosition !== index) ||
							boxOpened ||
							!prizes
						"
						:selected="selectedBoxPosition === index"
						:content="
							disabledBoxes
								? is_code === StreakPrizes.BONUS
									? `${percentage}%`
									: localizePoints(coin_value || 0)
								: '?'
						"
						@click="handleSelectBox(index)"
					/>
				</div>
			</section>
			<div class="buttons-wrapper">
				<PrimeButton
					full-width
					type="gradient"
					:label="$t(OPEN_BOX)"
					:disabled="isButtonDisable"
					:loading="isLoading"
					@click="openBox"
				/>
			</div>
		</main>
	</LayoutSubpage>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

:deep(.subpage__content) {
	max-width: 1005px;
}

.prize-draw {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 0 1rem;

	@include breakpoint(mobile) {
		max-width: 73.125rem;
	}
}

.buttons-wrapper {
	width: 100%;
	align-self: center;
}

.prize-draw--fields-wrapper {
	flex-grow: 1;
	align-self: center;
	width: 100%;
}

.prize-draw--fields {
	grid-gap: 0.625rem 0.75rem;
	padding: 0.625rem 0;
	display: grid;
	grid-template-columns: repeat(3, auto);
	align-self: stretch;

	@include breakpoint(mobile) {
		padding: 1.5rem 0;
		grid-template-columns: repeat(6, auto);
	}

	@include breakpoint(tablet) {
		padding: 1.5rem 0;
		grid-template-columns: repeat(4, auto);
	}

	@include breakpoint(laptop) {
		grid-template-columns: repeat(6, auto);
	}
}
</style>
