import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { isUserDev } from '@/utils/helpers';
import package_json from '../../../package.json';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { appLogStorage } from '@/utils/local-storage';

export const useDev = (): {
	isDev: ComputedRef<boolean>;
	appMeta: ComputedRef<string>;
	packageVersion: ComputedRef<string>;
	version: ComputedRef<string>;
} => {
	const { platform } = useMobileApp();
	const userStore = useUserStore();
	const { email } = storeToRefs(userStore);

	const isDev = computed(() => {
		if (email.value) {
			appLogStorage.value = isUserDev(email.value);
		}
		return isUserDev(email.value);
	});

	const appMeta = computed(() => {
		return `${import.meta.env.MODE}`;
	});

	const version = computed(() => {
		return `${package_json.version}`;
	});

	const packageVersion = computed(() => package_json.version);

	return {
		isDev,
		appMeta,
		version,
		packageVersion,
	};
};
