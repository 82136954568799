<script lang="ts" setup>
import { ref } from 'vue';
import {
	EMAIL_VERIFY_WARNING,
	NOT_VERIFIED_CODE,
	RESEND,
	SENT,
} from '@/locales/constants';
import { useAuthStore } from '@/stores/auth';
import { notify } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import {
	PiCheck,
	PiWarningTriangleDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const emailSent = ref(false);
const isLoading = ref(false);
const authStore = useAuthStore();
const componentName = useComponentName();
const { isMobileApp } = useMobileApp();

const handleResendEmail = async () => {
	try {
		isLoading.value = true;
		const response = await authStore.resendEmail();

		if (response?.status === 200) {
			emailSent.value = true;
			notify({ body: response?.data.message });
		}
	} catch (error) {
		notify({ body: authStore.error?.message || '' });
		sendErrorInfo(error, componentName);
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
	<div class="verify-email-wrapper">
		<PiWarningTriangleDuotone color="states-warning" class="warning-icon" />
		<PrimeText
			class="warning-text"
			size="sm"
			weight="500"
			v-html="$t(isMobileApp ? NOT_VERIFIED_CODE : EMAIL_VERIFY_WARNING)"
		/>
		<PrimeButton
			v-if="!emailSent"
			type="link"
			variant="primary"
			class="resend-email-button"
			:label="$t(RESEND)"
			:disabled="isLoading"
			@click="handleResendEmail"
		/>
		<div v-else class="sent-text">
			<PiCheck />
			<PrimeText size="sm" weight="600">{{ $t(SENT) }}</PrimeText>
		</div>
	</div>
</template>

<style lang="scss">
.verify-email-wrapper {
	padding: 0.75rem 0.875rem;
	background-color: var(--rgba-states-warning-20);
	border: 1px solid var(--states-warning);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.5rem;
	margin: 1.375rem 0;
}

.warning-icon {
	flex: 0 0 auto;
	margin-right: 0.625rem;
}

.warning-text {
	margin: 0;
	flex: 1 1 auto;
	max-width: 15.625rem;
	text-align: left;

	& span {
		font-weight: 600;
	}
}

.resend-email-button {
	margin-left: auto;

	&.disabled {
		cursor: not-allowed;

		&:hover {
			text-decoration: none;
		}
	}
}

.sent-text {
	margin-left: auto;
	flex: 0 0 auto;
	display: flex;
	align-items: center;

	svg {
		margin-right: 0.375rem;
	}
}
</style>
