<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	SET_USERNAME,
	CHANGE_USERNAME,
	CHANGE_TIMEZONE,
	ACCOUNT_SETTINGS,
	CHANGE_EMAIL,
	CHANGE_PASSWORD,
	EMAIL_PREFERENCES,
	DELETE_ACCOUNT,
	LANGUAGE,
} from '@/locales/constants';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import SettingsItem from './components/SettingsItem.vue';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_USERNAME_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_LANGUAGE_MODAL,
} from '@/constants/modals';
import {
	PiAtDuotone,
	PiCaretRight,
	PiClockDuotone,
	PiEnvelopeSimpleDuotone,
	PiGlobeSimpleDuotone,
	PiLockDuotone,
	PiTrashDuotone,
	PiUserCircleDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeSkeleton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { username, userLocales, loading } = storeToRefs(useUserStore());
const router = useRouter();
</script>

<template>
	<main class="settings">
		<LayoutSubpage :title="$t(ACCOUNT_SETTINGS)">
			<template v-if="loading">
				<PrimeSkeleton
					v-for="i in 6"
					:key="i"
					height="3.5rem"
					class="settings-skeleton"
				/>
			</template>
			<div v-else class="settings__items">
				<SettingsItem
					:title="username ? $t(CHANGE_USERNAME) : $t(SET_USERNAME)"
					:modal="{ name: CHANGE_USERNAME_MODAL }"
					:start-icon="PiUserCircleDuotone"
					:end-icon="PiCaretRight"
				/>
				<SettingsItem
					:title="$t(CHANGE_TIMEZONE)"
					:modal="{ name: CHANGE_TIMEZONE_MODAL }"
					:start-icon="PiClockDuotone"
					:end-icon="PiCaretRight"
				/>
				<SettingsItem
					:title="$t(CHANGE_EMAIL)"
					:modal="{ name: CHANGE_EMAIL_MODAL }"
					:start-icon="PiEnvelopeSimpleDuotone"
					:end-icon="PiCaretRight"
				/>
				<SettingsItem
					:title="$t(EMAIL_PREFERENCES)"
					:start-icon="PiAtDuotone"
					:end-icon="PiCaretRight"
					:click-handler="() => router.push({ name: 'email-subscriptions' })"
				/>
				<SettingsItem
					v-if="userLocales?.length > 1"
					:title="$t(LANGUAGE)"
					:modal="{ name: CHANGE_LANGUAGE_MODAL }"
					:start-icon="PiGlobeSimpleDuotone"
					:end-icon="PiCaretRight"
				/>
				<SettingsItem
					:title="$t(CHANGE_PASSWORD)"
					:modal="{ name: CHANGE_PASSWORD_MODAL }"
					:start-icon="PiLockDuotone"
					:end-icon="PiCaretRight"
				/>
				<SettingsItem
					:title="$t(DELETE_ACCOUNT)"
					:modal="{ name: DELETE_ACCOUNT_MODAL }"
					:start-icon="PiTrashDuotone"
					start-icon-color="states-critical"
					text-color="states-critical"
				/>
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
.settings {
	width: 100%;
}

.settings-skeleton {
	margin-bottom: 0.875rem;
}
</style>
