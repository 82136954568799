import { computed } from 'vue';
import type { ComputedRef } from 'vue';
import { BALANCE_REST } from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

export const useClaimBaseInfo = (): {
	isClaimAvailable: ComputedRef<boolean>;
	restOfTheBalanceText: ComputedRef<string>;
} => {
	const { t } = useI18n();

	const userStore = useUserStore();
	const {
		isWelcomeBonusClaimed,
		collectedCoins,
		userSelectedReward,
		isWelcomeBonusSelected,
		minWelcomeBonusClaimBalance,
	} = storeToRefs(userStore);

	const restOfTheBalanceText = computed(() => {
		if (userSelectedReward.value) {
			return t(BALANCE_REST, {
				value: localizePoints(
					collectedCoins.value - userSelectedReward.value.coin_value
				),
			});
		}
		return '';
	});

	const isMinimumBalanceReached = computed(() => {
		return (
			isWelcomeBonusSelected.value &&
			collectedCoins.value >= minWelcomeBonusClaimBalance.value
		);
	});

	const isClaimAvailable = computed(() => {
		return Boolean(
			isWelcomeBonusClaimed.value || isMinimumBalanceReached.value
		);
	});

	return {
		isClaimAvailable,
		restOfTheBalanceText,
	};
};
