import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import type { Component, ComputedRef, Ref } from 'vue';
import { computed, onBeforeMount, ref } from 'vue';
import type { ProfileStats } from '@/types';
import { getProfile } from '@/api';
import { ProfileNavigationTypes } from '@/enums';
import {
	PiCheckCircleDuotone,
	PiCoinsDuotone,
	PiFireDuotone,
	PiGiftDuotone,
	PiMedal,
	PiTrophyDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import {
	ACHIEVEMENTS_EARNED,
	HIGHEST_RANK,
	LEADERBOARD_LOCKED,
	LEADERBOARD_UNLOCK_TARGET,
	LEADERBOARD_UNLOCKED_MESSAGE,
	NONE,
	PROFILE_LEADERBOARD_RECORD,
	PROFILE_LONGEST_STREAK,
	PROFILE_LONGEST_STREAK_DAYS,
	PROFILE_POINTS_EARNED,
	PROFILE_POINTS_RATING,
	PROFILE_REWARDS_REDEEMED,
	PROFILE_SURVEYS_COMPLETED,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';

export type ProfileNavigationItem = {
	type: ProfileNavigationTypes;
	icon: Component;
	title: string;
	details: string;
	subtitle?: string;
	route?: { name: string; params?: Record<string, string> };
	handler?: () => void;
	visible: boolean;
};

export const useProfileView = (): {
	profileConfigItems: ComputedRef<ProfileNavigationItem[]>;
	isProfileStatsLoaded: ComputedRef<boolean>;
	isLoading: Ref<boolean>;
	isDarkModeEnabled: Ref<boolean>;
} => {
	const { leaderboard, username } = storeToRefs(useUserStore());
	const { t } = useI18n();

	const profileStats = ref<ProfileStats | null>(null);
	const isLoading = ref(true);

	const { isDarkModeEnabled } = storeToRefs(useUserStore());

	onBeforeMount(async () => {
		const data = await getProfile();
		if (data) {
			profileStats.value = data?.stats;
			isLoading.value = false;
		}
	});

	const isProfileStatsLoaded = computed(() => !!profileStats.value);

	const leaderboardData = computed<{ title: string; details: string }>(() => {
		const surveysLeft =
			leaderboard.value.total_completes_required_to_unlock -
			leaderboard.value.successful_completes;

		const lockedLeaderboardDetails =
			surveysLeft > 0
				? t(LEADERBOARD_UNLOCK_TARGET, {
						value: surveysLeft,
					})
				: !username.value
					? t(LEADERBOARD_UNLOCKED_MESSAGE)
					: '';

		return leaderboard.value.unlocked
			? profileStats.value?.leaderboard.record
				? {
						title: profileStats.value.leaderboard.record.toString() || '',
						details: t(HIGHEST_RANK),
					}
				: {
						title: t(NONE),
						details: t(PROFILE_LEADERBOARD_RECORD),
					}
			: {
					title: t(LEADERBOARD_LOCKED),
					details: lockedLeaderboardDetails,
				};
	});

	const profileConfig = computed<ProfileNavigationItem[]>(() => [
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiMedal,
			title: `${profileStats.value?.achievements?.completed} / ${profileStats.value?.achievements?.total}`,
			details: t(ACHIEVEMENTS_EARNED),
			route: { name: 'achievements' },
			visible: !!profileStats.value?.achievements,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiCoinsDuotone,
			title: localizePoints(profileStats.value?.points?.total || 0),
			details: t(PROFILE_POINTS_EARNED),
			subtitle: profileStats.value?.points?.rating
				? t(PROFILE_POINTS_RATING, { value: profileStats.value.points.rating })
				: '',
			route: { name: 'reward-history', params: { type: 'transactions' } },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiCheckCircleDuotone,
			title: String(profileStats.value?.surveys?.completed),
			details: t(PROFILE_SURVEYS_COMPLETED),
			route: { name: 'recent-activity' },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiGiftDuotone,
			title: localizePoints(profileStats.value?.reward?.total || 0),
			details: t(PROFILE_REWARDS_REDEEMED),
			route: { name: 'reward-history', params: { type: 'rewards' } },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiFireDuotone,
			title: t(PROFILE_LONGEST_STREAK_DAYS, {
				value: profileStats.value?.streaks?.longest,
			}),
			details: t(PROFILE_LONGEST_STREAK),
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiTrophyDuotone,
			title: leaderboardData.value.title,
			details: leaderboardData.value.details,
			visible: true,
		},
	]);

	const profileConfigItems = computed(() =>
		profileConfig.value.filter((item) => item.visible)
	);

	return {
		profileConfigItems,
		isProfileStatsLoaded,
		isLoading,
		isDarkModeEnabled,
	};
};
