import { defineStore } from 'pinia';
import { getAchievements, postAchievementsLevels } from '@/api';
import type { ErrorResponse, AchievementGroup } from '@/types';
import type { AxiosError } from 'axios';

import { sendErrorInfo } from '@/utils/errorCatching';

interface AchievementsState {
	data: AchievementGroup[];
	message: string;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	status: number;
}

export const useAchievementsStore = defineStore('achievements', {
	state: (): AchievementsState => ({
		data: [],
		message: '',
		loading: false,
		initLoading: false,
		error: null,
		status: 0,
	}),

	getters: {
		achievements: (state): AchievementGroup[] => state.data,
		achievementsMessage: (state) => state.message,
		achievementsError: (state) => state.error,
	},

	actions: {
		async fetchAchievements(): Promise<void> {
			try {
				this.initLoading = true;
				this.data = await getAchievements();
			} catch (error) {
				const axiosErrorResponse = (error as AxiosError).response;
				this.error = axiosErrorResponse?.data as ErrorResponse;
				sendErrorInfo(error);
			} finally {
				this.initLoading = false;
			}
		},

		async claimAchievementPrize(achievementId: string): Promise<void> {
			this.loading = true;

			try {
				const { status, data } = await postAchievementsLevels(achievementId);
				this.status = status;

				if (this.status === 201 || this.status === 200) {
					await this.fetchAchievements();
					this.message = data;
				}
			} catch (error: any | undefined) {
				const { message } = (error as AxiosError).response
					?.data as ErrorResponse;
				this.message = message;

				this.error = error;
			} finally {
				this.loading = false;
			}
		},
	},
});
