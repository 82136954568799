import { handleUserLocale } from '@/i18n';
import router from '@/router/index';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useUserStore } from '@/stores/user';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import {
	AUTH_ROUTE_NAME,
	USER_ROUTE_NAME,
} from '@/constants/routes';
import { FeatureFlags } from '@/enums';

export const localeGuard = async (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	_: NavigationGuardNext
) => {
	const { query } = to;

	if (query && query.locale) {
		await handleUserLocale(query.locale.toString());

		delete query.locale;

		const updatedURL = `${window.location.pathname}?${new URLSearchParams(
			// @ts-ignore
			query
		).toString()}`;

		await router.replace(updatedURL);
	}
};

export const authGuard = async (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { authToken } = useAppCookies();
	const authStore = useAuthStore();
	const isAuthorised = Boolean(authStore.token && authToken);
	const isPublicRoute = to.meta.isPublic;

	// Ignore authorization for this routes
	if (to.meta.ignoreAuth) {
		next();
		return;
	}

	// If Admin is logging in as user - destroy token and continue
	if (
		(to.name === AUTH_ROUTE_NAME.APP_LOGIN ||
			to.name === AUTH_ROUTE_NAME.SIGNATURE_LOGIN) &&
		to.query.auth_email &&
		to.query.auth_token
	) {
		if (authStore.token) {
			await authStore.removeToken();
			next();
			return;
		}
	}

	// IF user NOT authorised and this page NOT related to authentication flow
	if (!authToken && !isPublicRoute) {
		if (authStore.token) {
			await authStore.removeToken();
		}
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
		return;
		// IF user authorised and this page related to authentication flow
	} else if (isAuthorised && isPublicRoute) {
		next({ name: USER_ROUTE_NAME.SURVEYS });
		return;
	}

	next();
};

export const loadingGuard = async (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const { isAuthorized } = storeToRefs(useAuthStore());
	if (!isAuthorized.value) return;

	const { availableUserFeatures, isInitialDataLoading } =
		storeToRefs(useUserStore());

	// Wait for initial data loading before checking feature flags
	await new Promise((resolve) => {
		const checkLoading = () => {
			if (!isInitialDataLoading.value) {
				clearInterval(interval);
				resolve(null);
			}
		};
		const interval = setInterval(checkLoading, 50);
		// Check immediately
		checkLoading();
	});

	const feature = to.meta.feature as FeatureFlags;
	if (feature && !availableUserFeatures.value?.includes(feature)) {
		next({ name: USER_ROUTE_NAME.SURVEYS });
		return;
	}
};
