<script lang="ts" setup>
import type { BonusItem } from '@/types';
import {
	COINS,
	REDEEM_FROM,
	FAST_PAYOUT,
	MOST_POPULAR,
	BIGGEST_BONUS,
} from '@/locales/constants';
import { computed } from 'vue';
import { BonusLabels } from '@/enums';
import { localizePoints } from '@/utils/helpers';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const props = defineProps<{
	item: BonusItem;
	selectedBonus: boolean;
}>();

defineEmits<{
	(e: 'select-bonus', $event: BonusItem): void;
}>();

const label = computed<string>(() => {
	let bonusLabel = '';
	switch (props.item.label) {
		case BonusLabels.BIGGEST_BONUS:
			bonusLabel = BIGGEST_BONUS;
			break;
		case BonusLabels.FAST_PAYOUT:
			bonusLabel = FAST_PAYOUT;
			break;
		case BonusLabels.MOST_POPULAR:
			bonusLabel = MOST_POPULAR;
			break;
	}
	return bonusLabel;
});
</script>

<template>
	<div
		class="reward-option"
		:class="{
			selected: selectedBonus,
			popular: item.label === BonusLabels.MOST_POPULAR,
		}"
		@click="$emit('select-bonus', item)"
	>
		<PrimeText
			v-if="label"
			color="secondary"
			weight="500"
			size="2xs"
			class="bonus-title"
		>
			{{ $t(label) }}
		</PrimeText>

		<div class="top-info">
			<PrimeText tag="p" weight="700" size="xl">
				{{ localizePoints(item.coin_value) }}
				<br />
				<PrimeText weight="700" transform="capitalize">
					{{ $t(COINS) }}
				</PrimeText>
			</PrimeText>
		</div>

		<div>
			<PrimeText size="xs" weight="500" color="grey-700">
				{{ $t(REDEEM_FROM) }}
			</PrimeText>
			<br />
			<PrimeText size="xs" weight="500">
				{{ localizePoints(item.min_claim_balance) }}
				{{ $t(COINS).toLowerCase() }}
			</PrimeText>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.reward-option {
	height: 100%;
	padding: 0.875rem 0.3125rem;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--grey-300);
	box-shadow: var(--card-shadow);
	border-radius: 0.5rem;
	cursor: pointer;
	text-align: center;
	transition:
		border-color 0.3s,
		background-color 0.3s;

	&:hover {
		border-color: var(--base-primary);
		background-color: var(--base-primary-03);
	}

	@include breakpoint(mobileonly) {
		height: auto;
	}

	&.selected {
		border-color: var(--base-primary);
		background-color: var(--base-primary-03);
	}

	&.popular {
		padding-top: 0.5rem;

		.bonus-title {
			margin-bottom: 1.625rem;
			padding: 0.25rem;
			font-weight: 600;
			color: var(--white);
			background-color: var(--base-secondary);
			border-radius: 0.25rem;
		}
	}
}

.bonus-title {
	margin-bottom: 1rem;
	word-break: break-all;
}

.top-info {
	width: 100%;
	margin-bottom: 1rem;

	& .p-text {
		line-height: 1.1;
	}

	& .money-value {
		font-weight: 500;
		color: var(--grey-700);
	}
}
</style>
