<script setup lang="ts">

</script>

<template>
	<div class="section-wrapper" :class="[$attrs.class]">
		<slot/>
	</div>

</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.section-wrapper {
	width: 100%;
	padding: 1.5rem 0.875rem;
	background-color: var(--white);
	border-radius: 0.75rem;
	max-width: 100%;
	border: 1px solid var(--grey-200);
	margin-bottom: 1.5rem;

	@include breakpoint(tablet) {
		padding: 1.5rem;
	}
}
</style>
