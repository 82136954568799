<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { IconColors, UserLevel } from '@/types';
import {
	CONGRATS,
	EARN_COINS_TO_REACH_LEVEL,
	GOTCHA,
	WE_CREDITED_YOU_COINS,
	YOU_REACHED_LEVEL,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import TierCircle from '@/components/tiers/TierCircle.vue';
import TierBenefit from '@/components/tiers/TierBenefit.vue';
import {
	PrimeButton,
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { Benefits, benefitsContent } from '@/components/tiers/benefits';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const props = defineProps<{
	options: UserLevel;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const actual = ref(props?.options?.actual);
const next = ref(props?.options?.next);
const coins_earned = ref(props?.options?.coins_earned);
const coins_target = ref(props?.options?.coins_target);
const reward_earned = ref(props?.options?.reward_earned);
const tier = ref(props.options.tier);
const is_new_tier = ref(props.options.is_new_tier);

const nextLevelPointsTarget = computed(() => {
	return localizePoints(
		Number(coins_target.value) - Number(coins_earned.value)
	);
});

const { bonusDayBonus, rewardsDiscount } = storeToRefs(useUserStore());

const dynamicValue = computed(() => {
	switch (tier.value.benefit) {
		case Benefits.BONUS_DAY:
			return bonusDayBonus.value;
		case Benefits.REWARD_DISCOUNT:
			return rewardsDiscount.value;
		default:
			return '';
	}
});
</script>

<template>
	<ModalCommonContent id="congrats-level-modal">
		<template #header>{{ $t(CONGRATS) }}</template>
		<template #default>
			<div class="actual-level__wrapper">
				<TierCircle
					class="actual-level__badge"
					size="2.75rem"
					level-size="1.25rem"
					:tier-key="tier.key"
					:level="actual"
				/>

				<PrimeText tag="div" weight="500" class="actual-level__reached-level">
					<span v-html="`${$t(YOU_REACHED_LEVEL, { value: actual })}`" />
				</PrimeText>
				<PrimeText
					v-if="reward_earned"
					tag="div"
					weight="500"
					class="actual-level__earned-bonus"
				>
					{{
						$t(WE_CREDITED_YOU_COINS, { amount: localizePoints(reward_earned) })
					}}
				</PrimeText>

				<div class="actual-level__benefits-section">
					<PrimeDivider
						v-if="!is_new_tier || (!tier.benefit && !tier.is_available)"
					/>
					<TierBenefit
						v-if="tier.benefit && tier.is_available && is_new_tier"
						:dynamic-value="dynamicValue"
						:tier-key="tier.key as IconColors"
						:benefit="tier.benefit"
					/>
					<template
						v-if="
							tier.benefit &&
							benefitsContent[tier.benefit].details &&
							is_new_tier
						"
					>
						<PrimeText
							tag="p"
							size="sm"
							weight="500"
							class="actual-level__benefits-description"
						>
							{{ $t(benefitsContent[tier.benefit].details, { dynamicValue }) }}
						</PrimeText>
						<PrimeDivider top-gap="1rem" />
					</template>
				</div>

				<PrimeText size="sm" weight="500">
					{{
						$t(EARN_COINS_TO_REACH_LEVEL, {
							amount: nextLevelPointsTarget,
							value: next,
						})
					}}
				</PrimeText>
			</div>
		</template>
		<template #actions>
			<PrimeButton
				type="gradient"
				full-width
				:label="$t(GOTCHA)"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#congrats-level-modal {
	.content-title,
	.content-message {
		line-height: 1.4;
	}

	.actual-level__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.actual-level__badge {
		margin: 0.25rem auto;
	}

	.actual-level__reached-level {
		margin-top: 0.25rem;
	}

	.actual-level__benefits-section {
		width: 100%;
		margin: 1rem 0;
	}

	& .actual-level__benefits-description {
		margin-top: 1rem;
	}
}
</style>
