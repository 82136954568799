import { defineStore } from 'pinia';
import { getWelcomeBonuses, selectWelcomeBonus } from '@/api';
import type { BonusItem, ErrorResponse, MessageResponse } from '@/types';
import { useUserStore } from '@/stores/user';
import { sendErrorInfo } from '@/utils/errorCatching';
import { getErrorResponse } from '@/utils/helpers';

interface BonusState {
	data: BonusItem[];
	message: string;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	isPresetBonus: boolean;
}

const PRESET_BONUS_VALUE = 100;

export const useBonusStore = defineStore('bonus', {
	state: (): BonusState => ({
		data: [],
		message: '',
		loading: false,
		initLoading: false,
		error: null,
		isPresetBonus: false,
	}),
	getters: {
		presetBonus(state): BonusItem | null {
			return (
				state.data?.find((bonus) => bonus.coin_value === PRESET_BONUS_VALUE) ||
				null
			);
		},
	},
	actions: {
		async fetchBonusList(): Promise<void> {
			try {
				this.initLoading = true;
				const res = await getWelcomeBonuses();
				this.data = res?.bonuses || [];
				this.isPresetBonus = res?.is_preset_bonus || false;
			} catch (error) {
				this.error = getErrorResponse(error);
				sendErrorInfo(error);
			} finally {
				this.initLoading = false;
			}
		},

		async selectBonus(value: number): Promise<MessageResponse> {
			const userStore = useUserStore();
			try {
				this.loading = true;
				return await selectWelcomeBonus(value);
			} catch (error) {
				sendErrorInfo(error);
				this.error = getErrorResponse(error);
				return { message: this.error.message };
			} finally {
				await userStore.fetchUserData();
				this.loading = false;
			}
		},
	},
});
