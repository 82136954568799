<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { fetchTotalRewardsStats } from '@/api';
import type { TotalClaims } from '@/types';
import {
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { POINTS, TOTAL_CLAIMS } from '@/locales/constants';
import { useI18n } from 'vue-i18n';

const totalClaims = ref<TotalClaims | null>(null);
const isLoading = ref(true);

onBeforeMount(async () => {
	totalClaims.value = await fetchTotalRewardsStats();
	isLoading.value = false;
});

const amountOfClaims = computed(() => {
	return transformCurrency(
		+totalClaims.value?.money_value! || 0,
		totalClaims.value?.currency || 'USD',
		2
	);
});

const { t } = useI18n();

const totalClaimsValueText = computed(() => {
	return `${localizePoints(totalClaims.value?.coin_value || 0)} ${t(
		POINTS
	)} (≈ ${amountOfClaims.value})`;
});
</script>

<template>
	<div class="total-claims__container">
		<PrimeLoaderSpinner v-if="isLoading" />
		<div v-else class="total-claims__content">
			<PrimeText weight="500" size="xs">{{ $t(TOTAL_CLAIMS) }}</PrimeText>
			<PrimeText weight="600" size="sm" class="total-claims__amount">
				{{ totalClaimsValueText }}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.total-claims__container {
	background-color: var(--base-primary-09);
	padding: 0.875rem 0.625rem;
	border-radius: 0.5rem;
	margin-bottom: 1.125rem;
}

.total-claims__content {
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
}
.total-claims__amount {
	flex: none;
}
</style>
