<script setup lang="ts">
import { computed } from 'vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { COINS, EARNED, YOUR_RANK } from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { type UserStanding } from '@/types';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
	data: UserStanding;
}>();

const { isMobileOnly, isMobile } = useScreenSize();
const { t } = useI18n();

const username = computed(() =>
	props.data.is_current_user ? t(YOUR_RANK) : props.data.username
);
</script>
<template>
	<div class="rating-item" :class="{ 'is-main': data.is_current_user }">
		<div class="rating-left">
			<div class="rating-score">
				<PrimeText weight="600" size="xs" color="primary">
					{{ data.rank }}
				</PrimeText>
			</div>
			<div class="rating-earnd">
				<PrimeText
					:weight="data.is_current_user ? '700' : '600'"
					:size="isMobileOnly ? 'xs' : 'sm'"
					:color="data.is_current_user ? 'primary' : 'default'"
				>
					{{ username }}
				</PrimeText>
				<PrimeText weight="500" :size="isMobileOnly ? 'xs' : 'sm'">
					{{ localizePoints(data.earned) }} {{ $t(EARNED) }}
				</PrimeText>
			</div>
		</div>
		<PrimeText
			color="primary"
			:weight="data.is_current_user ? '700' : '500'"
			:size="isMobileOnly ? 'xs' : isMobile ? 'sm' : 'base'"
		>
			{{ localizePoints(data.prize) }} {{ $t(COINS) }}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.rating-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.5rem;
	width: calc(100% - 1.25rem);
	background: var(--base-primary-50);
	padding: 0.875rem 0.5rem;
	border-radius: 1.5rem;

	&.is-main {
		width: 100%;
		background: var(--base-primary-gradient);
		box-shadow:
			0px 0px 0px 1px #ffffff,
			0px 0px 0px 3px #aec2f0;
	}
	@include breakpoint(mobileonly) {
		width: calc(100% - 2.5rem);
		padding: 0.875rem 1rem;
	}
	@include breakpoint(mobile) {
		width: calc(100% - 3.125rem);
	}
}

.rating-score {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 2.25rem;
	min-height: 2.25rem;
	padding: 0.5rem;
	border-radius: 0.75rem;
	overflow: hidden;

	.is-main & {
		background: var(--base-primary-100);
	}
}

.rating-left {
	display: flex;
	align-items: center;
	gap: 0.75rem;
}

.rating-earnd {
	display: flex;
	flex-direction: column;
	gap: 0.125rem;
	word-break: break-word;
}
</style>
